import { Tooltip } from 'antd';
import React from 'react';

type Props = {
  children: JSX.Element;
  value: string;
};

const CopyToClipboard = ({ children, value }: Props) => (
  <Tooltip placement="left" title="click to copy to clipboard" mouseEnterDelay={0.8}>
    <span
      id="copyToClipboard"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        navigator.clipboard.writeText(value);
      }}
    >
      {children}
    </span>
  </Tooltip>
);

export default CopyToClipboard;
