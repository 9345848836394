import { Typography, Select, Form } from 'antd';
import React from 'react';
import './multiple-select-component.less';

const { Option } = Select;

interface TextInputProps {
  dataTestid?: string;
  placeholder: string;
  title: string;
  name: string;
  options: any;
  valueItem: string;
  keyItem: string;
  multiple: boolean;
  size: string;
  required: boolean;
  onChange?: (value: string | string[]) => void;
}

const MultipleSelectComponent = ({
  dataTestid,
  placeholder,
  size,
  multiple,
  title,
  options,
  keyItem,
  valueItem,
  name,
  required,
  onChange,
}: TextInputProps) => (
  <div data-testid="select-container" className="select-container-1" style={{ width: size }}>
    <Typography.Text>{title}</Typography.Text>
    <Form.Item name={name} rules={[{ required: required }]} data-testid={dataTestid}>
      <Select
        showSearch
        style={{ width: size }}
        placeholder={placeholder}
        onChange={onChange}
        optionFilterProp="children"
        mode={multiple ? 'multiple' : undefined}
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options.map((item: any) => (
          <Option key={item[keyItem]} value={item[keyItem]}>
            {item[valueItem]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  </div>
);

export default MultipleSelectComponent;
