import axios from '../../apiClient';

interface PosTypesSetting {
  _id: string;
  value: {
    type: string;
    requiredFields: string[];
    customerSearch: any;
  }[];
}

export const getSetting = async (setting: string): Promise<PosTypesSetting> => {
  const { data } = await axios.get('/settings/' + setting);
  return data;
};

export const getSettings = async (): Promise<string[]> => {
  const { data } = await axios.get('/settings');
  return data;
};

export const updateSetting = async ({ setting, payload }: { setting: string; payload: any }) =>
  axios.put('/settings/' + setting, payload);

export const deleteSetting = async (setting: string) => axios.delete('/settings/' + setting);

export const addSetting = async ({ setting, payload }: { setting: string; payload: any }) =>
  axios.post('/settings/' + setting, payload);
