import { Modal, Table } from 'antd';
import './order-modal.less';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

type Props = {
  visible: boolean;
  onCancel: Function;
  selectedOrder: any;
};

const OrdersModal = ({ visible, onCancel, selectedOrder }: Props) => {
  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: 'Brand Name',
      width: 200,
      dataIndex: 'brand_name',
    },
    // {
    //   title: 'Image',
    //   dataIndex: 'imageUrls',
    //   render: (imageUrls: any) => {
    //     if (!!imageUrls && imageUrls.length > 0) {
    //       return <img width="100" src={imageUrls[0]} />;
    //     }
    //     return <div />;
    //   },
    // },
    {
      title: 'Inventory ID',
      dataIndex: 'inventoryId',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Goal',
      dataIndex: 'goal',
    },
  ];

  return (
    <Modal
      open={visible}
      title={'Order Details'}
      onCancel={() => onCancel()}
      width="90%"
      footer={null}
    >
      <div className="orderInfoWrapper">
        <div className="orderInfoFied">
          Order #: <span>{selectedOrder?.orderNumber}</span>
        </div>
        <div className="orderInfoFied">
          Customer email: <span>{selectedOrder?.customerEmail}</span>
        </div>
        <div className="orderInfoFied">
          Customer phone number: <span>{selectedOrder?.phoneNumber}</span>
        </div>
        <div className="orderInfoFied">
          Total Value: <span>{selectedOrder?.orderCosts?.total}</span>
        </div>
      </div>
      <Table columns={columns} dataSource={selectedOrder?.orderItems} />
    </Modal>
  );
};

export default OrdersModal;
