import React from 'react';
import ContentLoader from 'react-content-loader';

function Loader() {
  return (
    <ContentLoader
      width={1400}
      height={500}
      viewBox="0 0 1400 500"
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
    >
      <circle cx="15" cy="26" r="8" />
      <rect x="32" y="22" rx="5" ry="5" width="113" height="9" />
      <circle cx="15" cy="48" r="8" />
      <rect x="32" y="44" rx="5" ry="5" width="113" height="9" />
      <circle cx="14" cy="84" r="8" />
      <rect x="31" y="80" rx="5" ry="5" width="113" height="9" />
      <circle cx="14" cy="106" r="8" />
      <rect x="31" y="102" rx="5" ry="5" width="113" height="9" />
      <circle cx="14" cy="146" r="8" />
      <rect x="31" y="142" rx="5" ry="5" width="113" height="9" />
      <circle cx="14" cy="168" r="8" />
      <rect x="31" y="164" rx="5" ry="5" width="113" height="9" />
      <circle cx="14" cy="206" r="8" />
      <rect x="31" y="202" rx="5" ry="5" width="113" height="9" />
      <circle cx="14" cy="228" r="8" />
      <rect x="31" y="224" rx="5" ry="5" width="113" height="9" />
      <rect x="174" y="21" rx="5" ry="5" width="113" height="9" />
      <rect x="174" y="43" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="21" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="43" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="79" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="101" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="141" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="163" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="201" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="223" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="201" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="259" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="281" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="320" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="342" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="380" rx="5" ry="5" width="113" height="9" />
      <rect x="594" y="402" rx="5" ry="5" width="113" height="9" />

      <rect x="736" y="21" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="43" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="79" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="101" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="141" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="163" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="201" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="223" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="201" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="259" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="281" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="320" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="342" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="380" rx="5" ry="5" width="163" height="9" />
      <rect x="736" y="402" rx="5" ry="5" width="163" height="9" />

      <rect x="936" y="21" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="43" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="79" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="101" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="141" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="163" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="201" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="223" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="201" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="259" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="281" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="320" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="342" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="380" rx="5" ry="5" width="213" height="9" />
      <rect x="936" y="402" rx="5" ry="5" width="213" height="9" />

      <rect x="594" y="223" rx="5" ry="5" width="113" height="9" />
      <rect x="173" y="79" rx="5" ry="5" width="113" height="9" />
      <rect x="173" y="101" rx="5" ry="5" width="113" height="9" />
      <rect x="173" y="141" rx="5" ry="5" width="113" height="9" />
      <rect x="173" y="163" rx="5" ry="5" width="113" height="9" />
      <rect x="173" y="201" rx="5" ry="5" width="113" height="9" />
      <rect x="173" y="223" rx="5" ry="5" width="113" height="9" />
      <rect x="312" y="21" rx="5" ry="5" width="113" height="9" />
      <rect x="312" y="43" rx="5" ry="5" width="113" height="9" />
      <rect x="311" y="79" rx="5" ry="5" width="113" height="9" />
      <rect x="311" y="101" rx="5" ry="5" width="113" height="9" />
      <rect x="311" y="141" rx="5" ry="5" width="113" height="9" />
      <rect x="311" y="163" rx="5" ry="5" width="113" height="9" />
      <rect x="311" y="201" rx="5" ry="5" width="113" height="9" />
      <rect x="311" y="223" rx="5" ry="5" width="113" height="9" />
      <rect x="453" y="20" rx="5" ry="5" width="113" height="9" />
      <rect x="453" y="42" rx="5" ry="5" width="113" height="9" />
      <rect x="452" y="78" rx="5" ry="5" width="113" height="9" />
      <rect x="452" y="100" rx="5" ry="5" width="113" height="9" />
      <rect x="452" y="140" rx="5" ry="5" width="113" height="9" />
      <rect x="452" y="162" rx="5" ry="5" width="113" height="9" />
      <rect x="452" y="200" rx="5" ry="5" width="113" height="9" />
      <rect x="452" y="222" rx="5" ry="5" width="113" height="9" />
      <circle cx="15" cy="264" r="8" />
      <rect x="32" y="260" rx="5" ry="5" width="113" height="9" />
      <circle cx="15" cy="286" r="8" />
      <rect x="32" y="282" rx="5" ry="5" width="113" height="9" />
      <rect x="174" y="259" rx="5" ry="5" width="113" height="9" />
      <rect x="174" y="281" rx="5" ry="5" width="113" height="9" />
      <rect x="312" y="259" rx="5" ry="5" width="113" height="9" />
      <rect x="312" y="281" rx="5" ry="5" width="113" height="9" />
      <rect x="453" y="258" rx="5" ry="5" width="113" height="9" />
      <rect x="453" y="280" rx="5" ry="5" width="113" height="9" />
      <circle cx="16" cy="325" r="8" />
      <rect x="33" y="321" rx="5" ry="5" width="113" height="9" />
      <circle cx="16" cy="347" r="8" />
      <rect x="33" y="343" rx="5" ry="5" width="113" height="9" />
      <rect x="175" y="320" rx="5" ry="5" width="113" height="9" />
      <rect x="175" y="342" rx="5" ry="5" width="113" height="9" />
      <rect x="313" y="320" rx="5" ry="5" width="113" height="9" />
      <rect x="313" y="342" rx="5" ry="5" width="113" height="9" />
      <rect x="454" y="319" rx="5" ry="5" width="113" height="9" />
      <rect x="454" y="341" rx="5" ry="5" width="113" height="9" />
      <circle cx="15" cy="385" r="8" />
      <rect x="32" y="381" rx="5" ry="5" width="113" height="9" />
      <circle cx="15" cy="407" r="8" />
      <rect x="32" y="403" rx="5" ry="5" width="113" height="9" />
      <rect x="174" y="380" rx="5" ry="5" width="113" height="9" />
      <rect x="174" y="402" rx="5" ry="5" width="113" height="9" />
      <rect x="312" y="380" rx="5" ry="5" width="113" height="9" />
      <rect x="312" y="402" rx="5" ry="5" width="113" height="9" />
      <rect x="453" y="379" rx="5" ry="5" width="113" height="9" />
      <rect x="453" y="401" rx="5" ry="5" width="113" height="9" />
    </ContentLoader>
  );
}

export default Loader;
