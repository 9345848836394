import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
  SettingTwoTone,
} from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import './product-table.less';

type Props = {
  totalRows: number;
  pageChangeHandler: (currentPage: number) => void;
  rowsPerPage: number;
  setPageSize: (e: number) => void;
  pageSize: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  setIsColumnConfigModalShown: (v: boolean) => void;
};

const PaginationComponent = ({
  totalRows,
  pageChangeHandler,
  rowsPerPage,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  setIsColumnConfigModalShown,
}: Props) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Onclick handlers for the butons
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo: number) => setCurrentPage(pageNo);

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    pageChangeHandler(currentPage);
  }, [currentPage, pageChangeHandler]);

  return (
    <div className="paginationContainer">
      <Button
        type="primary"
        shape="round"
        data-testid="paginationFirstPage"
        icon={<DoubleLeftOutlined />}
        size={'middle'}
        onClick={() => setCurrentPage(1)}
        disabled={!canGoBack}
      />
      <Button
        type="primary"
        shape="round"
        data-testid="paginationPreviousPage"
        icon={<LeftOutlined />}
        size={'middle'}
        onClick={() => onPrevPage()}
        disabled={!canGoBack}
      />
      <Button
        data-testid="paginationNextPage"
        type="primary"
        shape="round"
        icon={<RightOutlined />}
        size={'middle'}
        onClick={() => onNextPage()}
        disabled={!canGoNext}
      />
      <Button
        type="primary"
        shape="round"
        data-testid="paginationLastPage"
        icon={<DoubleRightOutlined />}
        size={'middle'}
        onClick={() => setCurrentPage(noOfPages)}
        disabled={!canGoNext}
      />
      <span className="pageIndicator">
        <div>Page</div>
        <strong>
          {currentPage} of {noOfPages}
        </strong>
      </span>
      <span className="pageIndicator goToIndicator">
        Go to page:
        <div className="pageInput">
          <Input
            size="small"
            type="number"
            data-testid="paginationPageNumber"
            defaultValue={currentPage}
            value={currentPage}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 0;
              onPageSelect(page);
            }}
          />
        </div>
      </span>
      <span className="pageIndicator">
        <Select
          defaultValue={10}
          value={pageSize}
          onChange={(e) => {
            setPageSize(e);
          }}
        >
          {[5, 10, 20, 40, 100, 200].map((pageSize) => (
            <Select.Option key={pageSize} value={pageSize}>
              Show {pageSize}
            </Select.Option>
          ))}
        </Select>
      </span>
      <span className="pageIndicator">
        <SettingTwoTone
          data-testid="settingIcon"
          twoToneColor={'#5344ff'}
          className="settingsButton"
          onClick={() => setIsColumnConfigModalShown(true)}
        />
      </span>
    </div>
  );
};

export default PaginationComponent;
