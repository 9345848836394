import React from 'react';
import { Input, Button, Form, Checkbox, Row, Col } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
export interface Menu {
  _id?: string;
  name: string;
  requiresMedicalId: boolean;
  isDefaultMenu: boolean;
  feedIdentifiers: string[];
}

interface MenuFormProps {
  menus?: Menu[];
  onSave: (menus: Menu[]) => void;
}

const MenuForm: React.FC<MenuFormProps> = ({ menus = [], onSave }) => {
  const handleChange = (
    editIndex: number,
    e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent
  ) => {
    const { name, value, checked } = e.target;
    if (!name) return;
    const newMenus = [...menus];
    if (name === 'isDefaultMenu' && checked) {
      newMenus.forEach((m) => (m.isDefaultMenu = false));
    }
    newMenus[editIndex] = {
      ...newMenus[editIndex],
      [name]: typeof value === 'undefined' ? checked : value,
    };
    onSave(newMenus);
  };

  const handleFeedIdentifiersChange = (
    editIndex: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const item = menus[editIndex];
    const { value } = e.target;
    const newMenus = [...menus];
    newMenus[editIndex] = {
      ...item,
      feedIdentifiers: value.split(',').map((item) => item.trim()),
    };
    onSave(newMenus);
  };

  return (
    <>
      <div>Dispensary Menus</div>
      <Input.Group>
        <Button
          style={{ width: '10%' }}
          type="primary"
          icon={<PlusOutlined />}
          data-testid="addMenu"
          onClick={() => {
            const newMenu = {
              name: '',
              isDefaultMenu: false,
              requiresMedicalId: false,
              feedIdentifiers: [],
            };
            onSave([...menus, newMenu]);
          }}
        />
      </Input.Group>
      {menus.map((item, idx) => (
        <Row key={idx}>
          <div
            style={{
              marginLeft: '2rem',
              marginTop: '2rem',
              borderRadius: '4px',
              border: '2px solid rgba(25,25,25,0.25)',
              padding: '1rem',
            }}
          >
            <Row>
              <Col span={16}>
                <div className="form-item">
                  <div>Name</div>
                  <Form.Item rules={[{ required: false }]}>
                    <Input
                      style={{ width: '80%' }}
                      type="text"
                      name="name"
                      data-testid={`menuName-${idx}`}
                      id={`name-${idx}`}
                      key={`name-${idx}`}
                      value={item.name}
                      onChange={(e) => handleChange(idx, e)}
                      placeholder="Menu Name"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="form-item">
                  <Form.Item valuePropName="checked">
                    <Checkbox
                      id={`requiresMedicalId-${idx}`}
                      key={`requiresMedicalId-${idx}`}
                      name="requiresMedicalId"
                      checked={item.requiresMedicalId}
                      data-testid={`requiresMedicalId-${idx}`}
                      onChange={(e) => handleChange(idx, e)}
                    >
                      <div>Requires Medical ID?</div>
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="form-item">
                  <Form.Item valuePropName="checked">
                    <Checkbox
                      id={`isDefaultMenu-${idx}`}
                      key={`isDefaultMenu-${idx}`}
                      name="isDefaultMenu"
                      data-testid={`isDefaultMenu-${idx}`}
                      checked={item.isDefaultMenu}
                      onChange={(e) => handleChange(idx, e)}
                    >
                      Is Default Menu?
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="form-item">
                  <Form.Item>
                    <div>Feed/Room Identifiers (comma separated)</div>
                    <Input
                      id={`feeds-${idx}`}
                      key={`feeds-${idx}`}
                      data-testid={`feeds-${idx}`}
                      type="text"
                      name="feedIdentifiers"
                      value={item.feedIdentifiers.join(', ')}
                      onChange={(e) => handleFeedIdentifiersChange(idx, e)}
                      placeholder="Feed Identifiers (comma separated)"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <Button
                  style={{ position: 'absolute', right: '0px' }}
                  type="ghost"
                  icon={<MinusCircleOutlined />}
                  data-testid={`removeMenu-${idx}`}
                  onClick={() => {
                    const newMenus = [...menus];
                    newMenus.splice(idx, 1);
                    onSave(newMenus);
                  }}
                />
              </Col>
            </Row>
          </div>
        </Row>
      ))}
    </>
  );
};

export default MenuForm;
