import { associateDuplicateAPI } from '../../../services/api/productAPI/potentialDuplicatesAPI';
import { Modal, Table, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import './potential_duplicates_modal.less';

interface DataType {
  _id: React.Key;
  name: string;
  brand_name: string;
  reportsCount: number;
  covers: { link: string }[];
}
interface PotentialDuplicatesProps {
  id: string;
  hideDuplicatesModal: (r: boolean) => void;
  potentialDuplicates: DataType[];
}

const PotentialDuplicatesModal = ({
  id,
  hideDuplicatesModal,
  potentialDuplicates,
}: PotentialDuplicatesProps) => {
  const [selectedDuplicate, setSelectedDuplicate] = useState<React.Key[]>();
  const [buttonLoading, setButtonLoading] = useState(false);

  const confirmData = () => {
    if (!selectedDuplicate) return;
    setButtonLoading(true);
    associateDuplicateAPI(id, selectedDuplicate[0])
      .then((_) => {
        setButtonLoading(false);
        message.success('Pending Product Associated');
        const refreshData = true;
        hideDuplicatesModal(refreshData);
      })
      .catch((error) => {
        message.error('Something went wrong');
        console.error(error);
        setButtonLoading(false);
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'IMAGE',
      render: (covers) => (
        <img src={covers[0]?.link} className="product-list-image" alt="product-list" />
      ),
      dataIndex: 'covers',
    },
    {
      title: 'NAME',
      dataIndex: 'name',
    },
    {
      title: 'BRAND',
      dataIndex: 'brand_name',
    },
    {
      title: 'NUMBER OF REPORTS',
      dataIndex: 'reportsCount',
    },
  ];

  return (
    <Modal
      visible={true}
      width="966px"
      title="Potential Duplicates"
      onCancel={() => hideDuplicatesModal(false)}
      onOk={confirmData}
      className={'potential-duplicates-container'}
      okButtonProps={{ loading: buttonLoading, disabled: !selectedDuplicate }}
    >
      <Table
        columns={columns}
        pagination={false}
        dataSource={potentialDuplicates}
        rowKey="_id"
        rowSelection={{ type: 'radio', onChange: (itemId) => setSelectedDuplicate(itemId) }}
      />
    </Modal>
  );
};

export default PotentialDuplicatesModal;
