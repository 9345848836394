import { RetailProductStatusEnum } from '../../../utils/enum';
import { encodeQuery } from '../../../utils/utils';
import {
  CurrentFilter,
  CurrentSort,
} from '../../../views/retailProducts/preApproved/RetailProductFilter';
import { RetailProduct } from '../../../views/retailProducts/preApproved/types';
import axios from '../../apiClient';

export const getRetailProducts = async ({
  limit = 5,
  skip = 0,
  collection,
  currentFilter,
  currentSort,
}: {
  limit: number;
  skip: number;
  collection?: RetailProductStatusEnum;
  currentFilter?: CurrentFilter;
  currentSort?: CurrentSort;
}): Promise<{ list: {}[]; total: number }> => {
  const url = encodeQuery('/org/v2/product', {
    limit,
    skip,
    status: collection,
    jointlyProductId: true,
    possibleMatchingProducts: true,
    ...(currentSort?.sortBy ? { sortBy: currentSort.sortBy } : {}),
    ...(currentFilter?.brand ? { brand: currentFilter?.brand } : {}),
    ...(currentFilter?.name ? { name: currentFilter?.name } : {}),
    ...(currentFilter?.typeName ? { typeName: currentFilter?.typeName } : {}),
    ...(currentFilter?.typeSpecific ? { typeSpecific: currentFilter?.typeSpecific } : {}),
    ...(currentFilter?.retailLocation ? { locationId: currentFilter?.retailLocation } : {}),
    ...(currentFilter?.states ? { shipping_legal_states: currentFilter?.states } : {}),
    withStockOnly: currentFilter?.stockAvailable === 'inventory only',
  });
  const { data } = await axios.get(url);
  return data;
};

export const getRetailProduct = async ({ id }: { id: string }): Promise<RetailProduct> => {
  const { data } = await axios.get(
    '/org/v2/product/' + id + '?possibleMatchingProducts=true&jointlyProductId=true'
  );
  return data;
};

export const unmatchRetailProduct = ({
  id,
  retailLocationId,
  status,
}: {
  id: string;
  retailLocationId: string;
  status: string;
}): Promise<any> =>
  axios.put('/org/v2/product', {
    id,
    retailLocationId,
    status: null,
    unset: ['jointlyProductId'],
  });

export const changeStatusRetailProduct = ({
  id,
  retailLocationId,
  status,
}: {
  id: string;
  retailLocationId: string;
  status: RetailProductStatusEnum | null;
}): Promise<any> =>
  axios.put('/org/v2/product', {
    id,
    retailLocationId,
    status,
  });

export const matchRetailProduct = async ({
  id,
  retailLocationId,
  jointlyProductId,
}: {
  id: string;
  retailLocationId: string;
  jointlyProductId: string;
}) =>
  axios.put('/org/v2/product', {
    id,
    retailLocationId,
    status: RetailProductStatusEnum.Mapped,
    jointlyProductId,
  });

export const approveProduct = async ({ id }: { id: string }) =>
  axios.post('/product/pending/approve-multiple-product', [{ _id: id }]);
