import OrdersModal from './order-modal/order-modal';
import ReactTable from './table';
import CopyToClipboard from '../../../components/CopyToClipboard';
import PaginationComponent from '../../../components/table/pagination';
import { getOrders } from '../../../services/api/orders';
import { formatDate } from '../../../utils/utils';
import OrderFilter from '../OrderFilter';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Empty, Spin, notification, Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import { CellProps } from 'react-table';

const OrdersTable = () => {
  const [selectedOrder, setSelectedOrder] = useState<any>(false);
  const [isEditModalShown, setIsOrderModalShown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isColumnConfigModalShown, setIsColumnConfigModalShown] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});

  const { isLoading, error, data } = useQuery({
    queryKey: ['getOrders', currentPage, pageSize, currentFilter],
    queryFn: () =>
      getOrders({
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
        currentFilter,
      }),
    onError: () => {
      notification.error({
        message: 'Something went wrong',
        description: 'Please try later',
      });
    },
    keepPreviousData: true,
  });

  const columns = useMemo<any[]>(
    () => [
      {
        Header: 'Order #',
        width: 160,
        accessor: 'orderNumber',
        Cell: ({ value }: CellProps<any>) => (
          <div
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {value || ''}
          </div>
        ),
      },
      {
        Header: 'Customer email',
        width: 200,
        accessor: 'customerEmail',
        Cell: ({ value }: CellProps<any>) => (
          <CopyToClipboard value={value || ''}>
            <div style={{ wordWrap: 'break-word' }} id="copyToClipboard">
              {value || ''}
            </div>
          </CopyToClipboard>
        ),
      },
      {
        Header: 'Phone Number',
        width: 160,
        accessor: 'phoneNumber',
        Cell: ({ value }: CellProps<any>) => <div>{value || ''}</div>,
      },
      {
        Header: 'Total value',
        width: 130,
        accessor: 'orderCosts',
        Cell: ({ value }: CellProps<any>) => (
          <div style={{ wordWrap: 'break-word' }}>{value?.total ? `$${value.total}` : ''}</div>
        ),
      },
      {
        Header: 'Purchase Status',
        width: 150,
        accessor: 'purchaseStatus',
        Cell: ({ value }: CellProps<any>) => {
          if (!value) return '';
          switch (value) {
            case 'paid':
              return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  paid
                </Tag>
              );
            case 'pending':
              return (
                <Tag icon={<ClockCircleOutlined />} color="processing">
                  pending
                </Tag>
              );
            case 'voided':
              return (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                  voided
                </Tag>
              );
            case 'refunded':
              return (
                <Tag icon={<MinusCircleOutlined />} color="default">
                  refunded
                </Tag>
              );
            default:
              return value || '';
          }
        },
      },
      {
        Header: 'Date',
        width: 180,
        accessor: 'placedAt',
        Cell: ({ value, row: { original } }: CellProps<any>) =>
          formatDate(value || original.createdAt),
      },
      {
        Header: 'Retail Location',
        width: 250,
        accessor: 'retailLocation',
        Cell: ({ value }: CellProps<any>) => (
          <div style={{ wordWrap: 'break-word' }}>{value || ''}</div>
        ),
      },
    ],
    []
  );

  const onRowClick = (row: any, e: any) => {
    if (e?.target?.id === 'copyToClipboard') return;
    setSelectedOrder(row.original);
    setIsOrderModalShown(true);
  };

  return (
    <div className="tableAndFilterWrapper">
      <div className="product-list-header">Order Tracking</div>
      <br />
      <OrderFilter
        setCurrentPage={setCurrentPage}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
      />
      {isLoading ? (
        <Spin size="large" />
      ) : !data || !data.list.length || error ? (
        <div style={{ flex: 10 }}>
          <Empty />
        </div>
      ) : (
        <div className="tableWrapper">
          <ReactTable
            data={data.list}
            columns={columns}
            selectedItem={selectedOrder}
            setMultiSelectType={() => true}
            setSelectedItens={() => true}
            setIsColumnConfigModalShown={setIsColumnConfigModalShown}
            isColumnConfigModalShown={isColumnConfigModalShown}
            getTrProps={onRowClick}
          />
          <OrdersModal
            visible={isEditModalShown}
            onCancel={() => setIsOrderModalShown(false)}
            selectedOrder={selectedOrder}
          />
          <br />
          <PaginationComponent
            totalRows={data.total}
            pageChangeHandler={setCurrentPage}
            rowsPerPage={pageSize}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setIsColumnConfigModalShown={setIsColumnConfigModalShown}
          />
          <br />
        </div>
      )}
    </div>
  );
};

export default OrdersTable;
