import React from 'react';

type Props = {
  label: string;
  value?: string;
  labelStyle?: React.CSSProperties | null;
  valueStyle?: React.CSSProperties | null;
  children?: React.ReactNode;
};

const FieldInfo = ({ label, value, labelStyle, valueStyle, children }: Props) => (
  <div style={{ marginTop: 10 }}>
    <div
      style={{
        display: 'flex',
        borderBottom: '1px solid #cfcfcf',
      }}
    >
      <div
        style={{
          flex: 1,
          padding: 5,
          fontWeight: 600,
          ...(labelStyle ? labelStyle : {}),
        }}
      >
        {label}:
      </div>
      <div
        style={{
          flex: 3,
          padding: 5,
          ...(valueStyle ? valueStyle : {}),
        }}
      >
        {value || children}
      </div>
    </div>
  </div>
);

export default FieldInfo;
