import { Modal, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useState, useEffect } from 'react';

type Props = {
  visible: boolean;
  onCancel: Function;
  colums: any[];
  setHiddenColumns: Function;
  configName?: string;
};

const ColumnsConfig = ({
  visible,
  onCancel,
  colums,
  setHiddenColumns,
  configName = 'hiddenColumns',
}: Props) => {
  const [internalColumns, setInternalColumns] = useState<any[]>(colums);

  useEffect(() => {
    const _localHiddenColumns = localStorage.getItem(configName)?.trim() || '[]';
    try {
      const localHiddenColumns = JSON.parse(_localHiddenColumns);
      if (!!localHiddenColumns && !!localHiddenColumns.length) {
        setHiddenColumns(localHiddenColumns);
        setInternalColumns((prevState) =>
          prevState.map((element) => {
            if (localHiddenColumns.includes(element.id)) {
              element.isVisible = false;
            }
            return element;
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [setHiddenColumns, colums]);

  const onChange = (e: CheckboxChangeEvent, id: string) => {
    setInternalColumns((prevState) =>
      prevState.map((element) => {
        if (element.id === id) {
          element.isVisible = e.target.checked;
        }
        return element;
      })
    );
  };

  return (
    <Modal
      open={visible}
      title={'Fields configuration'}
      onOk={() => {
        const hiddenColumns = internalColumns
          .filter((item) => !item.isVisible)
          .map((item) => item.id);
        setHiddenColumns(hiddenColumns);
        localStorage.setItem(configName, JSON.stringify(hiddenColumns));
        onCancel();
      }}
      onCancel={() => onCancel()}
    >
      <div className="checkBoxWrapper">
        {internalColumns
          .filter((item) => !['selection', 'action'].includes(item.id))
          .map((item: any) => (
            <Checkbox
              key={item.render('Header')}
              onChange={(e) => onChange(e, item.id)}
              checked={item.isVisible}
            >
              {item.render('Header')}
            </Checkbox>
          ))}
      </div>
    </Modal>
  );
};

export default ColumnsConfig;
