import { RetailProductStatusEnum } from './enum';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { format, isValid } from 'date-fns';
import { KV } from 'module-reaction';

const LOCAL_STORAGE_ACCESS = 'jointly-better-ls';

// allow key contans '.' to specific deeper structure
function getValueOfKV(key: string, obj: KV) {
  if (key.includes('.')) {
    const keyChains = key.split('.').map((_) => _.trim());
    let res = obj,
      k;
    while (keyChains.length) {
      k = keyChains.shift();
      if (res[k!]) {
        res = res[k!];
      }
    }

    return res === obj ? undefined : res;
  }
  return obj[key];
}

export function setValueOfKV(key: string, v: any, obj: KV) {
  if (key.includes('.')) {
    const keyChains = key.split('.').map((_) => _.trim());
    let res = obj,
      k;
    while (keyChains.length) {
      k = keyChains.shift();
      if (keyChains.length) {
        res[k!] = res[k!] || {};
        res = res[k!];
      } else {
        res[k!] = v;
      }
    }
  } else {
    obj[key] = v;
  }
}
function localStorageGet(key: string) {
  const str = localStorage.getItem(LOCAL_STORAGE_ACCESS);
  if (str) {
    const store = JSON.parse(str);
    return getValueOfKV(key, store);
  }
  return null;
}
function localStorageSet(key: string, v: any) {
  const str = localStorage.getItem(LOCAL_STORAGE_ACCESS);
  const store = str ? JSON.parse(str) : {};
  setValueOfKV(key, v, store);
  localStorage.setItem(LOCAL_STORAGE_ACCESS, JSON.stringify(store));
}

function getType(obj: any) {
  const type = Object.prototype.toString
    .call(obj)!
    .match(/^\[object (.*)\]$/)![1]
    .toLowerCase();
  if (obj === null) return 'null'; // PhantomJS has type "DOMWindow" for null
  if (obj === undefined) return 'undefined'; // PhantomJS has type "DOMWindow" for undefined
  return type;
}

export function deepClone(val: any): any {
  const type = getType(val);
  const res: KV = {};
  switch (type) {
    case 'array':
      return (val as any[]).map((_) => deepClone(_));
    case 'map':
      return new Map(val);
    case 'set':
      return new Set(val);
    case 'object':
      for (const k in val) {
        if (Object.prototype.hasOwnProperty.call(val, k)) {
          res[k] = deepClone(val[k]);
        }
      }
      return res;
    default:
      return val;
  }
}
export function maxCommonDivisor(m: number, n: number) {
  let u = +m,
    v = +n,
    t = v;
  while (v !== 0) {
    t = u % v;
    u = v;
    v = t;
  }
  return u;
}

const LOCAL_TOKEN_KEY = 'jointlyTkn';
const RES_TOKEN_KEY = 'jwt_token';
let memToken: string;
export function setToken(token: string) {
  localStorageSet(LOCAL_TOKEN_KEY, token);
  memToken = token;
}

export function getToken(): string {
  return memToken || localStorageGet(LOCAL_TOKEN_KEY) || '';
}

export class Net {
  private static async get(url: string, query?: { [k: string]: any }) {
    return await this.toFetch(this.encodeQuery(url, query), {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  private static async put(url: string, data: any) {
    const body: string | FormData = JSON.stringify(data);
    const headers: KV = {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    };

    headers['Content-Type'] = 'application/json';

    return await this.toFetch(url, {
      method: 'put',
      mode: 'cors',
      headers,
      body,
    });
  }

  private static async post(
    url: string,
    data: any,
    type: 'post' | 'form' | 'delete' | 'multipart' = 'post'
  ) {
    let body: string | FormData;
    const headers: KV = {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    };

    if (type === 'form' || type === 'multipart') {
      body = new FormData();
      for (const k in data) {
        if (Object.prototype.hasOwnProperty.call(data, k)) {
          body.append(k, data[k]);
        }
      }
      // Content-Type will add by browser automaticly
    } else {
      body = JSON.stringify(data);
      headers['Content-Type'] = 'application/json';
    }

    return await this.toFetch(url, {
      method: 'post',
      mode: 'cors',
      headers,
      body,
    });
  }

  private static async delete(
    url: string,
    data: any,
    type: 'post' | 'form' | 'delete' | 'multipart' = 'post'
  ) {
    let body: string | FormData;
    const headers: KV = {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    };

    if (type === 'form' || type === 'multipart') {
      body = new FormData();
      for (const k in data) {
        if (Object.prototype.hasOwnProperty.call(data, k)) {
          body.append(k, data[k]);
        }
      }
      // Content-Type will add by browser automaticly
    } else {
      body = JSON.stringify(data);
      headers['Content-Type'] = 'application/json';
    }

    return await this.toFetch(url, {
      method: type,
      mode: 'cors',
      headers,
      body,
    });
  }

  private static async toFetch(url: string, req: RequestInit) {
    try {
      const res = await fetch(url, req);
      const data = await res.json();
      // if error, data.status will be >=400, if success, data has no 'status'
      if (data.status && data.status >= 400 && data.message) {
        if (data.status === 401) {
          window.location.pathname = '/sign-in';
        }
        message.error('Something went wrong');
      }
      // record token here
      if (data[RES_TOKEN_KEY]) {
        setToken(data[RES_TOKEN_KEY]);
      }
      return data;
    } catch (error) {
      message.error((error as any).message);
      return { error: (error as any).message };
    }
  }

  /**
   *
   * @param path router path
   * @param params datas of KV
   * @param usePost post type, 'no' means use 'get' method, 'json' means use post by json format, 'form' means use post by form format
   */
  public static async req(
    path: string,
    params: KV = {},
    method: 'get' | 'put' | 'post' | 'form' | 'delete' | 'multipart' = 'get'
  ) {
    const url = `${process.env.REACT_APP_BASE_URL || ''}${
      path.startsWith('/') ? path : '/' + path
    }`;
    const res =
      method !== 'get'
        ? method !== 'delete'
          ? method !== 'put'
            ? await this.post(url, params, method)
            : await this.put(url, params)
          : await this.delete(url, params, method)
        : await this.get(url, params);
    return res;
  }

  // call this when logout
  public static clearToken() {
    setToken('');
  }

  private static encodeQuery(url: string, query?: { [k: string]: any }): string {
    const preUrl = url;
    if (!query) {
      return preUrl;
    }

    let queryStr = '';
    const queryArr = [];
    for (const k in query) {
      if (Object.prototype.hasOwnProperty.call(query, k)) {
        const v = Array.isArray(query[k]) ? JSON.stringify(query[k]) : query[k];
        queryArr.push(`${k}=${encodeURIComponent(v)}`);
      }
    }

    queryStr = queryArr.join('&');
    if (!preUrl.endsWith('?')) {
      queryStr = '?' + queryStr;
    }
    return preUrl + queryStr;
  }
}

const flPicker: {
  ele?: HTMLInputElement;
  cb?: (file: Blob, thumb?: string) => void;
} = {
  ele: undefined,
  cb: undefined,
};

export function uploadFile(ext: string) {
  return new Promise((resolve, reject) => {
    if (!flPicker.ele) {
      flPicker.ele = document.createElement('input');
      flPicker.ele.type = 'file';
      flPicker.ele.accept = ext.startsWith('.') ? ext : `.${ext}`;
    }
    flPicker.ele.dispatchEvent(new MouseEvent('click'));
    flPicker.ele.addEventListener('change', _onFileChosen);
    flPicker.cb = resolve;

    flPicker.ele.addEventListener('cancel', (_) => reject('canceled'));
  });
}
function _onFileChosen(e: any) {
  e.preventDefault();
  const file = e.target.files[0];
  const thumb = URL.createObjectURL(file); // valiable for images

  flPicker.ele!.value = '';

  flPicker.cb!(file, thumb);
}

export const getBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getSizeInUnits = (sizeData: string) => {
  const size = parseInt(sizeData);
  const m = size / 1024.0;
  const g = size / 1048576.0;
  const t = size / 1073741824.0;
  let hrSize;

  if (t > 1) {
    hrSize = `${t} TB`;
  } else if (g > 1) {
    hrSize = `${g} GB`;
  } else if (m > 1) {
    hrSize = `${m} MB`;
  } else {
    hrSize = `${size} KB`;
  }
  return hrSize;
};

export const encodeQuery = (url: string, query?: { [k: string]: any }): string => {
  const preUrl = url;
  if (!query) {
    return preUrl;
  }

  let queryStr = '';
  const queryArr = [];
  for (const k in query) {
    if (Object.prototype.hasOwnProperty.call(query, k)) {
      const v = Array.isArray(query[k]) ? JSON.stringify(query[k]) : query[k];
      queryArr.push(`${k}=${encodeURIComponent(v)}`);
    }
  }

  queryStr = queryArr.join('&');
  if (!preUrl.endsWith('?')) {
    queryStr = '?' + queryStr;
  }
  return preUrl + queryStr;
};

export const addLineBreakForCharacters = (string: string, override?: boolean) => {
  if (override) {
    return string.replace(/,|\//g, '\r\n');
  }
  // if / | ,
  if (string.length > 10) {
    return string.replace(/,|\//g, '$& \r\n');
  }
  return string;
};

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const formatDate = (date: Date | undefined) => {
  if (!!date && isValid(new Date(date))) {
    return format(new Date(date), 'MMM d yyyy h:mm aaa');
  }
  return '';
};

export const escapeQuery = (str: string) =>
  str
    .replace(String.fromCharCode(40), String.fromCharCode(92, 40))
    .replace(String.fromCharCode(41), String.fromCharCode(92, 41));

export const getStatusColor = (status: RetailProductStatusEnum) => {
  switch (status) {
    case RetailProductStatusEnum.Mapped:
      return '#28B463';
    case RetailProductStatusEnum.PreMapped:
      return '#F4D03F';
    case RetailProductStatusEnum.NotMapped:
      return '#E74C3C';
    case RetailProductStatusEnum.NotSupported:
      return '#000000';
    default:
      return '#666666';
  }
};

export const isObject = (obj: object) =>
  !!obj && typeof obj === 'object' && obj.constructor === Object && Object.keys(obj).length > 0;
