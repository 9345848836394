export const Terpenes = [
  {
    name: 'PNE',
    key: 'PNE',
  },
  {
    name: 'MYR',
    key: 'MYR',
  },
  {
    name: 'LME',
    key: 'LME',
  },
  {
    name: 'CYE',
    key: 'CYE',
  },
  {
    name: 'LNL',
    key: 'LNL',
  },
  {
    name: 'HUM',
    key: 'HUM',
  },
  {
    name: 'OCM',
    key: 'OCM',
  },
  {
    name: 'TPE',
    key: 'TPE',
  },
];

export const CannabinoidsData = [
  {
    name: 'THC',
    key: 'THC',
    label: 'THC',
  },
  {
    name: 'CBD',
    key: 'CBD',
    label: 'CBD',
  },
  {
    name: 'THCa',
    key: 'THCa',
    label: 'THCa',
  },
  {
    name: 'THCv',
    key: 'THCv',
    label: 'THCv',
  },
  {
    name: 'CBDa',
    key: 'CBDa',
    label: 'CBDa',
  },
  {
    name: 'CBDv',
    key: 'CBDv',
    label: 'CBDv',
  },
  {
    name: 'CBN',
    key: 'CBN',
    label: 'CBN',
  },
  {
    name: 'CBGa',
    key: 'CBGa',
    label: 'CBGa',
  },
  {
    name: 'CBG',
    key: 'CBG',
    label: 'CBG',
  },
  {
    name: 'CBC',
    key: 'CBC',
    label: 'CBC',
  },
  {
    name: 'CBCv',
    key: 'CBCv',
    label: 'CBCv',
  },
  {
    name: 'Delta-8',
    key: 'Delta-8',
    label: 'Delta-8',
  },
  {
    name: 'Delta-10',
    key: 'Delta10',
    label: 'Delta-10',
  },
  {
    name: 'HHC',
    key: 'HHC',
    label: 'HHC',
  },
  {
    name: 'THCp',
    key: 'THCp',
    label: 'THCp',
  },
  {
    name: 'THCo',
    key: 'THCo',
    label: 'THCo',
  },
  {
    name: 'HXC',
    key: 'HXC',
    label: 'HXC',
  },
  {
    name: 'HXCp',
    key: 'HXCp',
    label: 'HXCp',
  },
  {
    name: 'CBT',
    key: 'CBT',
    label: 'CBT',
  },
];

export const StrainTypes = [
  {
    name: 'indica',
    key: 'indica',
  },
  {
    name: 'sativa',
    key: 'sativa',
  },
  {
    name: 'hybrid',
    key: 'hybrid',
  },
];

export const States: {
  name: string;
  abbreviation: string;
}[] = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

interface ProductTypeSpecificItem {
  name: string;
  key?: string;
  servingType?: string;
  servingTypePlural?: string;
  defaultSelection?: number;
  unitOfSelection?: string;
  max?: number;
  search_key?: string;
  dosageRequired: boolean;
  mgConversion?: boolean;
  dosageValue?: string;
  cannabinoidMetric?: string;
  terpeneMetric?: string;
}

interface ProductTypeItem {
  key: string;
  name: string;
  specific: {
    [key: string]: ProductTypeSpecificItem;
  };
}

export const ProductTypes: ProductTypeItem[] = [
  {
    key: 'flowerSmoke',
    name: 'Flower/Smoke',
    specific: {
      flower: {
        name: 'Flower',
        servingType: 'Puff',
        servingTypePlural: 'Puffs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Flower/Smoke Flower',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      preRolls: {
        name: 'Pre-Rolls',
        servingType: 'Puff',
        servingTypePlural: 'Puffs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Flower/Smoke Pre-Rolls',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      hash: {
        name: 'Hash',
        servingType: 'Puff',
        servingTypePlural: 'Puffs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Flower/Smoke Hash',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
    },
  },
  {
    key: 'vapeOil',
    name: 'Vape Oil',
    specific: {
      disposableVapePens: {
        name: 'Disposable Vape Pens',
        servingType: 'Puff',
        servingTypePlural: 'Puffs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Vape Oil Disposable Vape Pens',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      cartridges: {
        name: 'Cartridges',
        servingType: 'Puff',
        servingTypePlural: 'Puffs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Vape Oil Cartridges',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      pods: {
        name: 'Pods',
        servingType: 'Puff',
        servingTypePlural: 'Puffs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Vape Oil Pods',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      drops: {
        name: 'Drops',
        servingType: 'Puff',
        servingTypePlural: 'Puffs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Vape Oil Drops',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
    },
  },
  {
    key: 'edibles',
    name: 'Edibles',
    specific: {
      gummies: {
        name: 'Gummies',
        servingType: 'Piece',
        servingTypePlural: 'Pieces',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'fraction',
        max: 999,
        search_key: 'Edibles Gummies',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      candy: {
        name: 'Candy',
        servingType: 'Piece',
        servingTypePlural: 'Pieces',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'fraction',
        max: 999,
        search_key: 'Edibles Candy',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      chocolate: {
        name: 'Chocolate',
        servingType: 'Piece',
        servingTypePlural: 'Pieces',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'fraction',
        max: 999,
        search_key: 'Edibles Chocolate',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      pillsCapsules: {
        name: 'Pills & Capsules',
        servingType: 'Pill',
        servingTypePlural: 'Pills',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'whole',
        max: 999,
        search_key: 'Edibles Pills/Capsules',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      cookingIngredients: {
        name: 'Cooking Ingredients',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Edibles ',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      chewingGum: {
        name: 'Chewing gum',
        servingType: 'Piece',
        servingTypePlural: 'Pieces',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'fraction',
        max: 999,
        search_key: 'Edibles Chewing gum',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      brownieCookies: {
        name: 'Brownie & Cookies',
        servingType: 'Piece',
        servingTypePlural: 'Pieces',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'fraction',
        max: 999,
        search_key: 'Edibles Brownie & Cookies',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      mints: {
        name: 'Mints',
        servingType: 'Piece',
        servingTypePlural: 'Pieces',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'fraction',
        max: 999,
        search_key: 'Edibles Mints',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      edibleOils: {
        name: 'Edible Oils',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Edibles Oils',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      sublingual: {
        name: 'Sublingual',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Edibles Sublingual',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      buccal: {
        name: 'Buccal',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Edibles Buccal',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      other_edibles: {
        name: 'Other',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Edibles Other',
        dosageRequired: true,
        dosageValue: 'Per Package',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
    },
  },
  {
    key: 'drink',
    name: 'Drink',
    specific: {
      beverages: {
        name: 'Beverages',
        servingType: 'Bottle',
        servingTypePlural: 'Bottles',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'fraction',
        max: 999,
        search_key: 'Drink Beverages',
        dosageRequired: true,
        dosageValue: 'Per Package',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
    },
  },
  {
    key: 'topical',
    name: 'Topical',
    specific: {
      balmssalves: {
        name: 'Balms & Salves',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Balms and Salves',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      bodyoilsserums: {
        name: 'Body Oils & Serums',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Body Oils & Serums',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      creamslotions: {
        name: 'Creams & Lotions',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Creams & Lotions',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      gels: {
        name: 'Gels',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Gels',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      patches: {
        name: 'Patches',
        servingType: 'Patch',
        servingTypePlural: 'Patches',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'whole',
        max: 999,
        search_key: 'Topical Patches',
        dosageRequired: true,
        dosageValue: 'Per Package',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      bathBombs: {
        name: 'Bath Bombs',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Bath Bombs',
        dosageRequired: true,
        dosageValue: 'Per Package',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      scrubs: {
        name: 'Scrubs',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Scrubs',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      lipBalm: {
        name: 'Lip Balms',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Lip Balm',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      soaps: {
        name: 'Soaps',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Topical Soaps',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
    },
  },
  {
    key: 'tincture',
    name: 'Tincture',
    specific: {
      droppersSprays: {
        name: 'Droppers & Sprays',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Tincture Droppers Sprays',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
    },
  },
  {
    key: 'dabbableConcentrates',
    name: 'Dabbable Concentrates',
    specific: {
      crumble: {
        name: 'Crumble',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Crumble',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      sauce: {
        name: 'Sauce',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Sauce',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      budder: {
        name: 'Budder',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      liveResin: {
        name: 'LiveResin',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Live Resin',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      distillate: {
        name: 'Distillate',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Distillate',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      crystalline: {
        name: 'Crystalline',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Crystalline',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      rosin: {
        name: 'Rosin',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Rosin',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      wax: {
        name: 'Wax',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Wax',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      shatter: {
        name: 'Shatter',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Shatter',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      batterBadder: {
        name: 'Batter/Badder',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Batter/badder',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      diamonds: {
        name: 'Diamonds',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Diamonds',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      sugar: {
        name: 'Sugar',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Sugar',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      honeycomb: {
        name: 'Honeycomb',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates honeycomb',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
      other_dabbableConcentrates: {
        name: 'Other',
        servingType: 'Dab',
        servingTypePlural: 'Dabs',
        defaultSelection: 3,
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Dabbable Concentrates Other',
        dosageRequired: false,
        dosageValue: 'None',
        cannabinoidMetric: '%',
        terpeneMetric: '%',
      },
    },
  },
  {
    key: 'intimateProducts',
    name: 'Intimate Products',
    specific: {
      lubricants: {
        name: 'Lubricants',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Intimate Products Lubricants',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      suppositories: {
        name: 'Suppositories',
        servingType: 'Pill',
        servingTypePlural: 'Pills',
        mgConversion: true,
        defaultSelection: 1,
        unitOfSelection: 'whole',
        max: 999,
        search_key: 'Intimate Products Suppositories',
        dosageRequired: true,
        dosageValue: 'Per Piece',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
      sprays: {
        name: 'Sprays',
        servingType: 'mg',
        unitOfSelection: 'whole',
        max: 99,
        search_key: 'Intimate Products Sprays',
        dosageRequired: true,
        dosageValue: '1',
        cannabinoidMetric: 'mg',
        terpeneMetric: 'mg',
      },
    },
  },
];

export const gender = {
  male: 'Male',
  female: 'Female',
  agender: 'Agender',
  nonBinary: 'Non-binary',
  other: 'Other',
  preferNotToSay: 'Prefer not to say',
};

export const ethnicity = {
  whiteNonHispanic: 'White Non Hispanic',
  hispanicLatino: 'Hispanic/Latino',
  americanIndian: 'American Indian',
  asian: 'Asian',
  blackOrAfricanAmerican: 'Black or African American',
  nativeHawaiianOrPacificIslander: 'Native Hawaiian or Pacific Islander',
  preferNotToSay: 'Prefer not to say',
};

export const educationLevel = {
  someHighSchool: 'Some High School',
  highSchoolDiploma: 'High School Diploma',
  someCollege: 'Some College',
  collegeDegree: 'College Degree',
  master: 'Masters/PHD',
  preferNotToSay: 'Prefer not to say',
};

export const marital = {
  neverMarried: 'Single(Never Married)',
  marriedOrCohabitation: 'Married, or in a domestic partnership',
  widowed: 'Widowed',
  divorced: 'Divorced',
  separated: 'Separated',
  preferNotToSay: 'Prefer not to say',
};

export const employment = {
  partTime: 'Employed, part time',
  fullTime: 'Employed, full time',
  selfEmployed: 'Self-Employed',
  lookingForWork: 'Not employed, looking for work',
  notLookingForWork: 'Not employed, not looking for work',
  retired: 'Retired',
  disabled: 'Disabled, not able to work',
  preferNotToSay: 'Prefer not to say',
};
