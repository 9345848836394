import './organization-edit-modal.less';
import uploadIcon from '../../../assets/product-edit-form/upload-icon.svg';
import MultipleSelectComponent from '../../../components/multiple-select-component/multiple-select-component';
import TextInputComponent from '../../../components/text-input-component/text-input-component';
import { Product } from '../../../models/model_product';
import { getProductByIdAPI } from '../../../services/api/productAPI/getProductById';
import { releaseProductLockAPI } from '../../../services/api/productAPI/releaseProductEditLock';
import {
  Terpenes,
  CannabinoidsData,
  StrainTypes,
  States,
  ProductTypes,
} from '../../../utils/options';
import { getBase64, getSizeInUnits, Net } from '../../../utils/utils';
import { Button, Form, Upload, notification, Typography, Select } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';
import React, { useState, useEffect } from 'react';

const { Option } = Select;

interface OrganizationModalProp {
  visible: boolean;
  hide: Function;
  onSuccess: Function;
  selectedProduct: any;
  action: string;
}

interface FormData {
  id: string;
  inner_id: string;
  brand_name: string;
  name: string;
  product_type: string;
  flavor: string;
  strain_type: string;
  strain: string;
  THC: string;
  CBD: string;
  THCa: string;
  THCv: string;
  CBDa: string;
  CBDv: string;
  CBN: string;
  CBG: string;
  CBC: string;
  CBCv: string;
  'Delta-8'?: string;
  Delta10?: string;
  HHC?: string;
  THCp?: string;
  THCo?: string;
  HXC?: string;
  HXCp?: string;
  CBT?: string;
  terpenes: string;
  cannabinoid: string;
  ex_store_url: string;
  covers: string;
  legal_states: string;
  note: string;
  action: string;
  dose_required: boolean;
  dosage: any;
  link?: string;
}

const OrganizationEditModal = (props: OrganizationModalProp) => {
  const { selectedProduct, action } = props;
  const {
    id,
    inner_id,
    cannabinoid,
    brand_name,
    legal_states,
    name,
    product_type,
    strain,
    strain_type,
    terpenes,
    ex_store_url,
    THC,
    CBD,
    THCa,
    THCv,
    CBDa,
    CBDv,
    CBN,
    CBG,
    CBC,
    CBCv,
    note,
    flavor,
  } = selectedProduct;
  const [previewImage, setPreviewImage] = useState('');
  const [adminImage, setAdminImage] = useState(null);
  const [imageFormat, setImageFormat] = useState('');
  const [imageSize, setImageSize] = useState('');
  const [approveModalLoading, setApproveModalLoading] = useState(false);
  const [cannabinoidValue, setCannabinoidValue] = useState([...cannabinoid]);
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [productType, setProductType] = useState(selectedProduct?.product_type?.type);
  const [productSpecific, setProductSpecific] = useState(selectedProduct?.product_type?.specific);
  const [form] = Form.useForm();
  useEffect(() => {
    if (selectedProduct?.legal_states?.length === 0) {
      onAutoFillStates(selectedProduct);
    }
  }, []);

  const switchDuplicatesModal = (refreshData?: boolean) => {
    setShowDuplicates(!showDuplicates);
    refreshData && props.onSuccess();
  };

  const closeEditModal = () => {
    releaseProductLockAPI(id);
    props.hide();
  };

  const onAutoFillStates = (product: Product) =>
    getProductByIdAPI(product.id).then((response) => {
      form.setFieldsValue({
        legal_states: response?.data?.brand_legal_states,
      });
    });

  const approveNewProduct = async (values: FormData) => {
    const { id, inner_id, covers } = selectedProduct;
    const {
      name,
      product_type,
      strain,
      strain_type,
      flavor,
      cannabinoid,
      ex_store_url,
      terpenes,
      legal_states,
      note,
      THC,
      CBD,
      THCa,
      THCv,
      CBDa,
      CBDv,
      CBN,
      CBG,
      CBC,
      CBCv,
      specific,
    } = values as any;

    const brand_name = (values as any).brand_name;

    const formData: FormData = {
      id,
      inner_id,
      brand_name,
      name,
      product_type: JSON.stringify({
        type: product_type,
        specific,
      }),
      flavor,
      strain_type,
      strain,
      THC,
      CBD,
      THCa,
      THCv,
      CBDa,
      CBDv,
      CBN,
      CBG,
      CBC,
      CBCv,
      terpenes: JSON.stringify(terpenes),
      cannabinoid: JSON.stringify(cannabinoid),
      ex_store_url,
      covers: JSON.stringify(covers),
      legal_states: JSON.stringify(
        legal_states.includes('all') ? States.map((s) => s.abbreviation) : legal_states
      ),
      note,
      dose_required: getDosageValues()?.dosageRequired,
      dosage: getDosageValues().dosageRequired ? { unit: getDosageValues()?.dosageValue } : {},
      link: covers[0].link,
      action,
    };

    formData['Delta-8'] = values['Delta-8'];
    formData['Delta10'] = values['Delta10'];
    formData['HHC'] = values['HHC'];
    formData['THCp'] = values['THCp'];
    formData['THCo'] = values['THCo'];
    formData['HXC'] = values['HXC'];
    formData['HXCp'] = values['HXCp'];
    formData['CBT'] = values['CBT'];

    setApproveModalLoading(true);

    try {
      if (adminImage) {
        const adminImageRes = await Net.req(
          '/product/admin-upload-image',
          {
            name: inner_id,
            adminImage,
          },
          'multipart'
        );
        if (adminImageRes && adminImageRes.link) {
          formData.link = adminImageRes.link;
        } else if (adminImageRes.status === 500) {
          setApproveModalLoading(false);
          return;
        }
      }

      if (!formData.link) {
        notification['error']({
          message: 'Please upload the images.',
        });
        setApproveModalLoading(false);
        return;
      }

      const res = await Net.req('/product/admin-update-product', formData, 'post');
      setApproveModalLoading(false);
      form.resetFields();
      props.hide();
      if (res?.status) {
        if (res.status === 'success') {
          props.onSuccess && props.onSuccess();
          notification['success']({
            message: 'Success!',
          });
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
    } catch (e) {
      setApproveModalLoading(false);
      form.resetFields();
      props.hide();
      notification['error']({
        message: 'Something went wrong!',
      });
    }
  };

  const getSpecificOptions = () => {
    if (!productType) {
      return [];
    }
    const type = ProductTypes.find(({ key }) => key === productType);
    if (!type) {
      return [];
    }
    Object.values(type.specific).forEach((val, index) => {
      val.key = Object.keys(type.specific)[index];
    });

    return Object.values(type.specific);
  };

  const getDosageValues = () => {
    const specificOptions = getSpecificOptions();
    return (
      specificOptions.find(({ key }) => productSpecific === key) || {
        dosageRequired: false,
        dosageValue: '',
      }
    );
  };

  const uploadFile = (file: RcFile) => {
    getBase64(file).then((res) => {
      setPreviewImage(res as any);

      setImageSize((file.size / 1000).toString());
      setImageFormat(file.type.split('/')[1].toUpperCase());
    });

    setAdminImage(file as any);
    return false;
  };

  const removeFile = () => {
    setPreviewImage('');
    setAdminImage(null);
  };

  const fileDialogProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    fileList: [],
    beforeUpload: uploadFile,
    onRemove: removeFile,
  };

  const submitForm = (values: FormData) => {
    approveNewProduct(values);
  };

  const onAutoFillStateForm = async (e: string) => {
    if (e.includes('all')) {
      const states = States.map((item) => item.abbreviation);
      form.setFieldsValue({
        legal_states: states,
      });
    } else {
      form.setFieldsValue({
        legal_states: e,
      });
    }
  };

  const initialValues: any = {
    cannabinoid,
    brand_name,
    legal_states,
    name,
    product_type: product_type.type,
    specific: product_type.specific,
    strain,
    strain_type,
    terpenes,
    id,
    inner_id,
    ex_store_url,
    THC,
    CBD,
    THCa,
    THCv,
    CBDa,
    CBDv,
    CBN,
    CBG,
    CBC,
    CBCv,
    note,
    flavor,
  };

  initialValues['Delta-8'] = selectedProduct['Delta-8'];
  initialValues['Delta10'] = selectedProduct['Delta10'];
  initialValues['HHC'] = selectedProduct['HHC'];
  initialValues['THCp'] = selectedProduct['THCp'];
  initialValues['THCo'] = selectedProduct['THCo'];
  initialValues['HXC'] = selectedProduct['HXC'];
  initialValues['HXCp'] = selectedProduct['HXCp'];
  initialValues['CBT'] = selectedProduct['CBT'];

  return (
    <Form
      form={form}
      onFinish={submitForm}
      className="edit-product-background"
      initialValues={initialValues}
    >
      <div className="product-edit-parent">
        <Upload {...fileDialogProps} className="product-upload-button">
          <div className="product-image-section">
            <img
              src={previewImage || (props.selectedProduct as Product).covers[0].link}
              alt="product"
              className="product-image"
            />
            <div className="product-upload-button">
              <img
                src={uploadIcon}
                className="upload-button-icon"
                id="add-product-image"
                alt="add-product"
              />
              Upload
            </div>
            {previewImage && (
              <div className="product-edit-image-text">
                {imageFormat} - {getSizeInUnits(imageSize)}
                <br />
                Size: {document.getElementById('add-product-image')?.clientWidth}x
                {document.getElementById('add-product-image')?.clientHeight}
                px
              </div>
            )}
            <div className="product-edit-bottom-image-description">
              Image is best if 200x200px min. Format .jpg, .png
            </div>
          </div>
        </Upload>
        <div className="product-edit-section-2">
          <div className="product-edit-sub-section-2">
            <div className="edit-product-header">
              <div className="edit-product-id">
                ID #{selectedProduct.inner_id}
                {action === 'pending-edit' && (
                  <>
                    <br />
                    <br />

                    <Button
                      type="primary"
                      disabled={approveModalLoading}
                      onClick={() => switchDuplicatesModal()}
                    >
                      Show Potential Duplicates ({selectedProduct.total_similar_products})
                    </Button>

                    <br />
                    <br />
                    <b>Number of reports {selectedProduct.total_similar_products}</b>
                    <br />
                    <br />
                  </>
                )}
              </div>
            </div>
            <div className="edit-product-option-buttons">
              <Button type="link" onClick={closeEditModal}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={approveModalLoading}
                disabled={approveModalLoading}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="data-row-2">
            <div>
              <TextInputComponent
                name="name"
                placeholder="Product Name"
                title="Product Name"
                size={'366px'}
                required={true}
              />
              <div className="select-container-1">
                <Typography.Text>Legal States</Typography.Text>
                <Form.Item name="legal_states" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    placeholder="Legal States"
                    mode="multiple"
                    optionFilterProp="children"
                    onChange={onAutoFillStateForm}
                    filterOption={(input, option: any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key="all" value="all">
                      All
                    </Option>
                    {States.map(({ name, abbreviation }) => (
                      <Option key={abbreviation} value={abbreviation}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <Button type="primary" onClick={() => form.setFieldsValue({ legal_states: [] })}>
                Clear All States
              </Button>

              <div className="select-container-1">
                <Typography.Text>Cannabinoid</Typography.Text>
                <Form.Item
                  name="cannabinoid"
                  rules={[
                    {
                      required: getDosageValues()?.dosageRequired,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={'Cannabinoid'}
                    optionFilterProp="children"
                    mode={'multiple'}
                    onChange={setCannabinoidValue as any}
                    filterOption={(input, option: any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key="all" value="" disabled>
                      Cannabinoid
                    </Option>
                    {CannabinoidsData.map((item: any) => (
                      <Option key={item['key']} value={item['key']}>
                        {item['label']}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="product-edit-section-3">
                <div className="product-edit-row">
                  <MultipleSelectComponent
                    name="terpenes"
                    placeholder="Terpenes"
                    title="Terpenes"
                    options={Terpenes}
                    keyItem={'key'}
                    valueItem={'name'}
                    multiple={true}
                    size={'366px'}
                    required={false}
                  />
                </div>
                <div className="product-edit-row">
                  <TextInputComponent
                    name="ex_store_url"
                    placeholder="Store URL"
                    title="Store URL"
                    size={'366px'}
                    required={true}
                  />
                </div>
                <div className="product-edit-row">
                  <TextInputComponent
                    name="note"
                    placeholder="Note"
                    title="Note"
                    size={'366px'}
                    required={false}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="product-edit-section-3-parent">
                <div className="product-edit-section-3">
                  <div className="product-edit-layer-1">
                    <div className="product-edit-row">
                      <div className="select-container-1 specific-modal">
                        <Typography.Text>Product Type</Typography.Text>
                        <Form.Item name="product_type" rules={[{ required: true }]}>
                          <Select placeholder={'Product Type'} onChange={setProductType as any}>
                            <Option key="all" value="">
                              Product Type
                            </Option>
                            {ProductTypes.map((item: any) => (
                              <Option key={item['key']} value={item['key']}>
                                {item['name']}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="product-spacer" />

                      <div className="select-container-1 specific-modal">
                        <Typography.Text>Specific</Typography.Text>
                        <Form.Item name="specific" rules={[{ required: true }]}>
                          <Select placeholder={'Specific'} onChange={setProductSpecific as any}>
                            <Option key="all" value="">
                              Specific
                            </Option>
                            {getSpecificOptions().map((item: any) => (
                              <Option key={item['key']} value={item['key']}>
                                {item['name']}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  {getDosageValues()?.dosageRequired && (
                    <div className="product-edit-layer-1">
                      <div>
                        Dosage Unit
                        <div className="dosage-unit-edit">{getDosageValues()?.dosageValue}</div>
                      </div>
                    </div>
                  )}
                  <div className="product-edit-layer-1">
                    <div className="product-edit-row">
                      <MultipleSelectComponent
                        name="strain_type"
                        placeholder="Strain Type"
                        title="Strain Type"
                        options={StrainTypes}
                        keyItem={'key'}
                        valueItem={'name'}
                        multiple={false}
                        size={'179px'}
                        required={false}
                      />

                      <div className="product-spacer" />

                      <TextInputComponent
                        name="strain"
                        placeholder="strain"
                        title="Strain"
                        size={'179px'}
                        required={false}
                      />
                    </div>
                  </div>
                  <div className="product-edit-row">
                    <TextInputComponent
                      name="flavor"
                      placeholder="Flavour"
                      title="Flavour"
                      size={'366px'}
                      required={false}
                    />
                  </div>
                </div>
              </div>
              {getDosageValues()?.dosageRequired && (
                <div className="product-edit-section-3">
                  <div className="product-edit-row-cannabinoid">
                    {cannabinoidValue.map((item: any) => (
                      <TextInputComponent
                        name={item}
                        key={item}
                        placeholder={`${item}mg`}
                        title={`${item}mg`}
                        size={'64px'}
                        required={true}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default OrganizationEditModal;
