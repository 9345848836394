import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Calendar, Col, Row } from 'antd';
import moment from 'moment';
import React, { useRef } from 'react';

const FORMAT = 'YYYY-MM-DD';

const headerRender = ({
  value,
  onChange,
}: {
  value: moment.Moment;
  onChange: (value: moment.Moment) => void;
}) => {
  const prevMonth = () => {
    onChange(value.clone().subtract(1, 'month'));
  };
  const nextMonth = () => {
    onChange(value.clone().add(1, 'month'));
  };

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Button
          shape="circle"
          icon={<LeftOutlined />}
          onClick={prevMonth}
          disabled={value.isSameOrBefore(moment(), 'month')}
        />
      </Col>
      <Col>{value.format('MMMM YYYY')}</Col>
      <Col>
        <Button shape="circle" icon={<RightOutlined />} onClick={nextMonth} />
      </Col>
    </Row>
  );
};
export interface DaysOffListProps {
  dates?: string[]; // Dates should be in the format 'YYYY-MM-DD'
  onChange?: (dates: string[]) => void;
}

const DaysOffList: React.FC<DaysOffListProps> = ({ dates = [], onChange }) => {
  const panelChangedRef = useRef(false);

  const handleDateSelect = (value: moment.Moment) => {
    if (panelChangedRef.current) {
      panelChangedRef.current = false;
      return;
    }

    const selectedDate = value.format(FORMAT);

    if (dates.includes(selectedDate)) {
      const newDates = dates.filter((date) => date !== selectedDate);
      onChange?.(newDates);
    } else {
      onChange?.([...dates, selectedDate]);
    }
  };

  const dateCellRender = (value: moment.Moment) => {
    const currentDay = value.format(FORMAT);

    if (dates.includes(currentDay)) {
      return (
        <div
          key={currentDay}
          style={{
            borderRadius: '5px',
            padding: '2px 5px',
            backgroundColor: '#f5222d',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Off
        </div>
      );
    }
    return null;
  };

  return (
    <Calendar
      mode="month"
      onPanelChange={() => {
        panelChangedRef.current = true;
      }}
      fullscreen={false}
      onSelect={handleDateSelect}
      dateCellRender={dateCellRender}
      headerRender={headerRender}
      disabledDate={(date) => date.isBefore(moment(), 'day')}
    />
  );
};

export default DaysOffList;
