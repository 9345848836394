import downArrow from '../../../assets/down_arrow.svg';
import {
  ethnicity as ethnicityData,
  gender as genderData,
  marital as maritalData,
  employment as employmentData,
  educationLevel as educationLevelData,
} from '../../../utils/options';
import { Input, Button, Select, DatePicker } from 'antd';
import './user_filter.less';
import _, { debounce } from 'lodash';
import moment from 'moment';
import React from 'react';

const { Option } = Select;
interface UserFilterProp {
  onSubmitFilter: Function;
  children: React.ReactNode;
}

export class UserFilter extends React.Component<UserFilterProp> {
  state = {
    search_key: '',
    country: '',
    ethnicity: '',
    gender: '',
    birthday: '',
    height: '',
    weight: '',
    education_level: '',
    marital_status: '',
    employment: '',
    showAdvanced: false,
  };

  emitChangeDebounced = debounce(this.props.onSubmitFilter as any, 500);

  componentWillUnmount() {
    this.emitChangeDebounced.cancel();
  }

  switchAdvanced = () => {
    this.setState({
      showAdvanced: !this.state.showAdvanced,
    });
  };

  handleSearch = () => {
    this.emitChangeDebounced(this.state);
  };

  resetFilter = () => {
    const resetParam = {
      search_key: '',
      country: '',
      ethnicity: '',
      gender: '',
      birthday: '',
      height: '',
      weight: '',
      education_level: '',
      marital_status: '',
      employment: '',
    };
    this.setState(resetParam, () => this.props.onSubmitFilter(resetParam));
  };

  render() {
    const {
      country,
      ethnicity,
      gender,
      height,
      weight,
      education_level,
      marital_status,
      employment,
      search_key,
    } = this.state;

    return (
      <div className="user-filter-root">
        <div className="product-filter">
          <div className="filter-item">
            <h1>{search_key}</h1>
            <Input.Search
              placeholder="Search"
              onChange={this.onSearchChange('search_key')}
              value={search_key}
              allowClear={true}
            />
          </div>

          <Button type="link" onClick={this.switchAdvanced}>
            Advanced search
            <img
              src={downArrow}
              className="search-bar-arrow-icon"
              alt="down-arrow"
              style={this.state.showAdvanced ? { transform: 'rotate(180deg)' } : {}}
            />
          </Button>
          {this.props.children}
        </div>

        {this.state.showAdvanced ? (
          <div className="product-filter">
            <div className="filter-item">
              <Input
                placeholder="Country"
                onChange={this.onSearchChange('country')}
                className="product-name-input"
                value={country}
                allowClear={true}
              />
            </div>

            <div className="filter-item">
              <Select
                showSearch
                className="product-type-select"
                placeholder="Ethinicity"
                optionFilterProp="children"
                onChange={this.onFilterChange('ethnicity') as any}
                value={ethnicity}
                filterOption={(input, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key="all" value={''} disabled>
                  Ethinicity
                </Option>
                {_.values(ethnicityData).map((name, index) => (
                  <Option key={_.keys(ethnicityData)[index]} value={_.keys(ethnicityData)[index]}>
                    {name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="filter-item">
              <Select
                showSearch
                className="product-type-select"
                placeholder="Gender"
                optionFilterProp="children"
                onChange={this.onFilterChange('gender') as any}
                value={gender}
                filterOption={(input, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key="all" value={''} disabled>
                  Gender
                </Option>
                {_.values(genderData).map((name, index) => (
                  <Option key={_.keys(genderData)[index]} value={_.keys(genderData)[index]}>
                    {name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="filter-item">
              <DatePicker
                placeholder="Birthday"
                onChange={this.onFilterChange('birthday') as any}
              />
            </div>

            <div className="filter-item">
              <Input
                placeholder="Height"
                onChange={this.onSearchChange('height')}
                className="product-name-input"
                value={height}
                allowClear={true}
              />
            </div>

            <div className="filter-item">
              <Input
                placeholder="Weight"
                onChange={this.onSearchChange('weight')}
                className="product-name-input"
                value={weight}
                allowClear={true}
              />
            </div>

            <div className="filter-item">
              <Select
                showSearch
                className="product-type-select"
                placeholder="Marital Status"
                optionFilterProp="children"
                onChange={this.onFilterChange('marital_status') as any}
                value={marital_status}
                filterOption={(input, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key="all" value={''} disabled>
                  Marital Status
                </Option>
                {_.values(maritalData).map((name, index) => (
                  <Option key={_.keys(maritalData)[index]} value={_.keys(maritalData)[index]}>
                    {name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="filter-item">
              <Select
                showSearch
                className="product-type-select"
                placeholder="Education Level"
                optionFilterProp="children"
                onChange={this.onFilterChange('education_level') as any}
                value={education_level}
                filterOption={(input, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key="all" value={''} disabled>
                  Education Level
                </Option>
                {_.values(educationLevelData).map((name, index) => (
                  <Option
                    key={_.keys(educationLevelData)[index]}
                    value={_.keys(educationLevelData)[index]}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="filter-item">
              <Select
                showSearch
                className="product-type-select"
                placeholder="Employment"
                optionFilterProp="children"
                onChange={this.onFilterChange('employment') as any}
                value={employment}
                filterOption={(input, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key="all" value={''} disabled>
                  Employment
                </Option>
                {_.values(employmentData).map((name, index) => (
                  <Option key={_.keys(employmentData)[index]} value={_.keys(employmentData)[index]}>
                    {name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="filter-item submit-button">
              <Button onClick={this.resetFilter} type="primary">
                Clear Filter
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private onFilterChange = (field: string) => async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (field === 'birthday') {
      await this.setState({
        [field]: moment(e as any).format('YYYY-MM-DD'),
      });
    } else {
      await this.setState({
        [field]: ['country', 'height', 'weight'].includes(field) ? e.target.value : e,
      });
    }
    this.props.onSubmitFilter(this.state);
  };

  private onSearchChange = (field: string) => async (e: React.ChangeEvent<HTMLInputElement>) => {
    await this.setState(
      {
        [field]: ['search_key', 'country', 'height', 'weight'].includes(field) ? e.target.value : e,
      },
      this.handleSearch
    );
  };
}
