import { encodeQuery } from '../../../utils/utils';
import axios from '../../apiClient';

export const bulkReactivateProductAPI = (data: any[]) =>
  axios.post('/product/reactivate-multiple-product', {
    products: data,
  });

export const bulkReactivateProductPaginatedAPI = (payload: any) => {
  const url = encodeQuery('/product/paginated-reactivate-multiple-product', payload);
  return axios.put(url);
};
