import logo from '../../assets/jointly-white.svg';
import { Net } from '../../utils/utils';
import { Layout, Input, Form, message, Button, Col } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './reset_password.less';
const { Header, Content } = Layout;

const inputTips = 'Password should have at least 8 characters!';
const inputNoSame = 'The passwords entered do not match';

message.config({
  top: 250,
});

interface MatchParams {
  uid: string;
}
export class ResetPasswordView extends React.Component<RouteComponentProps> {
  state = { password: '', passwordCfm: '', success: false };
  render() {
    return (
      <Layout className="reset-password-view">
        <Header>
          <img src={logo} alt="Jointly" />
        </Header>
        <Content>
          <Col lg={8} md={10} sm={20} xs={22}>
            {this.state.success ? (
              <div className="content-box">
                <p className="title">Success</p>
                <p className="des">You can now use your new password to log in to Jointly</p>
              </div>
            ) : (
              <div className="content-box">
                <p className="title">Reset your password</p>
                <Col className="form-box" lg={{ span: 22, offset: 1 }} sm={{ span: 24 }}>
                  <Form className="password-box" onFinish={this.onSubmit} layout="vertical">
                    <Form.Item label="New Password">
                      <Input type="password" onChange={(e) => this.recPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Confirm New Password">
                      <Input
                        type="password"
                        onChange={(e) => this.recPasswordCfm(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" className="submit-btn" htmlType="submit">
                        RESET PASSWORD
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </div>
            )}
          </Col>
        </Content>
      </Layout>
    );
  }

  private onSubmit = async (e: React.FormEvent) => {
    const { password, passwordCfm } = this.state;
    if (!password) {
      message.error(inputTips);
      return;
    }
    if (password !== passwordCfm) {
      message.error(inputNoSame);
      return;
    }

    const { uid } = this.props.match.params as MatchParams;
    const res = await Net.req('/user/reset-password', { uid, password }, 'post');
    if (res && res.message === 'success') {
      this.setState({ success: true });
    } else {
      message.error(res.message);
    }
  };

  private recPassword(password: string) {
    this.setState({ password });
  }
  private recPasswordCfm(passwordCfm: string) {
    this.setState({ passwordCfm });
  }
}
