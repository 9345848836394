import { deleteSetting, getSetting, updateSetting } from '../../services/api/settings';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Spin, Alert, Layout, Button, Space, notification } from 'antd';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';

type Props = {
  setting: string;
  onSuccessDeleting: () => void;
};

const Setting = ({ setting, onSuccessDeleting }: Props) => {
  const [original, setOriginal] = useState<any>(null);
  const [current, setCurrent] = useState<any>(null);

  const settingQuery = useQuery({
    queryKey: ['getSetting', setting],
    queryFn: () => getSetting(setting),
    onSuccess: (data) => {
      setOriginal(data);
      setCurrent(null);
    },
    enabled: !!setting,
  });

  const queryClient = useQueryClient();

  const editSettingMutation = useMutation({
    mutationFn: updateSetting,
    onSuccess: () => {
      notification.success({
        message: 'Updated successfully',
      });
      queryClient.invalidateQueries({ queryKey: ['getSetting'] });
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const deleteSettingMutation = useMutation({
    mutationFn: deleteSetting,
    onSuccess: () => {
      notification.success({
        message: 'Deleted successfully',
      });
      queryClient.invalidateQueries({ queryKey: ['getSettings'] });
      onSuccessDeleting();
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  if (!setting) return <div />;

  if (settingQuery.isLoading)
    return (
      <Layout
        style={{
          height: 400,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </Layout>
    );

  if (settingQuery.isError)
    return (
      <Layout style={{ alignItems: 'center' }}>
        <Alert message="Error" description={`Unable to load ${setting}`} type="error" showIcon />
      </Layout>
    );

  return (
    <div>
      <ReactJson
        collapsed={true}
        name={setting}
        displayDataTypes={false}
        src={original || {}}
        quotesOnKeys={false}
        onEdit={({ updated_src }) => {
          setCurrent(updated_src);
        }}
        onAdd={() => true}
        onDelete={({ updated_src }) => {
          setCurrent(updated_src);
        }}
      />
      <br />
      <Space wrap>
        <Button
          type="primary"
          danger
          loading={deleteSettingMutation.isLoading}
          onClick={() => {
            deleteSettingMutation.mutate(setting);
          }}
        >
          Delete
        </Button>
        {!!original &&
          !!current &&
          JSON.stringify(original?.value) !== JSON.stringify(current?.value) && (
            <>
              <Button
                type="primary"
                loading={editSettingMutation.isLoading}
                onClick={() => {
                  editSettingMutation.mutate({
                    setting,
                    payload: current.value,
                  });
                }}
              >
                Update
              </Button>
              <Button
                onClick={() => {
                  setOriginal({ ...original });
                  setCurrent(null);
                }}
              >
                Cancel
              </Button>
            </>
          )}
      </Space>
    </div>
  );
};

export default Setting;
