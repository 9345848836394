import { MainView } from './views/main/main_view';
import { ResetPasswordView } from './views/reset_password/reset_password';
import { VerifyView } from './views/user/verify';
import { UserSigninView } from './views/user_signin/user_signin';
import { UserSignupView } from './views/user_signup/user_signup';
import './index.less';
import '@fontsource/quicksand';
import Success from './views/user_signup/user_signup_success';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'module-reaction';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

const appRouters: RouterCfg[] = [
  {
    path: '/resetpassword/:uid',
    component: ResetPasswordView,
    exact: true,
  },
  {
    path: '/sign-in',
    component: UserSigninView,
    exact: true,
  },
  {
    path: '/sign-up/success',
    component: Success,
    exact: true,
  },
  {
    path: '/sign-up/:uid',
    component: UserSignupView,
    exact: true,
  },
  {
    path: '/--hidden-sign-up',
    component: UserSignupView,
    exact: true,
  },
  {
    path: '/account/verify/:uid',
    component: VerifyView,
    exact: true,
  },
  {
    path: '*',
    component: MainView,
  },
];

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <Switch>
        {appRouters.map((_) => (
          <Route key={_.path} {..._} />
        ))}
      </Switch>
    </Router>
  </QueryClientProvider>
);

ReactDOM.render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById('app')
);
