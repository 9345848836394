import { Net } from '../utils/utils';
import { message } from 'antd';
import { ModuleStore, ModuleAction, KV, doAction } from 'module-reaction';

export type RetailOrgOrLoc = {
  id: string;
  name: string;
};

export interface RetailUser {
  id?: string;
  email?: string;
  avatar?: Doc;
  permission?: string;
  status?: string;
  retailLocation: RetailOrgOrLoc;
  retailOrganization: RetailOrgOrLoc;
}
export interface ModleRetailUser extends ModuleStore {
  logined?: boolean;
  list?: RetailUser[]; // current result for show
  total?: number; // total num of current search
  searchKey?: string; // search keyword
  retailOrganizationId?: string;
  retailLocationId?: string;
  pageSize?: number; // when fetch, pageSize=limit
  pageIdx?: number; //start with 0, when fetch, skip=pageSize * pageIdx
  pageLoading?: boolean;
  loading?: boolean;
  hasMore?: boolean;
}
export const MODULE_RETAIL_USER = 'MODULE_RETAIL_USER';
export const modleRetailUser: ModleRetailUser = {
  module: MODULE_RETAIL_USER,
  logined: false,
  list: [],
  total: 0,
  searchKey: '',
  retailOrganizationId: '',
  retailLocationId: '',
  pageIdx: 1,
  pageSize: 20,
  loading: true,
  pageLoading: false,
  hasMore: true,
};

export const LoadingRetailUsersAction: ModuleAction<KV, ModleRetailUser> = {
  module: MODULE_RETAIL_USER,
  name: 'LoadingRetailUsersAction',
  process: async () => ({ list: [], total: 0, loading: true }),
};

export const FetchRetailUsersAction: ModuleAction<KV, ModleRetailUser> = {
  module: MODULE_RETAIL_USER,
  name: 'FetchRetailUsersAction',
  process: async (payload: KV, model: ModleRetailUser) => {
    const {
      search_key: searchKey,
      retail_organization_id: retailOrganizationId,
      retail_location_id: retailLocationId,
      scope,
      pageSize,
      pageIdx,
      showNoResults = true,
    } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/org/v1/retail-users',
      {
        searchKey,
        ...(retailOrganizationId &&
          retailOrganizationId !== '' && { retailOrganizationId: retailOrganizationId }),
        ...(retailLocationId && retailLocationId !== '' && { retailLocationId: retailLocationId }),
        scope,
        limit: pageSize,
        skip: (pageSize ?? 0) * ((pageIdx ?? 0) - 1),
      },
      'get'
    );
    if (res.list && res.list.length) {
      return {
        list: res.list,
        total: res.total,
        searchKey,
        scope,
        retailOrganizationId,
        retailLocationId,
        pageSize,
        pageIdx,
        loading: false,
        pageLoading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    if (showNoResults) message.info('No results');
    return { list: [], total: 0, loading: false, pageLoading: false };
  },
};

const FetchMoreRetailUsersActionPageLoading: ModuleAction<KV, ModleRetailUser> = {
  module: MODULE_RETAIL_USER,
  name: 'FetchMoreRetailUsersActionPageLoading',
  process: async (payload: KV, model: ModleRetailUser) => {
    const {
      search_key: searchKey,
      retail_organization_id: retailOrganizationId,
      retail_location_id: retailLocationId,
      scope,
      pageSize,
      pageIdx,
      showNoResults = true,
    } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/org/v1/retail-users',
      {
        searchKey,
        ...(retailOrganizationId &&
          retailOrganizationId !== '' && { retailOrganizationId: retailOrganizationId }),
        ...(retailLocationId && retailLocationId !== '' && { retailLocationId: retailLocationId }),
        scope: 'organization',
        limit: pageSize,
        skip: (pageSize ?? 0) * ((pageIdx ?? 0) - 1),
      },
      'get'
    );
    if (res.list && res.list.length) {
      return {
        list: [...(model.list ?? []), ...res.list],
        searchKey,
        scope,
        pageSize,
        pageIdx,
        pageLoading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    return { total: 0, pageLoading: false, hasMore: false };
  },
};

export const FetchMoreRetailUsersAction: ModuleAction<KV, ModleRetailUser> = {
  module: MODULE_RETAIL_USER,
  name: 'FetchMoreRetailUsersAction',
  process: async (payload: KV) => {
    doAction(FetchMoreRetailUsersActionPageLoading, payload);
    return { pageLoading: true };
  },
};
