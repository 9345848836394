import downArrow from '../../assets/down_arrow.svg';
import { States } from '../../utils/options';
import { Input, Button, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import './filter.less';

interface FilterProps {
  search_key?: string;
  brand_name?: string;
  product_type?: string;
  productSpecific?: string;
  states?: string[];
  store_url?: string;
}

interface ProductFilterProp {
  onFilter: Function;
  activateBulkActions: Function;
  currentFilter: FilterProps;
  setCurrentPage: Function;
  children: JSX.Element;
  isBulkNeeded?: boolean;
}

function ProductsFilter({
  onFilter,
  activateBulkActions,
  currentFilter,
  setCurrentPage,
  children,
  isBulkNeeded,
}: ProductFilterProp) {
  const [search_key, setSearch_key] = useState('');
  const [brand_name, setBrand_name] = useState('');
  const [product_type, setProduct_type] = useState('');
  const [productSpecific, setProductSpecific] = useState('');
  const [states, setStates] = useState('');
  const [store_url, setStore_url] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(true);

  const resetFilter = () => {
    setSearch_key('');
    setBrand_name('');
    setProduct_type('');
    setProductSpecific('');
    setStates('');
    setStore_url('');
    onFilter({});
  };

  useEffect(() => {
    setCurrentPage(1);
    resetFilter();
  }, []);

  useEffect(() => {
    const queryObject = {
      ...(!!search_key && { search_key }),
      ...(!!brand_name && { brand_name }),
      ...(!!product_type && { product_type }),
      ...(!!productSpecific && { productSpecific }),
      ...(!!states && { states }),
      ...(!!store_url && { store_url }),
    };
    setSearchDisabled(JSON.stringify(queryObject) === JSON.stringify(currentFilter));
  }, [currentFilter, search_key, brand_name, product_type, productSpecific, states, store_url]);

  const search = useCallback(() => {
    setCurrentPage(1);
    const queryObject = {
      ...(!!search_key && { search_key }),
      ...(!!brand_name && { brand_name }),
      ...(!!product_type && { product_type }),
      ...(!!productSpecific && { productSpecific }),
      ...(!!states && { states }),
      ...(!!store_url && { store_url }),
    };
    if (Object.keys(queryObject).length) {
      onFilter(queryObject);
    } else if (Object.keys(currentFilter).length) {
      onFilter({});
    }
  }, [search_key, brand_name, product_type, productSpecific, states, store_url]);

  return (
    <div className="filter-root">
      <div className="product-filter">
        <div className="filter-item">
          <Input.Search
            placeholder="Search"
            onChange={(e) => setSearch_key(e.target.value)}
            value={search_key}
            allowClear={true}
            onPressEnter={() => search()}
          />
        </div>

        <Button type="link" onClick={() => setShowAdvanced(!showAdvanced)}>
          Advanced search
          <img
            src={downArrow}
            className="search-bar-arrow-icon"
            alt="search-bar"
            style={showAdvanced ? { transform: 'rotate(180deg)' } : {}}
          />
        </Button>

        {isBulkNeeded && (
          <Select
            onSelect={(e) => activateBulkActions(e)}
            className="filter-search-bulk-actions"
            placeholder="Bulk Actions"
            value=""
          >
            <Select.Option style={{ display: 'none' }} value="" disabled>
              Bulk Actions
            </Select.Option>
            <Select.Option value="edit">Edit</Select.Option>
          </Select>
        )}

        <Button
          className="searchButton"
          onClick={() => search()}
          type="primary"
          disabled={searchDisabled}
        >
          Search
        </Button>

        <Button className="searchButton" onClick={() => resetFilter()} type="primary">
          Reset
        </Button>
        {children}
      </div>

      {showAdvanced ? (
        <div className="product-filter">
          <div className="filter-item">
            <Select
              showSearch
              className="product-filter-name-3"
              placeholder="Legal States"
              optionFilterProp="children"
              onChange={(e) => setStates(e.join(','))}
              mode="multiple"
              value={states ? (states as any).split(',') : []}
              filterOption={(input, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option style={{ display: 'none' }} key="all" value="" disabled>
                Legal States
              </Select.Option>
              {States.map(({ name, abbreviation }) => (
                <Select.Option key={abbreviation} value={abbreviation}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item">
            <Input
              placeholder="Store URL"
              onChange={(e) => setStore_url(e.target.value)}
              value={store_url}
              className="product-filter-name-2"
              allowClear={true}
              onPressEnter={() => search()}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ProductsFilter;
