import React from 'react';
import { List, Button, Modal, notification, Checkbox, Dropdown, Menu, Spin } from 'antd';
import {
  PendingBrand,
  FetchDiscontinuedBrandsAction,
  EmptyDiscontinuedBrandsAction,
  FetchMoreDiscontinuedBrandsAction,
  model_discontinued_brand,
  Brand,
  BrandListProp,
} from '../../../models/model_brand';
import { model_page_loading, PageLoadingAction } from '../../../models/model_product';
import { mapProp, doAction } from 'module-reaction';
import './discontinued_brand_list.less';
import downArrow from '../../../assets/down_arrow.svg';
import { bulkReactivateBrandAPI } from '../../../services/api/brandAPI/bulkReactivateBrandAPI';
import { BrandModal } from '../brand_modal';
import { DisontinuedBrandFilter } from '../discontinued_brand_filter/discontinued_brand_filter';

@mapProp(model_page_loading, 'pageLoading', 'filterLoading')
@mapProp(model_discontinued_brand)
export class DiscontinuedBrandListView extends React.Component<BrandListProp, {}> {
  state = {
    selectedBrand: {} as Brand,
    showApproveModal: false,
    selectedBrands: [],
    activeSort: '',
    sortActive: false,
    sortedProductList: [],
    showActivateModal: false,
    activateModalLoading: false,
    showBulkActivateModal: false,
    filter: {
      sort_by: '',
      order: '',
    },
  };

  sortItems = (sortItem: string) => {
    const { filter } = this.state;
    doAction(PageLoadingAction, { filterLoading: true });
    if (filter.sort_by === '' || (filter.sort_by !== sortItem && filter.order !== 'asc')) {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'desc' },
        },
        () =>
          doAction(FetchDiscontinuedBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'desc',
          })
      );
    } else if (filter.order === 'asc') {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'desc' },
        },
        () =>
          doAction(FetchDiscontinuedBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'desc',
          })
      );
    } else {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'asc' },
        },
        () =>
          doAction(FetchDiscontinuedBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'asc',
          })
      );
    }
  };

  checkProduct = (itemId: string) => {
    const data: string[] = [...this.state.selectedBrands];
    const itemIndex = data.findIndex((item: string) => item === itemId);
    if (itemIndex === -1) {
      data.push(itemId);
    } else {
      data.splice(itemIndex, 1);
    }
    this.setState({
      selectedBrands: [...data],
    });
  };

  checkAllProducts = () => {
    let data: string[] = this.state.selectedBrands;
    if (data.length !== this.props.list.length) {
      data = this.props.list.map((item) => item.id);
    } else {
      data = [];
    }
    this.setState({
      selectedBrands: [...data],
    });
  };

  handleActivateAction = (brandItem: Brand) => {
    this.setState({
      showActivateModal: true,
      selectedBrand: brandItem,
    });
  };

  showBulkActivateBrand = () => {
    this.setState({
      showBulkActivateModal: true,
    });
  };

  renderDropdownOverlay = (item: PendingBrand) => (
    <Menu>
      <Menu.Item key="edit">
        <Button type="link" key="activate" onClick={() => this.handleActivateAction(item)}>
          Activate
        </Button>
      </Menu.Item>
    </Menu>
  );

  loadMoreBrands = (e: any) => {
    const { pageSize, pageIdx, hasMore, pageLoading } = this.props;
    const brandListCon = document.getElementById('brand-list-con')!;
    if (
      brandListCon?.scrollHeight - brandListCon?.scrollTop - brandListCon?.offsetHeight < 40 &&
      !pageLoading &&
      hasMore
    ) {
      this.onPageChange(pageIdx + 1, pageSize);
    }
  };

  render() {
    const { list, loading, hasMore, filterLoading } = this.props;
    const {
      selectedBrand,
      showApproveModal,
      selectedBrands,
      showActivateModal,
      activateModalLoading,
      showBulkActivateModal,
    } = this.state;

    const activeSort = this.state.filter.order === 'desc' ? this.state.filter.sort_by : '';
    if (loading) {
      return (
        <div className="pending-loading">
          <Spin size="large" />
        </div>
      );
    }

    return (
      <div className="list-view discontinued-brand-list-view">
        <DisontinuedBrandFilter
          onSearch={this.onSearch}
          selectedBrands={selectedBrands}
          showBulkActivateBrand={this.showBulkActivateBrand}
        >
          {/* <div className="add-brand-section">
            <Button
              onClick={this.addNew}
              type="primary"
              className="pending-add-brand-button"
            >
              + ADD BRAND
            </Button>
          </div> */}
        </DisontinuedBrandFilter>
        <div className="list-con" id="brand-list-con" onScroll={this.loadMoreBrands}>
          <List
            className="brand-list"
            dataSource={list}
            header={
              <>
                <span className="span-1">
                  <Checkbox
                    indeterminate={
                      selectedBrands.length > 0 && list.length !== selectedBrands.length
                    }
                    onChange={this.checkAllProducts}
                    checked={
                      this.props.list.length === selectedBrands.length &&
                      this.props.list.length !== 0
                    }
                  />
                </span>
                <span className="span-1">ACTION</span>
                <span className="span-2">IMAGE</span>
                <span className="span-2" onClick={() => this.sortItems('inner_id')}>
                  ID
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'inner_id'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2" onClick={() => this.sortItems('note')}>
                  NOTE
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'note'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2 ellipse" onClick={() => this.sortItems('name')}>
                  NAME
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'name'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2" onClick={() => this.sortItems('company')}>
                  COMPANY
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'company'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-3" onClick={() => this.sortItems('introduction')}>
                  DESCRIPTION
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'introduction'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-3 ellipse">IMAGE</span>
              </>
            }
            renderItem={(item: PendingBrand, index: number) => {
              if (filterLoading) {
                if (index === 0)
                  return (
                    <div className="pending-loading">
                      <Spin size="large" />
                    </div>
                  );
                return null;
              }
              return (
                <>
                  <List.Item key={`${item.inner_id}`}>
                    <span className="span-1">
                      <Checkbox
                        onChange={(_) => this.checkProduct(item.id)}
                        checked={selectedBrands.includes(item.id as never)}
                      />
                    </span>
                    <span className="span-1">
                      <Dropdown
                        className="header-avatar-drop"
                        overlay={this.renderDropdownOverlay(item)}
                      >
                        <div className="header-logo-parent">Select</div>
                      </Dropdown>
                    </span>
                    <span className="span-2">
                      <img
                        src={item.covers[0]?.link}
                        className="product-list-image"
                        alt="product-list"
                      />
                    </span>
                    <span className="span-2">#{item.inner_id}</span>
                    <span className="span-2">{item.note}</span>
                    <span className="span-2 ellipse">{item.name}</span>
                    <span className="span-2">{item.company}</span>
                    <span className="span-3">{item.introduction}</span>
                    <span className="span-3 ellipse">
                      <a href={item.covers[0].link} rel="noreferrer" target="_blank">
                        Link
                      </a>
                    </span>
                  </List.Item>
                </>
              );
            }}
          />
          {hasMore && (
            <div className="loadmore">
              <Spin size="large" />
            </div>
          )}
        </div>

        <Modal
          visible={showActivateModal || showBulkActivateModal}
          title={'Activate Brand Request'}
          onOk={this.activateBrand}
          onCancel={this.hideActivateModal}
          confirmLoading={activateModalLoading}
        >
          <p>
            Are you sure you want to activate {showBulkActivateModal ? 'these' : 'this'} brand
            {showBulkActivateModal ? 's' : ''}?
          </p>
        </Modal>

        <BrandModal
          visible={showApproveModal}
          action={
            (selectedBrand as Brand).inner_id
              ? (selectedBrand as Brand).edit
                ? 'pending-edit'
                : 'approve'
              : 'new'
          }
          selectedBrand={selectedBrand}
          hide={this.hideApproveModal}
          onSuccess={this.handleSuccess}
        />
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    if (!this.props.list.length || this.props.list?.length === 0) {
      doAction(FetchDiscontinuedBrandsAction);
    }
  }

  componentWillUnmount() {
    doAction(EmptyDiscontinuedBrandsAction);
  }

  private onSearch = (searchKey: string) => {
    doAction(FetchDiscontinuedBrandsAction, { searchKey, pageIdx: 1 });
  };

  private onPageChange = (pageIdx?: number, pageSize?: number) => {
    doAction(PageLoadingAction, { pageLoading: true });
    const { sort_by, order } = this.state.filter;
    doAction(FetchMoreDiscontinuedBrandsAction, {
      pageIdx,
      pageSize,
      sort_by,
      order,
    });
  };

  private hideApproveModal = () => {
    this.setState({
      showApproveModal: false,
      selectedBrand: {},
    });
  };

  private handleSuccess = () => {
    doAction(FetchDiscontinuedBrandsAction);
  };

  private hideActivateModal = () => {
    this.setState({
      showActivateModal: false,
      showBulkActivateModal: false,
      selectedBrands: [],
      selectedBrand: {},
      activateModalLoading: false,
    });
  };

  private activateBrand = async () => {
    const { id } = this.state.selectedBrand as Brand;

    this.setState({ activateModalLoading: true });

    let data = [];

    if (this.state.showBulkActivateModal) {
      data = [...this.state.selectedBrands];
    } else {
      data = [id];
    }
    bulkReactivateBrandAPI([...data])
      .then((res) => {
        this.hideActivateModal();
        if (res?.data.status) {
          if (res.data.status === 'success') {
            doAction(FetchDiscontinuedBrandsAction);
            notification['success']({
              message: 'Activated brand successfully!',
            });
          } else {
            notification['error']({
              message: 'Something went wrong!',
            });
          }
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      })
      .catch((error) => {
        this.hideActivateModal();
        notification['error']({
          message: 'Something went wrong!',
        });
      });
  };
}
