import BrandSelect from './BrandSelect';
import downArrow from '../../../../assets/down_arrow.svg';
import './product_filter.less';
import { ProductFilter, previousSearchAction } from '../../../../models/model_products_paginated';
import { paginatedSearchAdminUsers } from '../../../../services/api/productAPI/generalSearchProducts';
import { States, ProductTypes } from '../../../../utils/options';
import { formatQuery } from '../table-wrapper';
import { useQuery } from '@tanstack/react-query';
import { Input, Button, Select, notification, Form, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { doAction } from 'module-reaction';
import { BaseSelectRef } from 'rc-select';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-grid-system';

export interface FilterProductBody {
  brand_name?: string;
  brand?: string;
  product_type?: string;
  productSpecific?: string;
  search_key?: string;
  states?: string;
  store_url?: string;
  last_updated_by?: string;
  reviewRequired?: boolean;
}

const emptyFilter = {};

interface ProductFilterProp {
  onFilter: (form: FilterProductBody) => void;
  activateBulkActions: (arg: string) => void;
  currentFilter?: ProductFilter;
  collection?: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  children?: JSX.Element;
  approvedMode?: boolean;
  defaultBrand?: string;
  defaultProductType?: string;
  shouldPrePopulate?: boolean;
}

function ProductsFilter({
  onFilter,
  activateBulkActions,
  currentFilter,
  collection,
  setCurrentPage,
  children,
  approvedMode,
  defaultBrand,
  defaultProductType,
  shouldPrePopulate,
}: ProductFilterProp) {
  const brandSearchRef = useRef<BaseSelectRef>(null);
  const [search_key, setSearch_key] = useState('');
  const [brand, setBrand] = useState('');
  // This is used to track the search value of the brand selection
  const [brandName, setBrandName] = useState('');
  const [product_type, setProduct_type] = useState('');
  const [productSpecific, setProductSpecific] = useState('');
  const [states, setStates] = useState('');
  const [store_url, setStore_url] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(!!approvedMode);
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [admin, setAdmin] = useState('');
  const [enableAdminSearch, setEnableAdminSearch] = useState(false);
  const [newCannabinoid, setNewCannabinoid] = useState(2);

  const adminsQuery = useQuery({
    queryKey: ['getPaginatedAdmins', currentFilter, collection],
    queryFn: () => paginatedSearchAdminUsers(formatQuery({ ...currentFilter, collection })),
    onError: () => {
      notification.error({
        message: 'Something went wrong',
        description: 'Please try later',
      });
    },
    enabled: enableAdminSearch,
  });

  const [form] = Form.useForm();

  const resetFilter = useCallback(() => {
    setSearch_key('');
    setBrand('');
    setProduct_type('');
    setProductSpecific('');
    setStates('');
    setStore_url('');
    setAdmin('');
    setNewCannabinoid(2);
    onFilter(emptyFilter);
    form.resetFields();
  }, [onFilter, form]);

  useEffect(() => {
    if (setCurrentPage) setCurrentPage(1);
    resetFilter();
  }, [collection]);

  useEffect(() => {
    if (brandSearchRef.current && approvedMode) {
      brandSearchRef.current.focus();
    }
  }, [approvedMode]);

  const columnsDimensions = {
    true: {
      xs: 12,
      sm: 6,
      lg: 4,
      xl: 3,
      xxl: 2.2,
    },
    false: {
      xs: 12,
      sm: 6,
      lg: 4,
      xl: 3,
      xxl: 1.5,
    },
  };

  useEffect(() => {
    // Set default product type/specific
    if (defaultProductType) {
      const foundProductType = ProductTypes.find((type) =>
        defaultProductType.toLowerCase().includes(type.name.toLowerCase())
      );
      if (foundProductType) {
        const _type = foundProductType.key.toLowerCase();
        setProduct_type(_type);
        const specificType = ProductTypes.find((type) => type.key.toLowerCase() === _type);
        if (specificType) {
          let _foundSpecificTypeKey;
          for (const [key, value] of Object.entries(specificType.specific)) {
            if (defaultProductType.toLowerCase().includes(value.name.toLowerCase())) {
              _foundSpecificTypeKey = key;
            }
          }
          !!_foundSpecificTypeKey && setProductSpecific(_foundSpecificTypeKey);
        }
      }
    }
  }, [defaultBrand, defaultProductType]);

  useEffect(() => {
    const queryObject = {
      ...(!!search_key && { search_key }),
      ...(!!brand && { brand }),
      ...(!!product_type && { product_type }),
      ...(!!productSpecific && { productSpecific }),
      ...(!!states && { states }),
      ...(!!admin && { last_updated_by: admin }),
      ...(!!store_url && { store_url }),
      ...([0, 1].includes(newCannabinoid) && {
        reviewRequired: !!newCannabinoid,
      }),
    };

    setSearchDisabled(JSON.stringify(queryObject) === JSON.stringify(currentFilter));
  }, [
    currentFilter,
    search_key,
    brand,
    product_type,
    productSpecific,
    states,
    store_url,
    admin,
    newCannabinoid,
  ]);

  useEffect(() => {
    if (
      shouldPrePopulate &&
      typeof currentFilter === 'object' &&
      Object.keys(currentFilter).length
    ) {
      setSearch_key(currentFilter.search_key || '');
      setBrand(currentFilter.brand || '');
      setProduct_type(currentFilter.product_type || '');
      setProductSpecific(currentFilter.productSpecific || '');
      setStates(currentFilter.states || '');
      setStore_url(currentFilter.store_url || '');
      setAdmin(currentFilter.last_updated_by || '');
      if (currentFilter?.reviewRequired !== undefined) {
        setNewCannabinoid(currentFilter?.reviewRequired ? 1 : 0);
      }
    }
  }, [currentFilter, shouldPrePopulate]);

  const search = useCallback(() => {
    if (setCurrentPage) setCurrentPage(1);
    const queryObject = {
      search_key,
      brand,
      product_type,
      productSpecific,
      states,
      last_updated_by: admin,
      store_url,
      ...([0, 1].includes(newCannabinoid) && {
        reviewRequired: !!newCannabinoid,
      }),
    };
    if (Object.keys(queryObject).length) {
      onFilter(queryObject);
    } else if (Object.keys(currentFilter || {}).length) {
      onFilter(emptyFilter);
    }
    doAction(previousSearchAction, {
      search_key,
      brand,
      product_type,
      productSpecific,
      states,
      store_url,
      admin,
      newCannabinoid,
    });
  }, [
    search_key,
    brand,
    brandName,
    product_type,
    productSpecific,
    states,
    store_url,
    admin,
    newCannabinoid,
    onFilter,
    currentFilter,
    setCurrentPage,
  ]);

  const getSpecificOptions = () => {
    if (!product_type) {
      return [];
    }
    const type = ProductTypes.find(({ key }) => key === product_type);
    if (!type) {
      return [];
    }
    Object.values(type.specific).forEach((val, index) => {
      val.key = Object.keys(type.specific)[index];
    });

    return Object.values(type.specific);
  };

  return (
    <div className="filter-root">
      <div className="product-filter">
        <div className="filter-item">
          <Input.Search
            placeholder="Search"
            onChange={(e) => setSearch_key(e.target.value)}
            value={search_key}
            allowClear={true}
            onPressEnter={() => search()}
          />
        </div>

        {!approvedMode && (
          <Button type="link" onClick={() => setShowAdvanced(!showAdvanced)}>
            Advanced search
            <img
              src={downArrow}
              className="search-bar-arrow-icon"
              alt="search-bar"
              style={showAdvanced ? { transform: 'rotate(180deg)' } : {}}
            />
          </Button>
        )}

        {!!Object.keys(currentFilter || {}).length && !approvedMode && (
          <Select
            onSelect={(e) => activateBulkActions(e)}
            className="filter-search-bulk-actions"
            placeholder="Bulk Actions"
            value=""
          >
            <Select.Option style={{ display: 'none' }} value="" disabled>
              Bulk Actions
            </Select.Option>
            {collection === 'approved' && (
              <Select.Option value="deactivate">Discontinue</Select.Option>
            )}
            {collection === 'discontinued' && (
              <Select.Option value="activate">Activate</Select.Option>
            )}
            {collection === 'pending' && (
              <>
                <Select.Option value="approve">Approve</Select.Option>
                <Select.Option value="reject">Homegrown</Select.Option>
              </>
            )}
            <Select.Option value="edit">Edit</Select.Option>
          </Select>
        )}

        <Button
          className="searchButton"
          onClick={() => search()}
          type="primary"
          disabled={searchDisabled}
        >
          Search
        </Button>

        <Button className="searchButton" onClick={() => resetFilter()} type="primary">
          Reset
        </Button>
        {children}
      </div>

      {showAdvanced ? (
        <Row justify="start">
          <Col {...columnsDimensions[approvedMode ? 'true' : 'false']}>
            <BrandSelect
              onSelect={(option) => {
                setBrand(option.value);
                setBrandName(option.label);
              }}
              defaultBrand={defaultBrand}
            />
          </Col>

          <Col {...columnsDimensions[approvedMode ? 'true' : 'false']}>
            <Select
              showSearch
              style={{
                margin: 5,
                width: '95%',
              }}
              placeholder="Type of product"
              optionFilterProp="children"
              onChange={(e) => {
                setProductSpecific('');
                setProduct_type(e);
              }}
              value={product_type}
              filterOption={(input, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option style={{ display: 'none' }} key="all" value={''} disabled>
                Type of product
              </Select.Option>
              {ProductTypes.map(({ name, key }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col {...columnsDimensions[approvedMode ? 'true' : 'false']}>
            <Select
              showSearch
              style={{
                margin: 5,
                width: '95%',
              }}
              placeholder="Specific"
              optionFilterProp="children"
              onChange={(e) => setProductSpecific(e)}
              value={productSpecific}
              filterOption={(input, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={!getSpecificOptions().length}
            >
              {getSpecificOptions().length && (
                <Select.Option style={{ display: 'none' }} key="all" value={''} disabled>
                  Specific
                </Select.Option>
              )}
              {getSpecificOptions().map(({ name, key }) => (
                <Select.Option key={key} value={key}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={6} lg={4} xl={3} xxl={2.2}>
            <Select
              showSearch
              style={{
                margin: 5,
                width: '95%',
              }}
              placeholder="Legal States"
              optionFilterProp="children"
              onChange={(e) => setStates(e.join(','))}
              mode="multiple"
              value={states ? (states as any).split(',') : []}
              filterOption={(input, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option style={{ display: 'none' }} key="all" value="" disabled>
                Legal States
              </Select.Option>
              {States.map(({ name, abbreviation }) => (
                <Select.Option key={abbreviation} value={abbreviation}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col {...columnsDimensions[approvedMode ? 'true' : 'false']}>
            <Input
              placeholder="Store URL"
              onChange={(e) => setStore_url(e.target.value)}
              value={store_url}
              style={{
                margin: 5,
                width: '95%',
              }}
              allowClear={true}
              onPressEnter={() => search()}
            />
          </Col>

          {!approvedMode && (
            <>
              <Col {...columnsDimensions[approvedMode ? 'true' : 'false']}>
                <Form
                  form={form}
                  layout="inline"
                  initialValues={{
                    admin: currentFilter?.last_updated_by,
                  }}
                >
                  <Form.Item
                    name="admin"
                    style={{
                      margin: 5,
                      width: '95%',
                    }}
                  >
                    <Select
                      showSearch
                      placeholder="Admin"
                      optionFilterProp="children"
                      allowClear
                      loading={enableAdminSearch && adminsQuery.isLoading}
                      onChange={(e: string) => setAdmin(e)}
                      onFocus={() => {
                        setEnableAdminSearch(true);
                      }}
                    >
                      {!adminsQuery.isLoading &&
                        adminsQuery.data &&
                        adminsQuery.data.length &&
                        adminsQuery.data.map((user) => (
                          <Select.Option key={user._id} value={user._id}>
                            {user.user}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <div
                  className="filter-item"
                  style={{
                    height: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '90%',
                    margin: 5,
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <Checkbox
                      data-testid="newCannabinoidsCheckbox"
                      indeterminate={newCannabinoid === 2}
                      onChange={(e: CheckboxChangeEvent) => {
                        switch (newCannabinoid) {
                          case 0:
                            setNewCannabinoid(2);
                            break;
                          case 1:
                            setNewCannabinoid(0);
                            break;
                          case 2:
                            setNewCannabinoid(1);
                            break;

                          default:
                            setNewCannabinoid(0);
                            break;
                        }
                      }}
                      checked={newCannabinoid === 1}
                    >
                      New cannabinoid
                    </Checkbox>
                  </div>

                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '-5px',
                    }}
                  >
                    (
                    {newCannabinoid === 2
                      ? 'ALL'
                      : newCannabinoid === 1
                      ? 'TRUE'
                      : newCannabinoid === 0
                      ? 'FALSE'
                      : ''}
                    )
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      ) : null}
    </div>
  );
}

export default ProductsFilter;
