import { encodeQuery } from '../../../utils/utils';
import { CurrentFilter } from '../../../views/orders/OrderFilter';
import axios from '../../apiClient';

export const getOrders = async ({
  limit = 5,
  skip = 0,
  currentFilter,
}: {
  limit?: number;
  skip?: number;
  currentFilter?: CurrentFilter;
}): Promise<{ list: object[]; total: number }> => {
  const url = encodeQuery('/org/v1/orders', {
    limit,
    skip,
    purchaseStatus: ['paid', 'voided', 'refunded', 'pending'],
    ...(currentFilter?.orderNumber ? { orderNumber: currentFilter?.orderNumber } : {}),
    ...(currentFilter?.customerEmail ? { customerEmail: currentFilter?.customerEmail } : {}),
  });
  const { data } = await axios.get(url);
  return data;
};
