import { statusIconSuccess } from '../utils/enum';
import { isJsonString, Net } from '../utils/utils';
import { message } from 'antd';
import { ModuleStore, ModuleAction, KV, doAction } from 'module-reaction';

const MODULE_PRODUCT = 'MODULE_ORGANIZATION';
const MODULE_PAGE_LOADING = 'MODULE_PAGE_LOADING';

interface ModelPageLoading extends ModuleStore {
  pageLoading: boolean;
  filterLoading: boolean;
}

export const model_product = {
  module: MODULE_PRODUCT,
  list: [],
  total: 0,
  pageSize: 5,
  pageIdx: 1,
  lastUploadCsvStatus: {
    status: 'success',
    icon: statusIconSuccess,
  },
  loading: true,
  hasMore: true,
};

export const model_page_loading: ModelPageLoading = {
  module: MODULE_PAGE_LOADING,
  pageLoading: false,
  filterLoading: false,
};

export const FetchOrganizationAction: ModuleAction<KV, object> = {
  module: MODULE_PRODUCT,
  name: 'FetchOrganizationAction',
  maxProcessSeconds: 30,
  process: async (payload: KV) => {
    const { search_key, pageSize, pageIdx, store_url, states } = Object.assign({}, payload);
    doAction(PageLoadingAction, {
      pageLoading: true,
    });
    const res = await Net.req(
      '/org/v1/organization/',
      {
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        searchKey: search_key && search_key?.length > 0 ? search_key.trim() : '',
        store_url: store_url && store_url?.length > 0 ? store_url.trim() : '',
        states: states && states?.length > 0 ? states : '',
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: res.list.map((item: object & { covers: string }) => ({
          ...item,
          covers: isJsonString(item.covers) ? JSON.parse(item.covers) : item.covers,
        })),
        total: res.total,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

const PageLoadingAction: ModuleAction<KV, ModelPageLoading> = {
  module: MODULE_PAGE_LOADING,
  name: 'PageLoadingAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelPageLoading) => {
    const { pageLoading, filterLoading } = Object.assign({}, model, payload);
    return {
      pageLoading,
      filterLoading,
    };
  },
};

type Permission = {
  permission: string;
  user: string;
  userName: string;
};

export interface Organization {
  id: string;
  subscription?: string;
  name: string;
  phone_number?: string;
  isOnlineStore?: boolean;
  address?: string;
  organization_website?: string;
  city?: string;
  state?: string;
  zip?: string;
  email?: string;
  pos?: string;
  number_of_retail_locations?: 0;
  legal_states?: string[];
  owner?: string;
  permissions?: Permission[];
  pendingInvites?: string[];
}
