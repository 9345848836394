import TableWrappre from './product_table/table-wrapper';
import { Menu } from 'antd';
import React from 'react';
import './product-routing.less';
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';

export const ProductRouting: React.FC = () => {
  const location = useLocation();

  return (
    <div className="list-view product-list-view">
      <div className="product-list-header">Products</div>
      <Menu mode="horizontal" className="products-nav-panel" selectedKeys={[location.pathname]}>
        <Menu.Item key="/productlist/approved">
          <NavLink to="/productlist/approved">Approved</NavLink>
        </Menu.Item>
        <Menu.Item key="/productlist/pending">
          <NavLink to="/productlist/pending">Pending</NavLink>
        </Menu.Item>
        <Menu.Item key="/productlist/discontinued">
          <NavLink to="/productlist/discontinued">Discontinued</NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/productlist/approved" component={TableWrappre} />
        <Route path="/productlist/pending" component={TableWrappre} />
        <Route path="/productlist/discontinued" component={TableWrappre} />
        <Redirect to="/productlist/approved" />
      </Switch>
    </div>
  );
};
