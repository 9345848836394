export const DAY_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export type DayOfWeek = typeof DAY_OF_WEEK[number];
export type IHourRange = { start?: string; end?: string };

/**
 * @example some data
 * const initialData: IAvailableHours = {
 *  monday: [
 *    { start: '08:00', end: '12:00' },
 *    { start: '13:00', end: '17:00' },
 *  ],
 *  tuesday: [
 *    { start: '08:00', end: '12:00' },
 *    { start: '13:00', end: '17:00' },
 *  ],
 *  wednesday: [
 *    { start: '08:00', end: '12:00' },
 *    { start: '13:00', end: '17:00' },
 *  ],
 *  thursday: [
 *    { start: '08:00', end: '12:00' },
 *    { start: '13:00', end: '17:00' },
 *  ],
 *  friday: [
 *    { start: '08:00', end: '12:00' },
 *    { start: '13:00', end: '17:00' },
 *  ],
 *  saturday: [
 *    { start: '08:00', end: '12:00' },
 *  ],
 *    sunday: [],
 *  };
 */
export type IAvailableHours = Record<DayOfWeek, IHourRange[]>;
