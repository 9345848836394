import logo from '../../assets/logo_sm.svg';
import { MODULE_USER } from '../../models/model_user';
import { routes } from '../../utils/enum';
import { Net } from '../../utils/utils';
import { Layout, Col, Form, Input, Button, message, Modal } from 'antd';
import { doFunction, doAction } from 'module-reaction';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './user_signin.less';
const { Header, Content } = Layout;

type UserSigninProp = RouteComponentProps;
export class UserSigninView extends React.Component<UserSigninProp> {
  state = { password: '', email: '', showForgotPasswordModal: false };

  handleConfirmForgotPassword = () => {
    if (!this.state.email) {
      message.error('please fill email');
      return;
    }
    // check for a valid email
    if (
      !this.state.email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      message.error('please provide a valid email');
      return;
    }
    this.setState({ showForgotPasswordModal: true });
  };

  handleForgotPassword = async () => {
    const res = await Net.req('/user/forgot-password', { email: this.state.email }, 'get');
    if (res && res.message === 'success') {
      message.success('Please check your email to reset your password');
    } else {
      message.error('Something went wrong, please try again later');
    }
    this.setState({ showForgotPasswordModal: false });
  };

  render() {
    return (
      <Layout className="signin-view">
        <Header>
          <img src={logo} alt="Jointly" />
        </Header>
        <Content>
          <Col lg={8} md={10} sm={20} xs={22}>
            {
              <div className="content-box">
                <div className="title">JOINTLY ADMIN LOGIN</div>
                <Col className="form-box" lg={{ span: 22, offset: 1 }} sm={{ span: 24 }}>
                  <Form className="password-box" onFinish={this.onSubmit} layout="vertical">
                    <Form.Item label="Email">
                      <Input type="email" onChange={(e) => this.recEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Password">
                      <Input type="password" onChange={(e) => this.recPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
                      <Button size="small" onClick={this.handleConfirmForgotPassword}>
                        Forgot password
                      </Button>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 7 }}>
                      <Button type="primary" className="submit-btn" htmlType="submit">
                        SIGN IN
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </div>
            }
          </Col>
        </Content>
        <Modal
          open={this.state.showForgotPasswordModal}
          title={'Forgot Password'}
          onOk={this.handleForgotPassword}
          onCancel={() => this.setState({ showForgotPasswordModal: false })}
        >
          <p>You will receive instructions to reset your password for the following email:</p>
          <p style={{ textAlign: 'center' }}>{this.state.email}</p>
        </Modal>
      </Layout>
    );
  }

  private recPassword(password: string) {
    this.setState({ password });
  }
  private recEmail(email: string) {
    this.setState({ email });
  }

  private onSubmit = async () => {
    const { password, email } = this.state;
    if (!password) {
      message.error('please fill password');
      return;
    }
    if (!email) {
      message.error('please fill email');
      return;
    }
    // check for a valid email
    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      message.error('please provide a valid email');
      return;
    }

    const res = await Net.req('/user/admin-sign-in', { email, password }, 'post');
    if (res && res.jwt_token) {
      // after log in, goto default page
      doAction(MODULE_USER, { logined: true });
      localStorage.setItem('userDetails', JSON.stringify(res.user));

      doFunction(async () => {
        if (res.user.role.includes('admin_user')) {
          this.props.history.replace(routes.productList);
        } else {
          this.props.history.replace(routes.retailLocationList);
        }
      });
    }
  };
}
