import axios from '../../apiClient';
import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export type EcommerceClient = {
  _id?: string;
  host: string;
  retailLocationId: string;
  retailLocation: {
    _id: string;
    name: string;
  };
  aiTitle: string;
  welcomeText: string;
  pathToProductId: string;
  prompts: string[];
  type: 'dutchiePlus' | 'tymber' | 'dutchieEmbedded' | 'jane' | 'testing';
  connectionInfo: Record<string, string | number>;
  pathToContactUs?: string;
  createdAt: Date;
  updatedAt: Date;
  pathSettings?: {
    path: string;
    zIndex: number;
    closedBottomPosition: number;
    tabBottomPosition: number;
    primaryColor: string;
    sparkIconColor: string;
    tabHorizontalPosition?: 'left' | 'right';
  }[];
};

export const createEcommerceClient = (
  data: EcommerceClient
): Promise<AxiosResponse<EcommerceClient>> => axios.post('/ecommerceClient', data);

export const updateEcommerceClient = (
  data: EcommerceClient
): Promise<AxiosResponse<EcommerceClient>> => axios.put(`/ecommerceClient/${data._id}`, data);

export const deleteEcommerceClient = (id: string): Promise<AxiosResponse<EcommerceClient>> =>
  axios.delete(`/ecommerceClient/${id}`);

export const getEcommerceClients = async ({
  queryKey,
}: QueryFunctionContext<[string, { skip: number; limit: number }]>): Promise<{
  results: EcommerceClient[];
  total: number;
}> => {
  const [, { skip, limit }] = queryKey;
  const { data } = await axios.get(`/ecommerceClient?skip=${skip}&limit=${limit}`);
  return data;
};
