import { Typography, Input, Form, InputProps } from 'antd';
import React from 'react';
import './text-input-component.less';

interface TextInputProps extends Omit<InputProps, 'size'> {
  placeholder?: string;
  title?: string;
  name?: string;
  required?: boolean;
  size?: string;
}

const TextInputComponent: React.FC<TextInputProps> = ({
  placeholder,
  title,
  size,
  name,
  required,
  ...props
}: TextInputProps) => (
  <div className="textfield-container-1" style={{ width: size }}>
    <Typography.Text>{title}</Typography.Text>
    <Form.Item name={name} rules={[{ required: required }]} valuePropName="value">
      <Input placeholder={placeholder} {...props} />
    </Form.Item>
  </div>
);

export default TextInputComponent;
