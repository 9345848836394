import { statusIconSuccess } from '../utils/enum';
import { isJsonString, Net } from '../utils/utils';
import { message } from 'antd';
import { ModuleStore, ModuleAction, KV, doAction } from 'module-reaction';
import { RouteComponentProps } from 'react-router';

export const MODULE_PRODUCT = 'MODULE_PRODUCT';
const MODULE_PAGE_LOADING = 'MODULE_PAGE_LOADING';
const MODULE_PREVIOUS_SEARCH = 'MODULE_PREVIOUS_SEARCH';

interface Cannabinoid {
  THC?: number; // unit: mg
  CBD?: number; // unit: same as above
  THCa?: number;
  THCv?: number;
  CBDa?: number;
  CBDv?: number;
  CBN?: number;
  CBG?: number;
  CBC?: number;
  CBCv?: number;
  Delta8?: number;
}

interface DoseObject {
  value: number;
  unit: string;
}

export interface Product extends Cannabinoid {
  id: string;
  inner_id: string;
  covers: Doc[];
  name: string;
  introduction: string;
  brand: string;
  brand_name: string;
  flavor: string;
  product_type: {
    type: string;
    specific: string;
    serving_size: {
      name: string;
      servingType: string; // Puff, Patches, mg ...
    };
    search_key: string; // keep this filed to help filter by regex
  };
  cannabinoid: string[];
  // strain type There are 3 types of strain: Indica & Sativa & hybrid
  strain_type: string;
  strain: string;
  // TERPENES
  terpenes: string[];
  ex_store_url: string; // when have no stores in our db, client can buy product via this url
  legal_states: string[]; // States of America which can sell cannabis
  status: string; // where this product is active or discontinued
  note: string;
  last_updated_by: string; // user who last saved
  updated_at: Date;
  reviewRequired: boolean;
}

export interface ProductFilter {
  search_key?: string;
  brand?: string;
  product_type?: string;
  productSpecific?: string;
  states?: string;
  store_url?: string;
  brand_name?: string;
  last_updated_by?: string;
  reviewRequired?: boolean;
}

export interface ProductListProp extends ModelProduct, RouteComponentProps {
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 1, when fetch, skip=pageSize * pageIdx
}

export interface ModelProduct extends ModuleStore {
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  lastUploadCsvStatus: {
    status: string; // processing/success/error:msg
    icon: React.ReactNode;
  };
  hasMore: boolean;
}

interface ModelPageLoading extends ModuleStore {
  pageLoading: boolean;
  filterLoading: boolean;
}

interface ModelPreviousSearch extends ModuleStore {
  search_key: string;
  brand: string;
  product_type: string;
  productSpecific: string;
  states?: string;
  store_url: string;
  last_updated_by: string;
  reviewRequired: boolean | null;
}

export const model_product: ModelProduct = {
  module: MODULE_PRODUCT,
  list: [],
  total: 0,
  pageSize: 20,
  pageIdx: 1,
  lastUploadCsvStatus: {
    status: 'success',
    icon: statusIconSuccess,
  },
  loading: true,
  hasMore: true,
};

export const model_page_loading: ModelPageLoading = {
  module: MODULE_PAGE_LOADING,
  pageLoading: false,
  filterLoading: false,
};

export const previous_search: ModelPreviousSearch = {
  module: MODULE_PREVIOUS_SEARCH,
  search_key: '',
  brand: '',
  product_type: '',
  productSpecific: '',
  states: '',
  store_url: '',
  last_updated_by: '',
  reviewRequired: null,
};

export const previousSearchAction: ModuleAction<KV, ModelPreviousSearch> = {
  module: MODULE_PREVIOUS_SEARCH,
  name: 'previousSearchAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelPreviousSearch) => {
    const {
      search_key,
      brand,
      product_type,
      productSpecific,
      states,
      store_url,
      admin,
      newCannabinoid,
    } = Object.assign({}, payload);

    return {
      search_key,
      brand,
      product_type,
      productSpecific,
      store_url,
      states,
      last_updated_by: admin,
      reviewRequired: newCannabinoid,
    };
  },
};

export const FetchProductsAction: ModuleAction<KV, ModelProduct> = {
  module: MODULE_PRODUCT,
  name: 'FetchProductsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelProduct) => {
    const {
      search_key,
      brand_name,
      brand,
      product_type,
      productSpecific,
      states,
      store_url,
      pageSize,
      pageIdx,
      sort_by,
      order,
      status,
      last_updated_by,
      reviewRequired,
    } = Object.assign({}, payload);
    doAction(PageLoadingAction, {
      pageLoading: true,
    });
    const res = await Net.req(
      '/product/paginated-search',
      {
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        ...(!!sort_by && { sort_by }),
        ...(!!order && { order }),
        query: JSON.stringify({
          status: status === 'approved' ? 'active' : status,
          ...(!!search_key && { search_key }),
          ...(!!product_type && { type: product_type }),
          ...(!!productSpecific && { specific: productSpecific }),
          ...(!!brand_name && { brand_name }),
          ...(!!last_updated_by && { last_updated_by }),
          ...(!!brand && { brand }),
          ...(!!states && { states }),
          ...(!!store_url && { store_url }),
          ...(reviewRequired !== undefined && {
            reviewRequired: !!reviewRequired,
          }),
        }),
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: res.list.map((item: any) => ({
          ...item,
          covers: isJsonString(item.covers) ? JSON.parse(item.covers) : item.covers,
        })),
        total: res.total,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

const PageLoadingAction: ModuleAction<KV, ModelPageLoading> = {
  module: MODULE_PAGE_LOADING,
  name: 'PageLoadingAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelPageLoading) => {
    const { pageLoading, filterLoading } = Object.assign({}, model, payload);
    return {
      pageLoading,
      filterLoading,
    };
  },
};
