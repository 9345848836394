import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import React from 'react';

export const routes = {
  signIn: '/sign-in',
  productList: '/productlist',
  productDetail: '/product/:id',
  brandList: '/brandlist',
  brandDetail: '/brand/:id',
  userList: '/users',
  userDetail: '/user/:id',
  organizationList: '/organizationlist',
  organizationDetail: '/organization/:id',
  retailLocationList: '/retailLocationList',
  retailLocationDetail: '/retailLocation/:id',
  retailUserList: '/retailUsers',
  retailProductList: '/retailProductList',
  orderList: '/orders',
  settings: '/settings',
  ecommerceClient: '/ecommerceClient',
  ecommerceClientList: '/ecommerceClientList',
  ecommerceClientDetail: '/ecommerceClientDetail/:id',
};

export const routerSignIn = '/sign-in';
export const routerProductList = '/productlist';
export const routerRetailProductList = '/retailProductList';
export const routerProductDetail = '/product/:id';
export const routerOrder = '/orders';
export const routerBrandList = '/brandlist';
export const routerBrandDetail = '/brand/:id';
export const routerUserList = '/users';
export const routerOrganizationList = '/organizationlist';
export const routerOrganizationDetail = '/organization/:id';
export const routerRetailLocationList = '/retailLocationList';
export const routerRetailUsersList = '/retailUsers';
export const routerRetailLocationDetail = '/retailLocation/:id';
export const routerUserDetail = '/user/:id';
export const routerSettings = '/settings';

export const statusIconSuccess = (
  <CheckCircleOutlined
    className="upcsv-status"
    style={{ color: '#66CC00', width: '20px', height: '20px' }}
  />
);
export const statusIconProcessing = (
  <SyncOutlined
    className="upcsv-status"
    spin
    style={{ color: '#FF9900', width: '20px', height: '20px' }}
  />
);
export const statusIconFail = (
  <CloseCircleOutlined
    className="upcsv-status"
    style={{ color: '#990000', width: '20px', height: '20px' }}
  />
);

export enum RetailProductStatusEnum {
  Mapped = 'mapped',
  PreMapped = 'preMapped',
  NotMapped = 'notMapped',
  NotSupported = 'notSupported',
}
