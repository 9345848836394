import { CurrentSort } from './RetailProductFilter';
import ColumnsConfig from '../../../components/table/column-config';
import { Cannabinoid } from '../../../models/model_brand';
import ProductEditForm from '../../product/product-edit-form/product-edit-form';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect } from 'react';
import '../../product/product_table/components/product-table.less';
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';

type Props = {
  data: any[];
  columns: any[];
  selectedProduct: any;
  setIsColumnConfigModalShown: (arg: boolean) => void;
  isColumnConfigModalShown: boolean;
  cannabinoidsList: Cannabinoid[];
  isAddProductModalShown: boolean;
  onSuccess: (id?: string) => void;
  hideAddProductModal: () => void;
  setCurrentSort: React.Dispatch<React.SetStateAction<CurrentSort>>;
};

const Table = ({
  data,
  columns,
  setIsColumnConfigModalShown,
  isColumnConfigModalShown,
  selectedProduct,
  isAddProductModalShown,
  onSuccess,
  hideAddProductModal,
  cannabinoidsList,
  setCurrentSort,
}: Props) => {
  const defaultColumn = {
    minWidth: 30,
    width: 100,
    maxWidth: 400,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    visibleColumns,
    setHiddenColumns,
    allColumns,
    state,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      defaultColumn,
      manualSortBy: true,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: '10px',
        }}
      >
        <code>{JSON.stringify({ values: row.values }, null, 2)}</code>
      </pre>
    ),
    []
  );

  useEffect(() => {
    if (state.sortBy.length > 0) {
      setCurrentSort({
        sortBy: `${state.sortBy[0].id}=${state.sortBy[0].desc ? '-1' : '1'}`,
      });
    } else {
      setCurrentSort({
        sortBy: '',
      });
    }
  }, [state.sortBy, setCurrentSort]);

  return (
    <>
      <table {...getTableProps()}>
        <thead className="sticky">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th
                  className="colunmHeader"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render('Header')}

                  {!['selection', 'action'].includes(column.id) && (
                    <>
                      {column.canSort && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <CaretDownOutlined
                                className="sortIndicator"
                                style={{
                                  color: '#d558ff',
                                }}
                              />
                            ) : (
                              <CaretUpOutlined
                                className="sortIndicator"
                                style={{
                                  color: '#d558ff',
                                }}
                              />
                            )
                          ) : (
                            <CaretDownOutlined
                              className="sortIndicator"
                              style={{
                                color: '#b5b5b5',
                              }}
                            />
                          )}
                        </span>
                      )}

                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                      />
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, index) => {
            prepareRow(row);
            return (
              <React.Fragment key={index}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any, index: number) => (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                )}
                {row.original?.id === selectedProduct.id && isAddProductModalShown && (
                  <tr>
                    <td className="editModalWrapper" colSpan={visibleColumns.length}>
                      <ProductEditForm
                        visible={isAddProductModalShown}
                        selectedProduct={{
                          name: selectedProduct.sparkRefinedFields?.name || selectedProduct.name,
                          brand: selectedProduct.sparkRefinedFields?.brandMatchId || '',
                          brand_name: selectedProduct.mainPossibleJointlyMappedId?.brand_name || '',
                          product_type: {
                            type: selectedProduct.sparkRefinedFields?.type?.name || '',
                            specific: selectedProduct.sparkRefinedFields?.type?.specific || '',
                          },
                          strain: selectedProduct.sparkRefinedFields?.strain?.name || '',
                          strain_type: selectedProduct.sparkRefinedFields?.strain?.type || '',
                          flavor: selectedProduct.sparkRefinedFields?.flavor?.[0] || '',
                          covers: [{ link: selectedProduct.imageUrls[0] }],
                          retailProductCannabinoids: selectedProduct.cannabinoids,
                          retailProductTerpenes: selectedProduct.terpenes,
                        }}
                        action={'new'}
                        hide={() => hideAddProductModal()}
                        onSuccess={onSuccess}
                        cannabinoidsList={cannabinoidsList}
                        avoidPendingCreation={false}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {/* Column configuration */}
      <ColumnsConfig
        visible={isColumnConfigModalShown}
        onCancel={() => setIsColumnConfigModalShown(false)}
        colums={allColumns}
        setHiddenColumns={setHiddenColumns}
        configName="retailProducts"
      />
    </>
  );
};

export default Table;
