import logo from '../../assets/logo_sm.svg';
import { MODULE_USER } from '../../models/model_user';
import { routes } from '../../utils/enum';
import { Net, setToken } from '../../utils/utils';
import { Layout, Col, Form, Input, Button, message } from 'antd';
import jws from 'jws';
import { doAction, doFunction } from 'module-reaction';
import React, { useCallback, useLayoutEffect } from 'react';
import './user_signup.less';
import { RouteComponentProps } from 'react-router-dom';

const { Header, Content } = Layout;

type Params = {
  uid: string;
};

type JWT = {
  id: string;
  email: string;
  exp: number;
  iat: number;
};

type UserSignupProp = RouteComponentProps<Params>;

export const UserSignupView: React.FC<UserSignupProp> = (props) => {
  const userJwt = props.match.params.uid;

  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [accessKey, setAccessKey] = React.useState('');

  const verifyToken = useCallback(async () => {
    const decoded = jws.decode(userJwt).payload as JWT;
    setEmail(decoded.email);
    setToken(userJwt);
  }, [userJwt]);

  useLayoutEffect(() => void verifyToken(), [verifyToken]);

  const onSubmit = async () => {
    if (!email) {
      message.error('please fill email');
      return;
    }
    if (!password) {
      message.error('please fill password');
      return;
    }
    if (password !== password2) {
      message.error('please fill same password twice');
      return;
    }
    if (!accessKey && !userJwt) {
      message.error('please fill access_key');
      return;
    }
    if (userJwt) {
      const res = await Net.req('/user/retail-signup', { username, password }, 'post');
      if (res && res.jwt_token) {
        // TODO after log in, go to success page. in the future I suppose we can follow the default flow and log in the user
        message.success('User created successfully');
        doFunction(async () => props.history.replace('/sign-up/success'));
      }
      return;
    }
    const res = await Net.req(
      '/user/admin-sign-up',
      { username, email, password, access_key: accessKey },
      'post'
    );
    if (res && res.jwt_token) {
      // after log in, goto default page
      doAction(MODULE_USER, { logined: true });
      doFunction(async () => props.history.replace(routes.productList));
    }
  };

  return (
    <Layout className="signup-view">
      <Header>
        <img src={logo} alt="Jointly" />
      </Header>
      <Content>
        <Col lg={8} md={10} sm={20} xs={22}>
          {
            <div className="content-box">
              <div className="title">JOINTLY {userJwt ? 'USER' : 'ADMIN'} SIGNUP</div>
              <Col className="form-box" lg={{ span: 22, offset: 1 }} sm={{ span: 24 }}>
                <Form className="password-box" onFinish={onSubmit} layout="vertical">
                  <Form.Item label="Username">
                    <Input type="text" onChange={(e) => setUsername(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Email">
                    <Input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      disabled={!!userJwt}
                    />
                  </Form.Item>
                  <Form.Item label="Password">
                    <Input type="password" onChange={(e) => setPassword(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Confirm Password">
                    <Input type="password" onChange={(e) => setPassword2(e.target.value)} />
                  </Form.Item>
                  {!userJwt && (
                    <Form.Item label="AccessKey">
                      <Input type="password" onChange={(e) => setAccessKey(e.target.value)} />
                    </Form.Item>
                  )}
                  <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" className="submit-btn" htmlType="submit">
                      SIGN UP
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </div>
          }
        </Col>
      </Content>
    </Layout>
  );
};
