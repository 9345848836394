import { addSetting } from '../../services/api/settings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, notification } from 'antd';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';

type Props = {
  setting: string;
  onSuccess: () => void;
};

const NewSetting = ({ setting, onSuccess }: Props) => {
  const [newSettingBody, setNewSettingBody] = useState<any>({});
  const [original, setOriginal] = useState<any>({
    value: {},
  });

  const queryClient = useQueryClient();

  const addSettingMutation = useMutation({
    mutationFn: addSetting,
    onSuccess: () => {
      notification.success({
        message: 'Updated successfully',
      });
      queryClient.invalidateQueries({ queryKey: ['getSettings'] });
      onSuccess();
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  return (
    <div>
      <ReactJson
        collapsed={false}
        name={setting}
        displayDataTypes={false}
        src={original}
        quotesOnKeys={false}
        onEdit={({ updated_src }) => {
          setNewSettingBody(updated_src);
        }}
        onAdd={() => true}
        onDelete={({ updated_src }) => {
          setNewSettingBody(updated_src);
        }}
      />
      <br />
      <Button
        type="primary"
        loading={addSettingMutation.isLoading}
        onClick={() => {
          if (!Object.keys(newSettingBody).length) {
            notification.warning({
              message: 'Incomplete request',
              description: "please add something to the 'value' field",
            });
            return;
          }
          addSettingMutation.mutate({
            setting,
            payload: newSettingBody.value,
          });
        }}
      >
        Add
      </Button>
    </div>
  );
};

export default NewSetting;
