import logo from '../../assets/logo_sm.svg';
import { Layout, Col, Result } from 'antd';
import React from 'react';
import './user_signup.less';

const { Header, Content } = Layout;

const Success = () => (
  <Layout className="signup-view">
    <Header>
      <img src={logo} alt="Jointly" />
    </Header>
    <Content>
      <Col lg={8} md={10} sm={20} xs={22}>
        <Result
          status="success"
          title="Success!"
          subTitle="Your account has been created successfully."
        />
      </Col>
    </Content>
  </Layout>
);

export default Success;
