import {
  ModelPendingBrand,
  PendingBrand,
  FetchPendingBrandsAction,
  EmptyPendingBrandsAction,
  FetchMorePendingBrandsAction,
  model_pending_brand,
  Brand,
} from '../../../models/model_brand';
import { model_page_loading, PageLoadingAction } from '../../../models/model_product';
import { verifyBrandLockAPI } from '../../../services/api/verifyLockAPI/verifyBrandLock';
import { Net } from '../../../utils/utils';
import BrandEditModal from '../brand-edit-modal/brand-edit-modal';
import { BrandModal } from '../brand_modal';
import BulkEditBrands from '../bulk_edit_brand/bulk_edit_brand';
import { PendingBrandFilter } from '../pending_brand_filter/pending_brand_filter';
import { List, Button, Modal, notification, Checkbox, Dropdown, Menu, Spin } from 'antd';
import { mapProp, doAction } from 'module-reaction';
import React, { MouseEvent } from 'react';
import './pending_brand_list.less';
import { RouteComponentProps } from 'react-router-dom';
import downArrow from '../../../assets/down_arrow.svg';

interface BrandListProp extends ModelPendingBrand, RouteComponentProps {
  list: PendingBrand[]; // current result for show
  total: number; // total mat
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 1, when fetch, skip=pageSize * pageIdx
  pageLoading: boolean; //show loading until next page is loaded
}

@mapProp(model_page_loading, 'pageLoading', 'filterLoading')
@mapProp(model_pending_brand)
export class PendingBrandListView extends React.Component<BrandListProp, {}> {
  state = {
    selectedBrand: {} as Brand,
    showRejectModal: false,
    rejectModalLoading: false,
    showApproveModal: false,
    selectedBrands: [],
    activeSort: '',
    sortActive: false,
    sortedProductList: [],
    isBrandModalShown: false,
    isBulkEditModalShown: false,
    lockLoadingItem: null,
    filter: {
      sort_by: '',
      order: '',
    },
  };

  sortItems = (sortItem: string) => {
    const { filter } = this.state;
    doAction(PageLoadingAction, { filterLoading: true });
    if (filter.sort_by === '' || (filter.sort_by !== sortItem && filter.order !== 'asc')) {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'desc' },
        },
        () =>
          doAction(FetchPendingBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'desc',
          })
      );
    } else if (filter.order === 'asc') {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'desc' },
        },
        () =>
          doAction(FetchPendingBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'desc',
          })
      );
    } else {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'asc' },
        },
        () =>
          doAction(FetchPendingBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'asc',
          })
      );
    }
  };

  checkProduct = (itemId: string) => {
    const data: string[] = [...this.state.selectedBrands];
    const itemIndex = data.findIndex((item: string) => item === itemId);
    if (itemIndex === -1) {
      data.push(itemId);
    } else {
      data.splice(itemIndex, 1);
    }
    this.setState({
      selectedBrands: [...data],
    });
  };

  checkAllProducts = () => {
    let data: string[] = this.state.selectedBrands;
    if (data.length !== this.props.list.length) {
      data = this.props.list.map((item) => item.id);
    } else {
      data = [];
    }
    this.setState({
      selectedBrands: [...data],
    });
  };

  private showEditBrandModal = (item: Brand) => (e: MouseEvent<HTMLInputElement>) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      lockLoadingItem: item.id,
    });
    verifyBrandLockAPI(item.id)
      .then((response: any) => {
        this.setState(
          {
            selectedBrand: {
              ...item,
              updatedAt: response.data.updatedAt,
            },
            lockLoadingItem: null,
          },
          this.showBrandModal
        );
      })
      .catch((error) => {
        this.setState({
          lockLoadingItem: null,
        });
        notification['error']({
          message: 'This product is being edited by another user',
        });
      });
  };

  private showBrandModal = () => {
    this.setState({
      isBrandModalShown: true,
    });
  };

  private hideEditBrandModal = () => {
    this.setState({
      isBrandModalShown: false,
      selectedBrand: {},
    });
  };

  private showBulkActions = (action: string) => {
    if (action === 'deactivate' && this.state.selectedBrands.length > 0) {
      this.showBulkDeactivateModal();
    } else if (action === 'edit' && this.state.selectedBrands.length > 0) {
      this.showBulkEditModal();
    } else {
      notification.warning({ message: 'Please select 1 or more items.' });
    }
  };

  private showBulkDeactivateModal = () => {
    this.setState({
      isBulkDeactivateModalShown: true,
    });
  };

  private showBulkEditModal = () => {
    this.setState({
      isBulkEditModalShown: true,
    });
  };

  renderDropdownOverlay = (item: PendingBrand) => (
    <Menu>
      <Menu.Item key="edit">
        <Button type="link" onClick={this.showEditBrandModal(item)}>
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="deactivate">
        <Button type="link" onClick={this.showApproveModal(item)}>
          Approve
        </Button>
      </Menu.Item>
      {/* <Menu.Item key="delete">
          <Button type="link" onClick={this.showRejectModal(item)}>
            Reject
          </Button>
        </Menu.Item> */}
    </Menu>
  );

  loadMoreBrands = (e: any) => {
    const { pageSize, pageIdx, hasMore, pageLoading } = this.props;
    const brandListCon = document.getElementById('brand-list-con')!;
    if (
      brandListCon?.scrollHeight - brandListCon?.scrollTop - brandListCon?.offsetHeight < 40 &&
      !pageLoading &&
      hasMore
    ) {
      this.onPageChange(pageIdx + 1, pageSize);
    }
  };

  render() {
    const { list, loading, hasMore, filterLoading } = this.props;
    const {
      selectedBrand,
      showRejectModal,
      rejectModalLoading,
      showApproveModal,
      selectedBrands,
      isBrandModalShown,
      isBulkEditModalShown,
      lockLoadingItem,
    } = this.state;

    const activeSort = this.state.filter.order === 'desc' ? this.state.filter.sort_by : '';

    if (loading) {
      return (
        <div className="pending-loading">
          <Spin size="large" />
        </div>
      );
    }

    return (
      <div className="list-view pending-brand-list-view">
        <PendingBrandFilter onSearch={this.onSearch} activateBulkActions={this.showBulkActions}>
          <div className="pending-brand-add-new">
            <Button onClick={this.addNew} type="primary" className="pending-add-brand-button">
              + ADD BRAND
            </Button>
          </div>
        </PendingBrandFilter>
        <div className="list-con" id="brand-list-con" onScroll={this.loadMoreBrands}>
          <List
            className="brand-list"
            dataSource={list}
            header={
              <>
                <span className="span-1">
                  <Checkbox
                    indeterminate={
                      selectedBrands.length > 0 && list.length !== selectedBrands.length
                    }
                    onChange={this.checkAllProducts}
                    checked={
                      this.props.list.length === selectedBrands.length &&
                      this.props.list.length !== 0
                    }
                  />
                </span>
                <span className="span-1">ACTION</span>
                <span className="span-2">IMAGE</span>
                <span className="span-2" onClick={() => this.sortItems('inner_id')}>
                  ID
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'inner_id'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2" onClick={() => this.sortItems('note')}>
                  NOTE
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'note'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2 ellipse" onClick={() => this.sortItems('name')}>
                  NAME
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'name'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2" onClick={() => this.sortItems('company')}>
                  COMPANY
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'company'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-3" onClick={() => this.sortItems('introduction')}>
                  DESCRIPTION
                  <img
                    src={downArrow}
                    className="header-sort-icon"
                    alt="sort-icon"
                    style={
                      activeSort === 'introduction'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-3 ellipse">IMAGE</span>
              </>
            }
            renderItem={(item: PendingBrand, index: number) => {
              if (filterLoading) {
                if (index === 0)
                  return (
                    <div className="pending-loading">
                      <Spin size="large" />
                    </div>
                  );
                return null;
              }
              return (
                <>
                  <List.Item key={`${item.inner_id}`}>
                    <span className="span-1">
                      <Checkbox
                        onChange={(_) => this.checkProduct(item.id)}
                        checked={selectedBrands.includes(item.id as never)}
                      />
                    </span>
                    <span className="span-1">
                      {lockLoadingItem === item.id ? (
                        <Button size="middle" disabled={true} loading={true}>
                          Select
                        </Button>
                      ) : (
                        <Dropdown
                          className="header-avatar-drop"
                          overlay={this.renderDropdownOverlay(item)}
                        >
                          <div className="header-logo-parent">Select</div>
                        </Dropdown>
                      )}
                    </span>
                    <span className="span-2">
                      <img
                        src={item.covers[0]?.link}
                        className="product-list-image"
                        alt="product-list"
                      />
                    </span>
                    <span className="span-2">#{item.inner_id}</span>
                    <span className="span-2">{item.note}</span>
                    <span className="span-2 ellipse">{item.name}</span>
                    <span className="span-2">{item.company}</span>
                    <span className="span-3">{item.introduction}</span>
                    <span className="span-3 ellipse">
                      <a href={item.covers[0].link} rel="noreferrer" target="_blank">
                        Link
                      </a>
                    </span>
                  </List.Item>
                  {isBrandModalShown && selectedBrand.id === item.id ? (
                    <List.Item className="edit-product-background">
                      <BrandEditModal
                        visible={isBrandModalShown}
                        selectedBrand={selectedBrand as Brand}
                        action="pending-edit"
                        hide={this.hideEditBrandModal}
                        onSuccess={this.handleSuccess}
                      />
                    </List.Item>
                  ) : null}
                </>
              );
            }}
          />
          {hasMore && (
            <div className="loadmore">
              <Spin size="large" />
            </div>
          )}
        </div>

        {isBulkEditModalShown && (
          <BulkEditBrands
            visible={isBulkEditModalShown}
            selectedBrands={selectedBrands as string[]}
            brandList={this.props.list as Brand[]}
            clearSelectionData={this.clearSelectionData as never}
            closeBulkEditModal={this.closeBulkEditModal as never}
            type="pending-edit"
          />
        )}

        <Modal
          visible={showRejectModal}
          title={'Reject Brand Request'}
          onOk={this.rejectBrand}
          onCancel={this.hideRejectModal}
          confirmLoading={rejectModalLoading}
        >
          <p>Are you sure you want to reject this request?</p>
        </Modal>

        <BrandModal
          visible={showApproveModal}
          action={
            (selectedBrand as Brand).inner_id
              ? (selectedBrand as Brand).edit
                ? 'pending-edit'
                : 'approve'
              : 'new'
          }
          selectedBrand={selectedBrand}
          hide={this.hideApproveModal}
          onSuccess={this.handleSuccess}
        />
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    if (!this.props.list.length || this.props.list?.length === 0) {
      doAction(FetchPendingBrandsAction);
    }
  }

  componentWillUnmount() {
    doAction(EmptyPendingBrandsAction);
  }

  private clearSelectionData = () => {
    this.setState({
      selectedBrands: [],
    });
    doAction(FetchPendingBrandsAction);
  };

  private closeBulkEditModal = () => {
    this.setState({
      isBulkEditModalShown: false,
    });
  };

  private onSearch = (searchKey: string) => {
    doAction(FetchPendingBrandsAction, { searchKey, pageIdx: 1 });
  };

  private onPageChange = (pageIdx?: number, pageSize?: number) => {
    doAction(PageLoadingAction, { pageLoading: true });
    const { sort_by, order } = this.state.filter;
    doAction(FetchMorePendingBrandsAction, {
      pageIdx,
      pageSize,
      sort_by,
      order,
    });
  };

  private addNew = async () => {
    this.showApproveModal({})();
  };

  // Approve form

  private showApproveModal = (item: Brand | {}) => () => {
    verifyBrandLockAPI((item as Brand).id)
      .then((response: any) => {
        this.setState({
          selectedBrand: {
            ...item,
            updatedAt: response.data.updatedAt,
          },
          showApproveModal: true,
        });
      })
      .catch((error) => {
        this.setState({
          showApproveModal: true,
          selectedBrand: item,
        });
      });
  };

  private hideApproveModal = () => {
    this.setState({
      showApproveModal: false,
      selectedBrand: {},
    });
  };

  private handleSuccess = () => {
    doAction(FetchPendingBrandsAction);
  };

  // end approve

  private hideRejectModal = () => {
    this.setState({
      showRejectModal: false,
      selectedBrand: {},
    });
  };

  private rejectBrand = async () => {
    const { id } = this.state.selectedBrand as Brand;

    this.setState({ rejectModalLoading: true });

    try {
      const res = await Net.req('/brand/pending/reject-brand', { id }, 'post');
      this.setState({
        rejectModalLoading: false,
        showRejectModal: false,
      });
      if (res?.status) {
        if (res.status === 'success') {
          doAction(FetchPendingBrandsAction);
          notification['success']({
            message: 'Rejected brand successfully!',
          });
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
    } catch (e) {
      this.setState({
        rejectModalLoading: false,
        showRejectModal: false,
      });
      notification['error']({
        message: 'Something went wrong!',
      });
    }
  };

  // End Reject Form
}
