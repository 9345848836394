import { FetchUsersAction, ModleUser, modleUser } from '../../../../models/model_user';
import { AutoComplete, AutoCompleteProps } from 'antd';
import { doAction, mapProp } from 'module-reaction';
import React, { useEffect } from 'react';

type UserAutocompleteProps = ModleUser &
  AutoCompleteProps<string, { value: string }> & {
    children?: React.ReactNode;
  };

const UserAutocomplete: React.FC<UserAutocompleteProps> = (props) => {
  useEffect(() => {
    doAction(FetchUsersAction, { search_key: '' });
  }, []);

  const handleSearch = (value: string) => {
    doAction(FetchUsersAction, { search_key: value, showNoResults: false });
  };

  return (
    <AutoComplete
      onSearch={handleSearch}
      options={props.list?.map((user) => ({ value: user.email || user.username || '' }))}
      filterOption={(inputValue, option) =>
        option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      {...props}
    />
  );
};

export default mapProp(modleUser)(UserAutocomplete);
