import FieldInfo from './FieldInfo';
import { RetailProductStatusEnum } from '../../../utils/enum';
import { getStatusColor } from '../../../utils/utils';
import { Image } from 'antd';
import React from 'react';
import ReactJson from 'react-json-view';

type Props = {
  currentStatus: RetailProductStatusEnum;
  name: string;
  brand: string;
  size: {
    amount: string;
    unit?: string;
  };
  metadata: any;
  imageUrls: string;
  reference?: any;
};

const RetailProductComponent = ({
  currentStatus,
  name,
  brand,
  size,
  metadata,
  imageUrls,
  reference,
}: Props) => (
  <div style={{ flex: 4 }} ref={reference}>
    <p
      style={{
        fontSize: 16,
        textAlign: 'right',
        marginBottom: 0,
        fontWeight: 600,
        textDecoration: 'underline',
      }}
    >
      Retail product
    </p>
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '300px',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Image height={300} src={imageUrls} style={{ objectFit: 'contain' }} />
      </div>
    </div>
    <div style={{ padding: 5 }}>
      <div
        style={{
          background: getStatusColor(currentStatus),
          inlineSize: 'max-content',
          padding: '5px',
          borderRadius: '5px',
          textTransform: 'uppercase',
          marginRight: 'auto',
          marginLeft: 'auto',
          height: 'fit-content',
          color: 'white',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
      >
        {currentStatus || ''}
      </div>
    </div>
    <FieldInfo label="Name" value={name} />
    <FieldInfo label="Brand" value={brand} />
    <FieldInfo label="Size" value={`${size?.amount}${size?.unit ? ' ' + size?.unit : ''}`} />
    <div style={{ marginTop: 10 }}>
      <div
        style={{
          display: 'flex',
          borderBottom: '1px solid #cfcfcf',
        }}
      >
        <div style={{ flex: 3 }}>
          <ReactJson name="metadata" collapsed={false} displayDataTypes={false} src={metadata} />
        </div>
      </div>
    </div>
  </div>
);

export default RetailProductComponent;
