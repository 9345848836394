import { Input, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import RemoveAccents from 'remove-accents';

export type CurrentFilter = {
  orderNumber?: string;
  customerEmail?: string;
};

type Props = {
  currentFilter: CurrentFilter;
  setCurrentFilter: Function;
  setCurrentPage: Function;
};

const OrderFilter = ({ currentFilter, setCurrentFilter, setCurrentPage }: Props) => {
  const [orderNumber, setOrderNumber] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [searchDisabled, setSearchDisabled] = useState(true);

  useEffect(() => {
    const queryObject = {
      ...(!!orderNumber && { orderNumber }),
      ...(!!customerEmail && { customerEmail }),
    };
    setSearchDisabled(JSON.stringify(queryObject) === JSON.stringify(currentFilter));
  }, [orderNumber, customerEmail, currentFilter]);

  const search = () => {
    setCurrentPage(1);
    const queryObject = {
      ...(!!orderNumber && { orderNumber }),
      ...(!!customerEmail && { customerEmail }),
    };
    if (Object.keys(queryObject).length) {
      setCurrentFilter(queryObject);
    } else if (Object.keys(currentFilter).length) {
      setCurrentFilter({});
    }
  };

  const resetFilter = () => {
    setOrderNumber('');
    setCustomerEmail('');
    setCurrentFilter({});
  };

  return (
    <div className="filter-root">
      <div className="product-filter">
        <div className="filter-item">
          <Input
            placeholder="Order number"
            onChange={(e) => setOrderNumber(RemoveAccents(e.target.value))}
            value={orderNumber}
            allowClear={true}
          />
        </div>
        <div className="filter-item">
          <Input
            placeholder="customer email"
            onChange={(e) => setCustomerEmail(RemoveAccents(e.target.value))}
            value={customerEmail}
            allowClear={true}
          />
        </div>
        <Button
          className="searchButton"
          onClick={() => search()}
          type="primary"
          disabled={searchDisabled}
          data-testid="searchButton"
        >
          Search
        </Button>

        <Button
          className="searchButton"
          onClick={() => resetFilter()}
          type="primary"
          disabled={orderNumber === '' && customerEmail === ''}
          data-testid="resetButton"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default OrderFilter;
