import {
  DAY_OF_WEEK,
  DayOfWeek,
  IAvailableHours,
  IHourRange,
} from '../../../../../utils/interfaces/available_hours';
import { MinusOutlined, PlusOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Space, TimePicker, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

export type WeekTableProps = {
  initialValue?: IAvailableHours;
  onChange?: (value: IAvailableHours) => void;
};

const emptyData: IAvailableHours = DAY_OF_WEEK.reduce(
  (acc, day) => ({ ...acc, [day]: [] }),
  {} as IAvailableHours
);

const WeekTable: React.FC<WeekTableProps> = ({ initialValue = emptyData, onChange }) => {
  const [data, setData] = useState(initialValue);

  const handleChange = (
    day: DayOfWeek,
    index: number,
    field: keyof IHourRange,
    time?: Moment | null
  ) => {
    const newDayData = [...data[day]];
    newDayData[index][field] = time?.format('HH:mm');
    setData({ ...data, [day]: newDayData });
  };

  const addRange = (day: DayOfWeek) => {
    setData({ ...data, [day]: [...data[day], { start: null, end: null }] });
  };

  const removeRange = (day: DayOfWeek, index: number) => {
    const newDayData = [...data[day]];
    newDayData.splice(index, 1);
    setData({ ...data, [day]: newDayData });
  };

  const duplicateRanges = (day: DayOfWeek) => {
    const dayIndex = DAY_OF_WEEK.indexOf(day);
    const nextDay = DAY_OF_WEEK[(dayIndex + 1) % 7];
    setData({ ...data, [nextDay]: [...data[day]] });
  };

  useEffect(() => {
    onChange?.(data);
  }, [data, onChange]);

  return (
    <Collapse defaultActiveKey={Object.keys(data)}>
      {DAY_OF_WEEK.map((day) => (
        <Collapse.Panel
          header={<span style={{ textTransform: 'capitalize' }}>{day}</span>}
          key={day}
          extra={
            <Tooltip title="Duplicate ranges">
              <Button
                data-testid={`${day}-duplicateRanges`}
                type="primary"
                shape="circle"
                icon={<ArrowDownOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  duplicateRanges(day);
                }}
              />
            </Tooltip>
          }
        >
          {data[day].map((range, index) => (
            <Row
              key={`${day}-${index}:${range.start}-${range.end}`}
              align="middle"
              gutter={[16, 8]}
            >
              <Col>
                <TimePicker.RangePicker
                  data-testid={`${day}-${index}-rangePicker`}
                  defaultValue={[
                    range.start ? moment(range.start, 'HH:mm') : null,
                    range.end ? moment(range.end, 'HH:mm') : null,
                  ]}
                  format="HH:mm"
                  onChange={(times) => {
                    handleChange(day, index, 'start', times?.[0]);
                    handleChange(day, index, 'end', times?.[1]);
                  }}
                />
              </Col>
              <Col>
                <Tooltip title="Remove range">
                  <Button
                    data-testid={`${day}-${index}-removeRange`}
                    type="primary"
                    shape="circle"
                    icon={<MinusOutlined />}
                    onClick={() => removeRange(day, index)}
                  />
                </Tooltip>
              </Col>
            </Row>
          ))}
          <Space>
            <Button
              data-testid={`${day}-addRange`}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addRange(day)}
            >
              Add range
            </Button>
          </Space>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default WeekTable;
