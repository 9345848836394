import downArrowLogo from '../../assets/down_arrow.svg';
import logo from '../../assets/header-logo.svg';
import './header.less';
import { MODULE_USER } from '../../models/model_user';
import { routes } from '../../utils/enum';
import { Net } from '../../utils/utils';
import { LogoutOutlined } from '@ant-design/icons';
import { Layout, Avatar, Dropdown, Typography } from 'antd';
import { doAction, doFunction } from 'module-reaction';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const { Header } = Layout;

export const HeaderComponent: React.FC = () => {
  const history = useHistory();
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false; // Cleanup function to set isMounted to false on unmount
    },
    []
  );

  const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);
  const onClick = ({ key }: any) => {
    if (key === 'logout' && isMounted.current) {
      doAction(MODULE_USER, { logined: false });
      doFunction(async () => {
        Net.clearToken();
        history.push(routes.signIn);
      });
    }
  };

  const items = [
    {
      label: (
        <div data-testid="render-logout-button">
          <LogoutOutlined />
          &nbsp;&nbsp;
          <span className="header-logout-text">Logout</span>
        </div>
      ),
      key: 'logout',
    },
  ];

  return (
    <Header>
      <img className="header-logo logo-space" data-testid="logo-image" src={logo} alt="Jointly" />
      <Dropdown className="header-avatar-drop" menu={{ items, onClick }}>
        <div data-testid="dropdown-image" className="header-logo-parent">
          <div className="header-logo-avatar" data-testid="user-avatar">
            <Avatar
              style={{ backgroundColor: '#5344ffff' }}
              alt="avatar"
              src={
                userDetails?.avatar?.link ? (
                  <img
                    className="avatar-image"
                    src={userDetails?.avatar.link}
                    alt="avatar"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = 'https://cdn-icons-png.flaticon.com/128/149/149071.png';
                    }}
                  />
                ) : (
                  <Typography.Title
                    style={{
                      color: '#ffffff',
                      lineHeight: '32px',
                    }}
                    level={4}
                  >
                    {userDetails?.username[0].toUpperCase()}
                  </Typography.Title>
                )
              }
            />
          </div>
          <div className="header-user-name">{userDetails?.username}</div>
          <img src={downArrowLogo} className="header-down-logo" alt="down-arow" />
        </div>
      </Dropdown>
    </Header>
  );
};
