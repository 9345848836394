import Table from './organization_table/table-wrapper';
import React from 'react';
import './organization.less';
import { Redirect, Route, Switch } from 'react-router-dom';

const Organization: React.FC = () => (
  <div className="organization-list-view organizations-list-view">
    <div className="organizations-list-header">Organization</div>
    <Switch>
      <Route path="/organizationlist" component={Table} />
      <Redirect to="/organizationlist" />
    </Switch>
  </div>
);

export default Organization;
