import { routes } from '../utils/enum';
import { Net } from '../utils/utils';
import { message } from 'antd';
import { ModuleStore, ModuleAction, KV, plusAction, doAction } from 'module-reaction';

export interface User {
  id?: string;
  email?: string;
  username?: string;
  avatar?: Doc;
  country?: string;
  role?: string[];

  location?: { coordinates?: number[] };
  last_successful_login_time?: string; // date
  last_failed_login_time?: string; // date
  profile?: {
    gender?: string;
    date_of_birth?: string; // date string
    // Height is xx Feet, xx Inches
    height?: {
      feet?: number;
      inches?: number;
    };
    weight?: number;
    ethnicity?: string | string[];
    education_level?: string;
    marital?: string;
    employment?: string;
    year_of_starting_use_cannabis?: number;
    use_cannabis_frequency?: string;
    banned?: boolean;
  };
  profile_progress?: number;
}
export interface ModleUser extends ModuleStore {
  logined?: boolean;
  list?: User[]; // current result for show
  total?: number; // total num of current search
  searchKey?: string; // search keyword
  pageSize?: number; // when fetch, pageSize=limit
  pageIdx?: number; //start with 0, when fetch, skip=pageSize * pageIdx
  curTab?: 'active' | 'banned';
  pageLoading?: boolean;
  loading?: boolean;
  hasMore?: boolean;
  country?: string;
  ethnicity?: string;
  gender?: string;
  birthday?: string;
  height?: string;
  weight?: string;
  education_level?: string;
  marital_status?: string;
  employment?: string;
}
export const MODULE_USER = 'MODULE_USER';
export const modleUser: ModleUser = {
  module: MODULE_USER,
  logined: false,
  list: [],
  total: 0,
  searchKey: '',
  pageIdx: 1,
  pageSize: 20,
  curTab: 'active',
  loading: true,
  pageLoading: false,
  hasMore: true,
};

export const LoginWithTokenAction: ModuleAction<KV, ModleUser> = {
  module: MODULE_USER,
  name: 'LoginWithTokenAction',
  process: async (history: KV, module: ModleUser) => {
    if (module.logined) {
      return {
        loading: false,
      };
    }
    const res = await Net.req('/user/signin-with-token');
    if (res?.jwt_token) {
      localStorage.setItem('userDetails', JSON.stringify(res.user));
      return {
        logined: true,
        loading: false,
      };
    }
    history.push(routes.signIn);

    return {};
  },
};

export const LoadingUsersAction: ModuleAction<KV, ModleUser> = {
  module: MODULE_USER,
  name: 'LoadingUsersAction',
  process: async () => ({ list: [], total: 0, loading: true }),
};

export const FetchUsersAction: ModuleAction<KV, ModleUser> = {
  module: MODULE_USER,
  name: 'FetchUsersAction',
  process: async (payload: KV, model: ModleUser) => {
    const {
      curTab,
      search_key: searchKey,
      pageSize,
      pageIdx,
      country,
      ethnicity,
      gender,
      birthday,
      height,
      weight,
      education_level: educationLevel,
      marital_status: maritalStatus,
      employment,
      showNoResults = true,
    } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/user/admin-search',
      {
        banned: curTab === 'banned',
        search_key: searchKey,
        limit: pageSize,
        skip: (pageSize ?? 0) * ((pageIdx ?? 0) - 1),
        country: country || '',
        ethnicity: ethnicity || '',
        gender: gender || '',
        birthday: birthday || '',
        height: height || '',
        weight: weight || '',
        education_level: educationLevel || '',
        marital_status: maritalStatus || '',
        employment: employment || '',
      },
      'get'
    );
    if (res.list && res.list.length) {
      return {
        list: res.list,
        total: res.total,
        curTab,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        pageLoading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    if (showNoResults) message.info('No results');
    return { list: [], total: 0, loading: false, pageLoading: false };
  },
};

const FetchMoreUsersActionPageLoading: ModuleAction<KV, ModleUser> = {
  module: MODULE_USER,
  name: 'FetchMoreUsersActionPageLoading',
  process: async (payload: KV, model: ModleUser) => {
    const {
      curTab,
      search_key: searchKey,
      pageSize,
      pageIdx,
      country,
      ethnicity,
      gender,
      birthday,
      height,
      weight,
      education_level: educationLevel,
      marital_status: maritalStatus,
      employment,
    } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/user/admin-search',
      {
        banned: curTab === 'banned',
        search_key: searchKey || '',
        limit: pageSize,
        skip: (pageSize ?? 0) * ((pageIdx ?? 0) - 1),
        country: country || '',
        ethnicity: ethnicity || '',
        gender: gender || '',
        birthday: birthday || '',
        height: height || '',
        weight: weight || '',
        education_level: educationLevel || '',
        marital_status: maritalStatus || '',
        employment: employment || '',
      },
      'get'
    );
    if (res.list && res.list.length) {
      return {
        list: [...(model.list ?? []), ...res.list],
        curTab,
        searchKey,
        pageSize,
        pageIdx,
        pageLoading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { total: 0, pageLoading: false, hasMore: false };
  },
};

export const FetchMoreUsersAction: ModuleAction<KV, ModleUser> = {
  module: MODULE_USER,
  name: 'FetchMoreUsersAction',
  process: async (payload: KV) => {
    doAction(FetchMoreUsersActionPageLoading, payload);
    return { pageLoading: true };
  },
};

export const ChgBanUsersAction: ModuleAction<KV, ModleUser> = {
  module: MODULE_USER,
  name: 'ChgBanUsersAction',
  process: async (payload: KV, moduleStore: ModleUser) => {
    const { users } = payload;
    const mode = moduleStore.curTab === 'active' ? 'ban' : 'unban';
    const res = await Net.req('/user/admin-ban-user', { users, mode }, 'post');
    if (res?.message === 'success') {
      plusAction(FetchUsersAction); // fresh curtab's users
    }
    return {};
  },
};
