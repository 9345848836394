import {
  InstallRetailLocationPayload,
  installRetailLocation,
} from '../../../services/api/retailLocationAPI';
import { useMutation } from '@tanstack/react-query';
import { Modal, notification, Button, Form, FormInstance } from 'antd';
import React from 'react';
import './SyncRetailLocationInventoryModal.less';

type Props = {
  visible: boolean;
  onRequestClose: () => void;
  data: {
    id: string;
    pos: any;
  };
};

const EditRetailLocationModal = ({ visible, onRequestClose, data }: Props) => {
  const formRef = React.createRef<FormInstance>();

  const [form] = Form.useForm();

  const installRetailLocationMutation = useMutation({
    mutationFn: (data: InstallRetailLocationPayload) => installRetailLocation(data),
    onSuccess: () => {
      notification.success({
        message: 'Inventory synchronization started',
      });
      onRequestClose();
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  return (
    <Modal visible={visible} footer={null} width="966px" onCancel={onRequestClose}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={() => {
          installRetailLocationMutation.mutate({
            id: data.id,
          });
        }}
        ref={formRef}
      >
        <div className="modal-title" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          Synchronize inventory
          <div className="option-buttons">
            <Button type="link" className="cancel-button" onClick={onRequestClose}>
              Cancel
            </Button>
            <span className="buttons-space" />
            <Button type="primary" htmlType="submit" loading={false} disabled={false}>
              Continue
            </Button>
          </div>
        </div>
        <div className="approve-modal-product approve-modal">
          <div className="product-modal-col-2">
            <p>
              Clicking &quot;continue&quot; will start the synchronization of store products with
              the Jointly database, which may take some time.
            </p>
            <p>
              Keep in mind that this will only update the products that have been changed since the
              last synchronization.
            </p>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditRetailLocationModal;
