import './retail_location_modal.less';
import { DaysOffList, WeekTable } from './components';
import EligibleZipCodeInput from './components/eligible_zip_code/EligibleZipCode';
import MenuForm, { Menu } from './components/menu';
import ImagePicker from '../../../components/image-picker/ImagePicker';
import { Organization } from '../../../models/model_organization_paginated';
import { Promotion } from '../../../models/model_retail_location_paginated';
import { getSearchOrganizationList } from '../../../services/api/organizationAPI/organizationListAPI';
import {
  AddRetailLocation,
  EditRetailLocation,
  editRetailLocation,
  addRetailLocation,
} from '../../../services/api/retailLocationAPI';
import { getSetting } from '../../../services/api/settings';
import apiClient from '../../../services/apiClient';
import { IAvailableHours } from '../../../utils/interfaces/available_hours';
import { States } from '../../../utils/options';
import { parsePromotionValue } from '../helpers';
import {
  promotionsDictionary,
  PromotionsDictionary,
} from '../retail_location_detail/RetailLocationDetail';
import { ClearOutlined, CloseOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Input,
  Modal,
  Select,
  notification,
  Button,
  Form,
  FormInstance,
  Checkbox,
  Row,
  Col,
  Card,
  Statistic,
  Slider,
  Tooltip,
  message,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { SliderMarks } from 'antd/lib/slider';
import axios, { AxiosError } from 'axios';
import React, { useState, useEffect, useMemo, createRef } from 'react';
export enum Action {
  add = 'Add',
  edit = 'Edit',
}

interface ConnectionInfo {
  name: string;
  type: string;
}

export interface EligibleZipCode {
  value: string;
  min: number;
}

type ImageUploadResponse = {
  files: {
    fileName: string;
    uploadUrl: string;
  }[];
};

type Props = {
  visible: boolean;
  onRequestClose: () => void;
  editObject?: {
    id: string;
    name: string;
    phoneNumber: string;
    address: string;
    website: string;
    state: string;
    zipcode: string;
    isOnlineStore: boolean;
    pickup: boolean;
    delivery: boolean;
    mobileEnabled: boolean;
    mobWebEnabled: boolean;
    isMedicalOnly: boolean;
    bccEmail: string;
    organization: {
      id: string;
      name: string;
    };
    latitude: string | number;
    longitude: string | number;
    posName: string;
    posType: string;
    customerSearchType: string;
    customerSearchBy: string[];
    posStockMinimum: number;
    posDisabled: boolean;
    connectionInfo: ConnectionInfo;
    promotions?: Promotion[];
    pickupHours?: IAvailableHours;
    deliveryHours?: IAvailableHours;
    daysOff?: string[];
    logoUrl?: string;
    bannerUrl?: string;
    defaultDeliveryWindow?: string;
    preOrderAdvanceDays?: number;
    eligibleZipCodes?: EligibleZipCode[];
    deliveryWindow?: number;
    paymentMethods?: string[];
    pickupPaymentMethods?: string[];
    isPhotoIdRequired?: boolean;
    photoIdInstruction?: string;
    pricesIncludeTaxes?: boolean;
    menus?: Menu[];
  };
  action: Action;
};

const marks: SliderMarks = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
};

const RetailLocationModal = ({ visible, onRequestClose, editObject, action }: Props) => {
  const [name, setName] = useState(editObject?.name || '');
  const [phoneNumber, setPhoneNumber] = useState(editObject?.phoneNumber || '');
  const [address, setAddress] = useState(editObject?.address || '');
  const [website, setWebsite] = useState(editObject?.website || '');
  const [state, setState] = useState(editObject?.state || '');
  const [zipcode, setZipcode] = useState(editObject?.zipcode || '');
  const [isOnlineStore, setIsOnlineStore] = useState(editObject?.isOnlineStore || false);
  const [pickup, setPickup] = useState(editObject?.pickup || false);
  const [delivery, setDelivery] = useState(editObject?.delivery || false);
  const [defaultDeliveryWindow, setDefaultDeliveryWindow] = useState(
    editObject?.defaultDeliveryWindow || ''
  );
  const [preOrderAdvanceDays, setPreOrderAdvanceDays] = useState(
    editObject?.preOrderAdvanceDays ?? 0
  );
  const [mobileEnabled, setMobileEnabled] = useState(editObject?.mobileEnabled || false);
  const [mobWebEnabled, setMobWebEnabled] = useState(editObject?.mobWebEnabled || false);
  const [isMedicalOnly, setIsMedicalOnly] = useState(editObject?.isMedicalOnly || false);
  const [bccEmail, setBccEmail] = useState(editObject?.bccEmail || '');
  const [organization, setOrganization] = useState(editObject?.organization?.id || '');
  const [organizationList, setOrganizationList] = useState<Organization[]>([]);
  const [latitude, setLatitude] = useState(editObject?.latitude || '');
  const [longitude, setLongitude] = useState(editObject?.longitude || '');
  const [posName, setPosName] = useState(editObject?.posName || '');
  const [posType, setPosType] = useState(editObject?.posType || '');
  const [customerSearchType, setCustomerSearchType] = useState(
    editObject?.customerSearchType || ''
  );
  const [customerSearchBy, setCustomerSearchBy] = useState<string[]>(
    editObject?.customerSearchBy || []
  );
  const [posStockMinimum, setPosStockMinimum] = useState(editObject?.posStockMinimum || '');
  const [promotionsList, setPromotionsList] = useState<Promotion[]>(editObject?.promotions || []);
  const [currentPromotionType, setCurrentPromotionType] = useState('');
  const [currentPromotionValueType, setCurrentPromotionValueType] = useState<
    'number' | 'boolean'
  >();
  const [currentPromotionValue, setCurrentPromotionValue] = useState<number | boolean | null>(null);
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfo | object>(
    editObject?.connectionInfo || {}
  );

  const [daysOff, setDaysOff] = useState(editObject?.daysOff || []);
  const [pickupHours, setPickupHours] = useState(editObject?.pickupHours);
  const [deliveryHours, setDeliveryHours] = useState(editObject?.deliveryHours);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [deliveryWindowTimeBlock, setDeliveryWindowTimeBlock] = useState(
    editObject?.deliveryWindow || 0
  );
  const [paymentMethods, setPaymentMethods] = useState<string[]>(editObject?.paymentMethods || []);
  const [pickupPaymentMethods, setPickupPaymentMethods] = useState<string[]>(
    editObject?.pickupPaymentMethods || []
  );
  const [isPhotoIdRequired, setIsPhotoIdRequired] = useState(
    editObject?.isPhotoIdRequired || false
  );
  const [eligibleZipCodes, setEligibleZipCodes] = useState<EligibleZipCode[]>(
    editObject?.eligibleZipCodes || []
  );
  const [photoIdInstruction, setPhotoIdInstruction] = useState(
    editObject?.photoIdInstruction || ''
  );

  const [slug, setSlug] = useState('');

  const [pricesIncludeTaxes, setPricesIncludeTaxes] = useState(
    editObject?.pricesIncludeTaxes ?? null
  );
  const [automaticInventorySyncsEnabled, setAutomaticInventorySyncsEnabled] = useState<boolean>(
    !editObject?.posDisabled
  );
  const [menus, setMenus] = useState<Menu[] | undefined>(editObject?.menus);
  const [form] = Form.useForm();

  const posTypesQuery = useQuery({
    queryKey: ['getSettingPosType'],
    queryFn: () => getSetting('posTypes'),
    onError: () => {
      notification.error({
        message: 'Something went wrong!.',
        description: 'Unable to get posTypes',
      });
    },
    enabled: visible,
  });

  useEffect(() => {
    if (!organizationList.length) {
      getSearchOrganizationList().then((res) => setOrganizationList(res?.data?.list || []));
    }
  }, [organizationList.length]);

  const formRef = createRef<FormInstance>();

  const queryClient = useQueryClient();

  const onSuccess = () => {
    if (action === Action.add) form.resetFields();
    notification.success({
      message: 'Updated successfully',
    });
    queryClient.invalidateQueries({ queryKey: ['getRetailLocation'] });
    onRequestClose();
  };

  const onError = (err: AxiosError) => {
    notification.error({
      message: err?.response?.data?.message || 'Something went wrong!',
    });
  };

  const editMutation = useMutation({
    mutationFn: (updateRetailLocation: EditRetailLocation) =>
      editRetailLocation(updateRetailLocation),
    onSuccess,
    onError,
  });

  const addMutation = useMutation({
    mutationFn: (_addRetailLocation: AddRetailLocation) => addRetailLocation(_addRetailLocation),
    onSuccess,
    onError,
  });

  const requiredFields = useMemo(
    () =>
      (!!posType &&
        posTypesQuery.data?.value?.find(
          (posOption: { type: string }) => posOption.type === posType
        ) &&
        posTypesQuery.data?.value?.find(
          (posOption: { type: string; requiredFields: string[] }) => posOption.type === posType
        )?.requiredFields) ||
      [],
    [posType, posTypesQuery]
  );

  const availableCustomerSearchBy = useMemo(
    () =>
      (!!posType &&
        posTypesQuery.data?.value?.find((e) => e.type === posType)?.customerSearch
          ?.availableCustomerSearchBy) ||
      [],
    [posType, posTypesQuery]
  );

  const initialValues = {
    name: editObject?.name || '',
    phoneNumber: editObject?.phoneNumber || '',
    address: editObject?.address || '',
    website: editObject?.website || '',
    state: editObject?.state || '',
    zipcode: editObject?.zipcode || '',
    posName: editObject?.posName || '',
    posStockMinimum: editObject?.posStockMinimum || '',
    bccEmail: editObject?.bccEmail || '',
    ...(editObject?.connectionInfo ? { ...editObject?.connectionInfo } : {}),
    latitude: editObject?.latitude || '',
    longitude: editObject?.longitude || '',
    organization: editObject?.organization?.id || '',
    posType: editObject?.posType || '',
    customerSearchType: editObject?.customerSearchType || '',
    customerSearchBy:
      editObject?.customerSearchBy && editObject?.customerSearchBy.length > 0
        ? editObject?.customerSearchBy
        : availableCustomerSearchBy || [],
    defaultDeliveryWindow: editObject?.defaultDeliveryWindow || '',
    preOrderAdvanceDays: editObject?.preOrderAdvanceDays ?? 0,
    eligibleZipCodes: editObject?.eligibleZipCodes || [],
    deliveryWindowTimeBlock: editObject?.deliveryWindow || 0,
    paymentMethods: editObject?.paymentMethods || [],
    pickupPaymentMethods: editObject?.pickupPaymentMethods || [],
    isPhotoIdRequired: editObject?.isPhotoIdRequired || false,
    pricesIncludeTaxes: editObject?.pricesIncludeTaxes ?? null,
  };

  const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);
  const isAdmin = userDetails?.role.includes('admin_user');

  const uploadImage = async (file: File) => {
    try {
      const response = await apiClient.post<ImageUploadResponse>(
        `org/v1/location/${editObject?.id}/upload`,
        {
          files: [
            {
              fileName: file.name,
              contentType: file.type,
            },
          ],
        }
      );
      const url = response.data.files[0].uploadUrl;
      //For some reason axios throws a 400 on this request... so we use fetch
      const res = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (res.status !== 200) throw new Error(`Error uploading image: ${res.statusText}`);
      const strippedUrl = url.split('?')[0];
      return strippedUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      if (axios.isAxiosError(error) && error.response?.data?.message) {
        message.error(`Error uploading image: ${error.response.statusText}`);
      } else message.error(error);
      return undefined;
    }
  };

  return (
    <Modal
      open={visible}
      confirmLoading={editMutation.isLoading || addMutation.isLoading}
      footer={null}
      width="966px"
      onCancel={() => {
        onRequestClose();
        form.setFieldsValue(initialValues);
        setMenus(editObject?.menus);
      }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={async (formData) => {
          const {
            name,
            phoneNumber,
            address,
            website,
            state,
            zipcode,
            posName,
            latitude,
            longitude,
            organization,
            posType,
            customerSearchType,
            customerSearchBy,
            posStockMinimum,
          } = formData;

          let logoUrl = editObject?.logoUrl;
          let bannerUrl = editObject?.bannerUrl;

          if (logoFile) logoUrl = await uploadImage(logoFile as File);
          if (bannerFile) bannerUrl = await uploadImage(bannerFile as File);

          const payload = {
            name,
            isOnlineStore,
            pickup,
            delivery,
            mobileEnabled,
            mobWebEnabled,
            isMedicalOnly,
            phoneNumber,
            ...(logoUrl && { logoUrl }),
            ...(bannerUrl && { bannerUrl }),
            bccEmail,
            ...(address ? { address } : {}),
            ...(website ? { website } : {}),
            ...(state ? { state: state.toString() } : {}),
            ...(zipcode ? { zipcode } : {}),
            ...(!!latitude && longitude
              ? {
                  geoLocation: {
                    type: 'Point',
                    coordinates: [longitude, latitude],
                  },
                }
              : {}),
            pos: {
              name: posName,
              type: posType,
              customerSearchType,
              customerSearchBy,
              stockMinimum: posStockMinimum,
              connectionInfo,
              ...(menus ? { menus } : {}),
              disabled: !automaticInventorySyncsEnabled,
            },
            ...(Array.isArray(promotionsList) ? { promotions: promotionsList } : {}),
            ...(pickupHours ? { pickupHours } : {}),
            ...(deliveryHours ? { deliveryHours } : {}),
            ...(eligibleZipCodes ? { eligibleZipCodes } : {}),
            ...(defaultDeliveryWindow ? { defaultDeliveryWindow } : {}),
            ...(preOrderAdvanceDays !== undefined ? { preOrderAdvanceDays } : {}),
            ...(deliveryWindowTimeBlock ? { deliveryWindow: deliveryWindowTimeBlock } : {}),
            ...(paymentMethods ? { paymentMethods } : {}),
            ...(pickupPaymentMethods ? { pickupPaymentMethods } : {}),
            ...(daysOff ? { daysOff } : {}),
            isPhotoIdRequired,
            photoIdInstruction,
            pricesIncludeTaxes,
          };

          if (action === Action.edit && editObject) {
            editMutation.mutate({
              ...payload,
              id: editObject?.id,
              retailOrganization: {
                id: organization,
                name: organizationList.find((item) => item.id === organization)?.name,
              },
            });
          } else if (action === Action.add) {
            addMutation.mutate({
              ...payload,
              retailOrganizationId: organization,
              slug,
            });
          }
        }}
        initialValues={initialValues}
        ref={formRef}
      >
        <div
          className="product-modal-title"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {action} Retail Location
          <div className="edit-product-option-buttons">
            <Button
              type="link"
              className="cancel-button"
              onClick={() => {
                onRequestClose();
                form.setFieldsValue(initialValues);
                setMenus(editObject?.menus);
              }}
            >
              Cancel
            </Button>
            <span className="add-product-space" />
            <Button
              type="primary"
              htmlType="submit"
              loading={editMutation.isLoading || addMutation.isLoading}
              disabled={editMutation.isLoading || addMutation.isLoading}
            >
              Save
            </Button>
          </div>
        </div>

        <div className="approve-modal-product approve-modal">
          <div className="product-modal-col-2">
            <div className="form-item">
              <div>Name</div>
              <Form.Item name="name" rules={[{ required: true }]}>
                <Input
                  placeholder="Name"
                  onBlur={() => {
                    setSlug(name.toLowerCase().replace(/\s+/g, '-'));
                    if (formRef.current)
                      formRef.current.setFieldValue(
                        'slug',
                        name.toLowerCase().replace(/\s+/g, '-')
                      );
                  }}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Item>
            </div>

            <div className="logoAndBannerWrapper">
              <ImagePicker label="Logo" url={editObject?.logoUrl} onChange={setLogoFile} />
              <ImagePicker
                label="Storefront"
                format="rectangle"
                url={editObject?.bannerUrl}
                onChange={setBannerFile}
              />
            </div>

            {action === Action.add && (
              <div className="form-item">
                <div>slug</div>
                <Form.Item
                  name="slug"
                  shouldUpdate
                  initialValue={slug}
                  rules={[{ required: true }]}
                >
                  <Input onChange={(e) => setSlug(e.target.value)} value={slug} />
                </Form.Item>
              </div>
            )}

            <div className="sectionTitle1">Location visibility & Settings</div>
            <div className="form-item" style={{ marginBottom: 20 }} data-testid="mobile-enabled">
              <Checkbox
                defaultChecked={mobileEnabled}
                onChange={(e: CheckboxChangeEvent) => {
                  setMobileEnabled(e.target.checked);
                }}
              >
                Mobile App (iOS)
              </Checkbox>
            </div>
            <div className="form-item" style={{ marginBottom: 20 }} data-testid="mob-web-enabled">
              <Checkbox
                defaultChecked={mobWebEnabled}
                onChange={(e: CheckboxChangeEvent) => {
                  setMobWebEnabled(e.target.checked);
                }}
              >
                Web Browser (Android)
              </Checkbox>
            </div>
            <div className="form-item" style={{ marginBottom: 20 }}>
              <Checkbox
                defaultChecked={isMedicalOnly}
                onChange={(e: CheckboxChangeEvent) => {
                  setIsMedicalOnly(e.target.checked);
                }}
              >
                Medical Only
              </Checkbox>
            </div>
            <div className="form-item" style={{ marginBottom: 20 }}>
              <Checkbox
                defaultChecked={delivery}
                onChange={(e: CheckboxChangeEvent) => {
                  setDelivery(e.target.checked);
                }}
              >
                Delivery
              </Checkbox>
            </div>

            {!isOnlineStore && (
              <>
                <div className="form-item" style={{ marginBottom: 20 }}>
                  <Checkbox
                    defaultChecked={pickup}
                    onChange={(e: CheckboxChangeEvent) => {
                      setPickup(e.target.checked);
                    }}
                  >
                    Pickup
                  </Checkbox>
                </div>
              </>
            )}

            {(delivery || pickup) && (
              <div className="form-item">
                <div>
                  Days Off{' '}
                  <Tooltip
                    title="The 'Days Off' option affects both delivery and pickup. On these days, the dispensary hours will show 'Closed'."
                    trigger={['hover', 'click']}
                  >
                    <Button shape="circle" type="text" icon={<InfoCircleOutlined />} />
                  </Tooltip>
                </div>
                <DaysOffList dates={daysOff} onChange={(value) => setDaysOff(value)} />
              </div>
            )}

            {delivery && (
              <>
                <div className="sectionTitle2">Delivery Settings</div>
                <div className="form-item">
                  <div>Delivery Payment methods</div>
                  <Checkbox.Group
                    name="paymentMethods"
                    options={['Cash', 'Debit card', 'Credit card']}
                    defaultValue={paymentMethods}
                    onChange={(value) => {
                      setPaymentMethods(value as string[]);
                    }}
                  />
                </div>
                <div className="form-item">
                  <div>Photo ID</div>
                  <Checkbox
                    defaultChecked={isPhotoIdRequired}
                    onChange={(e: CheckboxChangeEvent) => {
                      setIsPhotoIdRequired(e.target.checked);
                    }}
                  >
                    Photo ID Upload Required
                  </Checkbox>
                </div>
                <div className="form-item">
                  <div>Photo ID instruction</div>
                  <Form.Item>
                    <Input
                      placeholder="Upload a photo of your driver's license or a government issued photo ID"
                      onChange={(e) => {
                        setPhotoIdInstruction(e.target.value);
                      }}
                      value={photoIdInstruction}
                    />
                  </Form.Item>
                </div>
                <div className="form-item">
                  <div>Default delivery time (ie. ASAP 60-90 minutes)</div>
                  <Form.Item name="defaultDeliveryWindow">
                    <Input
                      placeholder="Default delivery time"
                      onChange={(e) => setDefaultDeliveryWindow(e.target.value)}
                      value={defaultDeliveryWindow}
                    />
                  </Form.Item>
                </div>
                <div className="form-Item">
                  <div>
                    Delivery Window increments (hours){' '}
                    <Tooltip
                      title="Set the duration for delivery windows. Delivery windows will start from the earliest available delivery time. E.g., 2 will set 2 hour delivery windows: 12-2pm, 2-4pm, etc."
                      trigger={['hover', 'click']}
                    >
                      <Button shape="circle" type="text" icon={<InfoCircleOutlined />} />
                    </Tooltip>
                  </div>
                  <Form.Item name="deliveryWindowTimeBlock">
                    <Slider
                      marks={{
                        1: '1',
                        1.5: '1.5',
                        2: '2',
                        2.5: '2.5',
                        3: '3',
                        3.5: '3.5',
                        4: '4',
                        4.5: '4.5',
                        5: '5',
                        5.5: '5.5',
                        6: '6',
                      }}
                      min={1}
                      step={0.5}
                      value={deliveryWindowTimeBlock}
                      max={6}
                      onChange={(value) => setDeliveryWindowTimeBlock(value)}
                    />
                  </Form.Item>
                </div>
                <div className="form-Item">
                  <div>
                    Pre-Order Advance Days
                    <Tooltip
                      title="Set the number of days in advance users can place orders (0 = today only, 1 = today & tomorrow, etc.)"
                      trigger={['hover', 'click']}
                    >
                      <Button shape="circle" type="text" icon={<InfoCircleOutlined />} />
                    </Tooltip>
                  </div>
                  <Form.Item name="preOrderAdvanceDays">
                    <Slider
                      marks={marks}
                      step={null}
                      value={preOrderAdvanceDays}
                      max={7}
                      onChange={(value) => setPreOrderAdvanceDays(value)}
                    />
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="eligibleZipCodesHeaderWrapper">
                    <span>Eligible zip codes</span>
                    <span>Minimum order value</span>
                  </div>
                  <EligibleZipCodeInput
                    initialValue={eligibleZipCodes}
                    onChange={(value) => setEligibleZipCodes(value)}
                  />
                </div>
                <div>Delivery Time</div>
                <WeekTable
                  initialValue={deliveryHours}
                  onChange={(value) => setDeliveryHours(value)}
                />
              </>
            )}

            {pickup && (
              <>
                <div className="sectionTitle2">Pickup Settings</div>

                <div className="form-item">
                  <div>Pickup Payment methods</div>
                  <Checkbox.Group
                    name="pickupPaymentMethods"
                    options={['Cash', 'Debit card', 'Credit card']}
                    defaultValue={pickupPaymentMethods}
                    onChange={(value) => {
                      setPickupPaymentMethods(value as string[]);
                    }}
                  />
                </div>
                <div>Pickup Time</div>
                <WeekTable initialValue={pickupHours} onChange={(value) => setPickupHours(value)} />
              </>
            )}

            <div className="sectionTitle1">Location Details</div>
            {isAdmin && (
              <div className="form-item">
                <div>Organization</div>
                <Form.Item name="organization" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    placeholder="Organization"
                    optionFilterProp="children"
                    className="add-product-input-full-component"
                    onChange={(value) => setOrganization(value)}
                    value={organization}
                    filterOption={(input, option) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {organizationList.map(({ name, id }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}

            <div className="form-item">
              <div>Website URL</div>
              <Form.Item
                name="website"
                rules={[
                  {
                    required: isOnlineStore,
                    type: 'url',
                  },
                ]}
              >
                <Input
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                  data-testid="websiteInput"
                />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Street Address</div>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: !isOnlineStore,
                  },
                ]}
              >
                <Input
                  placeholder="Street Address"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>State</div>
              <Form.Item name="state" rules={[{ required: !isOnlineStore }]}>
                <Select
                  showSearch
                  placeholder="Legal States"
                  optionFilterProp="children"
                  className="add-product-input-full-component"
                  onChange={(value) => setState(value)}
                  value={state}
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {States.map(({ name, abbreviation }) => (
                    <Select.Option key={abbreviation} value={abbreviation}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Zip Code</div>
              <Form.Item name="zipcode" rules={[{ required: !isOnlineStore }]}>
                <Input
                  placeholder="Zip Code"
                  onChange={(e) => setZipcode(e.target.value)}
                  value={zipcode}
                />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Phone Number </div>
              <Form.Item name="phoneNumber" rules={[{ required: true }]}>
                <Input
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>BCC Email</div>
              <Form.Item name="bccEmail">
                <Input
                  placeholder="BCC"
                  onChange={(e) => setBccEmail(e.target.value)}
                  value={bccEmail}
                />
              </Form.Item>
            </div>

            <div>GPS Coords</div>

            <div className="form-item">
              <div>Latitude</div>
              <Form.Item
                name="latitude"
                rules={[
                  {
                    required: !isOnlineStore,
                  },
                ]}
              >
                <Input
                  placeholder="Latitude"
                  onChange={(e) => setLatitude(e.target.value)}
                  type="number"
                  value={`${latitude}`}
                />
              </Form.Item>
            </div>
            <div className="form-item">
              <div>Longitude</div>
              <Form.Item name="longitude" rules={[{ required: !isOnlineStore }]}>
                <Input
                  placeholder="Longitude"
                  onChange={(e) => setLongitude(e.target.value)}
                  type="number"
                  value={`${longitude}`}
                />
              </Form.Item>
            </div>

            {isAdmin && (
              <div className="form-item">
                <div>POS Name </div>
                <Form.Item name="posName" rules={[{ required: true }]}>
                  <Input
                    placeholder="POS Provider"
                    onChange={(e) => setPosName(e.target.value)}
                    value={posName}
                  />
                </Form.Item>
              </div>
            )}
            {isAdmin && (
              <div className="form-item">
                <div>POS Type </div>
                <Form.Item name="posType" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    placeholder="Pos Type"
                    optionFilterProp="children"
                    className="add-product-input-full-component"
                    onChange={(value) => {
                      setPosType(value);
                      setConnectionInfo({});
                      setCustomerSearchType('');
                      setCustomerSearchBy([]);
                    }}
                    value={posType}
                    filterOption={(input, option) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {posTypesQuery.data?.value?.map((posOption: { type: string }) => (
                      <Select.Option key={posOption.type} value={posOption.type}>
                        {posOption.type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            {isAdmin && (
              <div className="form-item" style={{ marginBottom: 20 }}>
                <Checkbox
                  defaultChecked={automaticInventorySyncsEnabled}
                  onChange={(e: CheckboxChangeEvent) => {
                    setAutomaticInventorySyncsEnabled(e.target.checked);
                  }}
                  data-testid="automaticInventorySyncsCheckbox"
                >
                  Automatic inventory reconciliations
                </Checkbox>
              </div>
            )}
            {isAdmin && (
              <div className="form-item">
                <div>Customer search type</div>
                <Form.Item name="customerSearchType" rules={[{ required: true }]}>
                  <Select
                    placeholder="Customer search type"
                    allowClear
                    className="add-product-input-full-component"
                    onChange={setCustomerSearchType}
                    value={customerSearchType}
                  >
                    {posTypesQuery.data?.value
                      ?.find((e) => e.type === posType)
                      ?.customerSearch?.availableCustomerSearchType?.map((searchType: string) => (
                        <Select.Option key={searchType} value={searchType}>
                          {searchType}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            {isAdmin && (
              <div className="form-item">
                <div>Customer search by</div>
                <Form.Item name="customerSearchBy">
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Customer search by"
                    className="add-product-input-full-component"
                    onChange={setCustomerSearchBy}
                    value={customerSearchBy}
                  >
                    {availableCustomerSearchBy
                      .filter((o: string) => !customerSearchBy.includes(o))
                      .map((searchBy: string) => (
                        <Select.Option key={searchBy} value={searchBy}>
                          {searchBy}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            {isAdmin && (
              <div className="form-item">
                <div>Inventory stock minimum</div>
                <Form.Item name="posStockMinimum" rules={[{ required: true }]}>
                  <Input
                    placeholder="Minimum stock level"
                    onChange={(e) => setPosStockMinimum(e.target.value)}
                    type="number"
                    value={`${posStockMinimum}`}
                  />
                </Form.Item>
              </div>
            )}
            {isAdmin &&
              requiredFields.map((field: string) => (
                <div key={field} className="form-item" style={{ marginLeft: 20 }}>
                  <div>{field}</div>
                  <Form.Item
                    name={field}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setConnectionInfo((prevState) => ({
                          ...prevState,
                          [field]: e.target.value,
                        }));
                      }}
                      style={{ width: '95%' }}
                      // @ts-expect-error open object no way to predict fields
                      defaultValue={connectionInfo[field] || ''}
                    />
                  </Form.Item>
                </div>
              ))}
            {isAdmin && (
              <div className="form-item">
                <div>Promotion</div>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item name={'promotion_type'} noStyle>
                      <Select
                        placeholder="Type"
                        style={{ width: '30%' }}
                        onChange={(value) => setCurrentPromotionType(value)}
                        value={currentPromotionType}
                      >
                        {Object.keys(promotionsDictionary)
                          .filter((item) => !promotionsList.map((prom) => prom.type).includes(item))
                          .map((key) => (
                            <Select.Option value={key} key={key}>
                              {promotionsDictionary[key as keyof PromotionsDictionary]}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'promotion_value_type'} noStyle>
                      <Select
                        placeholder="Value type"
                        style={{ width: '25%' }}
                        onChange={(value) => {
                          setCurrentPromotionValueType(value);
                          if (value === 'boolean') {
                            setCurrentPromotionValue(true);
                          }
                        }}
                        value={currentPromotionValueType}
                      >
                        <Select.Option value="number">Number</Select.Option>
                        <Select.Option value="boolean">Boolean</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={'promotion_value'} noStyle>
                      {currentPromotionValueType === 'boolean' ? (
                        <div className="checkBoxFreeDelivery">
                          <Checkbox
                            defaultChecked={!!currentPromotionValue}
                            onChange={(e: CheckboxChangeEvent) => {
                              setCurrentPromotionValue(e.target.checked);
                            }}
                          />
                        </div>
                      ) : (
                        <Input
                          style={{ width: '25%' }}
                          placeholder="Value"
                          type="number"
                          onChange={(e) => {
                            const value = !Number.isNaN(e.target.valueAsNumber)
                              ? e.target.valueAsNumber
                              : null;

                            setCurrentPromotionValue(value);
                          }}
                          value={currentPromotionValue?.toString()}
                        />
                      )}
                    </Form.Item>
                    <Button
                      style={{ width: '10%' }}
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setPromotionsList((prevState) => [
                          ...prevState,
                          {
                            type: currentPromotionType,
                            valueType: currentPromotionValueType as 'number' | 'boolean',
                            value: currentPromotionValue as number | boolean,
                          },
                        ]);
                        form.setFieldsValue({
                          promotion_type: '',
                          promotion_value_type: '',
                          promotion_value: '',
                        });
                        setCurrentPromotionType('');
                        setCurrentPromotionValue(null);
                      }}
                    />
                    <Button
                      style={{ width: '10%' }}
                      type="ghost"
                      icon={<ClearOutlined />}
                      onClick={() => {
                        setCurrentPromotionType('');
                        setCurrentPromotionValueType(undefined);
                        setCurrentPromotionValue(null);
                        form.setFieldsValue({
                          promotion_type: '',
                          promotion_value_type: '',
                          promotion_value: '',
                        });
                      }}
                    />
                  </Input.Group>
                </Form.Item>
              </div>
            )}

            <br />
            {isAdmin && (
              <Row gutter={16}>
                {!!promotionsList &&
                  !!promotionsList.length &&
                  promotionsList.map((item, index: number) => (
                    <Col key={index} span={12} style={{ marginBottom: 15 }}>
                      <Card
                        title={
                          promotionsDictionary[item.type as keyof PromotionsDictionary] || item.type
                        }
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              setPromotionsList((prevState) =>
                                prevState.filter((prom) => prom.type !== item.type)
                              );
                            }}
                            style={{ fontSize: 20 }}
                          />
                        }
                      >
                        <Statistic
                          value={parsePromotionValue(item)}
                          valueStyle={{
                            color: '#5344ff',
                          }}
                        />
                      </Card>
                    </Col>
                  ))}
              </Row>
            )}
            <div className="form-item">
              <div>Product prices include taxes</div>
              <Form.Item name="pricesIncludeTaxes" rules={[{ required: true }]}>
                <Select
                  placeholder="Prices include taxes"
                  className="add-product-input-full-component"
                  onChange={(value) => setPricesIncludeTaxes(value)}
                >
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </div>
            {isAdmin && (
              <div className="form-item">
                <MenuForm
                  onSave={(newMenus: Menu[]) => {
                    if (!newMenus || newMenus.length < 1) {
                      return setMenus([]);
                    }
                    setMenus(newMenus);
                  }}
                  menus={menus}
                />
              </div>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default RetailLocationModal;
