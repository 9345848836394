import { RetailLocation } from '../../../models/model_retail_location_paginated';
import { States } from '../../../utils/options';
import { Input, Button, Select, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import RemoveAccents from 'remove-accents';

export type CurrentFilter = {
  brand?: string;
  name?: string;
  typeName?: string;
  typeSpecific?: string;
  states?: string;
  retailLocation?: string;
  stockAvailable?: string;
};

export type CurrentSort = {
  sortBy: string;
};

type Props = {
  currentFilter: CurrentFilter;
  setCurrentFilter: Function;
  setCurrentPage: Function;
  retailLocationList: RetailLocation[];
};

const RetailProductFilter = ({
  currentFilter,
  setCurrentFilter,
  setCurrentPage,
  retailLocationList,
}: Props) => {
  const [brand, setBrand] = useState('');
  const [name, setName] = useState('');
  const [typeName, setTypeName] = useState('');
  const [typeSpecific, setTypeSpecific] = useState('');
  const [states, setStates] = useState('');
  const [retailLocation, setRetailLocation] = useState('');
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [stockAvailable, setStockAvailable] = useState(false);

  useEffect(() => {
    const queryObject = {
      ...(!!brand && { brand }),
      ...(!!name && { name }),
      ...(!!typeName && { typeName }),
      ...(!!typeSpecific && { typeSpecific }),
      ...(!!states && { states }),
      ...(!!retailLocation && { retailLocation }),
      ...(!!stockAvailable && { stockAvailable }),
    };
    setSearchDisabled(JSON.stringify(queryObject) === JSON.stringify(currentFilter));
  }, [brand, name, typeName, typeSpecific, states, retailLocation, stockAvailable, currentFilter]);

  const search = () => {
    setCurrentPage(1);
    const queryObject = {
      ...(!!brand && { brand }),
      ...(!!name && { name }),
      ...(!!typeName && { typeName }),
      ...(!!typeSpecific && { typeSpecific }),
      ...(!!states && { states }),
      ...(!!retailLocation && { retailLocation }),
      ...(!!stockAvailable && { stockAvailable }),
    };
    if (Object.keys(queryObject).length) {
      setCurrentFilter(queryObject);
    } else if (Object.keys(currentFilter).length) {
      setCurrentFilter({});
    }
  };

  const resetFilter = () => {
    setBrand('');
    setName('');
    setTypeName('');
    setTypeSpecific('');
    setStates('');
    setRetailLocation('');
    setStockAvailable(false);
    setCurrentFilter({});
  };

  return (
    <div className="filter-root">
      <Row
        style={{
          marginTop: 10,
          paddingBottom: 10,
          borderBottom: '1px solid #f0f0f0',
          gap: 5,
        }}
        justify="start"
      >
        <Col xs={12} sm={8} lg={6} xl={4} xxl={4}>
          <Input.Search
            placeholder="Brand"
            onChange={(e) => setBrand(RemoveAccents(e.target.value))}
            value={brand}
            allowClear={true}
          />
        </Col>
        <Col xs={11} sm={8} lg={6} xl={4} xxl={4}>
          <Input.Search
            placeholder="Name"
            onChange={(e) => setName(RemoveAccents(e.target.value))}
            value={name}
            allowClear={true}
          />
        </Col>
        <Col xs={12} sm={7} lg={6} xl={4} xxl={4}>
          <Input.Search
            placeholder="Type"
            onChange={(e) => setTypeName(RemoveAccents(e.target.value))}
            value={typeName}
            allowClear={true}
          />
        </Col>
        <Col xs={11} sm={7} lg={5} xl={4} xxl={4}>
          <Input.Search
            placeholder="Specific type"
            onChange={(e) => setTypeSpecific(RemoveAccents(e.target.value))}
            value={typeSpecific}
            allowClear={true}
          />
        </Col>
        <Col xs={12} sm={5} lg={4} xl={3} xxl={3}>
          <Select
            className="product-filter-name-3"
            placeholder="Stock"
            optionFilterProp="children"
            onChange={(e) => setStockAvailable(e)}
            value={stockAvailable}
            style={{ width: '100%' }}
          >
            <Select.Option value={false}>All</Select.Option>
            <Select.Option value={'inventory only'}>Inventory Only</Select.Option>
          </Select>
        </Col>
        <Col xs={11} sm={6} lg={5} xl={4} xxl={4}>
          <Select
            showSearch
            className="product-filter-name-3"
            placeholder="Shipping Legal States"
            optionFilterProp="children"
            onChange={(e) => setStates(e.join(','))}
            mode="multiple"
            value={states ? (states as any).split(',') : []}
            filterOption={(input, option: any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
          >
            <Select.Option style={{ display: 'none' }} key="all" value="" disabled>
              Legal States
            </Select.Option>
            {States.map(({ name, abbreviation }) => (
              <Select.Option key={abbreviation} value={abbreviation}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={12} sm={5} lg={5} xl={4} xxl={4}>
          {retailLocationList?.length && (
            <Select
              showSearch
              className="product-filter-name-2"
              optionFilterProp="children"
              placeholder="Location"
              allowClear
              onChange={(retailLocationId: string) => {
                setRetailLocation(retailLocationId);
              }}
              filterOption={(input, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={retailLocation ? retailLocation : undefined}
              style={{ width: '100%' }}
            >
              {retailLocationList
                .sort((a: any, b: any) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  return 1;
                })
                .map(({ name, id }: { name: string; id: string }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Col>
        <Col xs={11} sm={3} lg={3} xl={2} xxl={2}>
          <Button
            className="searchButton"
            onClick={() => search()}
            type="primary"
            disabled={searchDisabled}
            style={{ width: '100%' }}
          >
            Search
          </Button>
        </Col>
        <Col xs={12} sm={3} lg={3} xl={2} xxl={2}>
          <Button
            className="searchButton"
            onClick={() => resetFilter()}
            type="primary"
            disabled={
              brand === '' &&
              name === '' &&
              typeName === '' &&
              typeSpecific === '' &&
              states === '' &&
              retailLocation === '' &&
              stockAvailable === false
            }
            style={{ width: '100%' }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RetailProductFilter;
