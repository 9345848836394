import { RetailUserListView } from './retailUser_list';
import { ModleRetailUser } from '../../models/model_retail_user';
import { Menu } from 'antd';
import React from 'react';
import './retailUser_routing.less';
import {
  NavLink,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';

interface RetailUserListProp extends RouteComponentProps, ModleRetailUser {}

export const RetailUserRouting = (props: RetailUserListProp) => {
  const location = useLocation();

  return (
    <div className="list-view retail-users-list-view">
      <div className="retail-users-list-header">Retail Users</div>
      <Menu mode="horizontal" className="products-nav-panel" selectedKeys={[location.pathname]}>
        <Menu.Item key="/retailUsers/organization">
          <NavLink to="/retailUsers/organization">Organization level</NavLink>
        </Menu.Item>
        <Menu.Item key="/retailUsers/location">
          <NavLink to="/retailUsers/location">Location level</NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/retailUsers/organization">
          <RetailUserListView {...props} tabItem="organization" />
        </Route>
        <Route path="/retailUsers/location">
          <RetailUserListView {...props} tabItem="location" />
        </Route>
        <Redirect to="/retailUsers/organization" />
      </Switch>
    </div>
  );
};
