import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import React from 'react';

const YesOrNo = ({ value }: { value: boolean }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {value ? (
      <CheckCircleFilled
        style={{ color: '#2ECC71', fontSize: 20 }}
        data-testid="check-circle-icon"
      />
    ) : (
      <CloseCircleFilled
        style={{ color: '#E74C3C', fontSize: 20 }}
        data-testid="close-circle-icon"
      />
    )}
    <b
      style={{
        marginLeft: 5,
        color: value ? '#2ECC71' : '#E74C3C',
      }}
    >
      {value ? 'Yes' : 'No'}
    </b>
  </div>
);

export default YesOrNo;
