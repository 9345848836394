import { PendingBrandListView } from './pending_brand_list';
import { BrandListProp } from '../../../models/model_brand';
import React from 'react';
import { RouteComponentProps } from 'react-router';

const PendingBrand = (props: BrandListProp | RouteComponentProps) => (
  <PendingBrandListView {...(props as BrandListProp)} />
);

export default PendingBrand;
