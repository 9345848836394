import { User } from './model_user';
import { statusIconSuccess, statusIconProcessing, statusIconFail } from '../utils/enum';
import { Net } from '../utils/utils';
import { message } from 'antd';
import {
  ModuleStore,
  ModuleAction,
  KV,
  getModuleProp,
  doAction,
  plusAction,
} from 'module-reaction';
import React from 'react';
import { RouteComponentProps } from 'react-router';

const MODULE_PRODUCT = 'MODULE_PRODUCT';
const MODULE_PENDING_PRODUCT = 'MODULE_PENDING_PRODUCT';
const MODULE_DISCONTINUED_PRODUCT = 'MODULE_DISCONTINUED_PRODUCT';
const MODULE_PRODUCT_REPORTS = 'MODULE_PRODUCT_REPORTS';
const MODULE_PAGE_LOADING = 'MODULE_PAGE_LOADING';
interface Cannabinoid {
  THC?: number; // unit: mg
  CBD?: number; // unit: same as above
  THCa?: number;
  THCv?: number;
  CBDa?: number;
  CBDv?: number;
  CBN?: number;
  CBG?: number;
  CBC?: number;
  CBCv?: number;
  Delta8?: number;
  Delta10?: number;
  HHC?: number;
  THCp?: number;
  THCo?: number;
  HXC?: number;
  HXCp?: number;
  CBT?: number;
}
export const cannabinoid_enums = [
  'THC', // unit: mg
  'CBD', // unit: same as above
  'THCa',
  'THCv',
  'CBDa',
  'CBDv',
  'CBN',
  'CBG',
  'CBC',
  'CBCv',
  'Delta8',
  'Delta10',
  'HHC',
  'THCp',
  'THCo',
  'HXC',
  'HXCp',
  'CBT',
];

export interface LabResult {
  name: string;
  type: 'cannabinoid' | 'terpene';
  min: null | number;
  max: null | number;
}

export interface Product extends Cannabinoid {
  id: string;
  inner_id: string;
  covers: Doc[];
  name: string;
  introduction: string;
  brand: string;
  brand_name: string;
  flavor: string;
  product_type: {
    type: string;
    specific: string;
    serving_size: {
      name: string;
      servingType: string; // Puff, Patches, mg ...
    };
    search_key: string; // keep this filed to help filter by regex
  };
  cannabinoid: string[];
  // strain type There are 3 types of strain: Indica & Sativa & hybrid
  strain_type: string;
  strain: string;
  // TERPENES
  terpenes: string[];
  ex_store_url: string; // when have no stores in our db, client can buy product via this url
  legal_states: string[]; // States of America which can sell cannabis
  status: string; // where this product is active or discontinued
  note: string;
  last_updated_by: string; // user who last saved
  _id: string;
  user?: User;
  submitted_at: Date;
  'Delta-8'?: string;
  completed_at: Date;
  updated_at?: Date;
  created_at?: Date;
  dosage: DoseObject;
  edit?: string;
  dose_required?: boolean;
  reviewRequired: boolean;
  labResults?: LabResult[];
}

export interface ProductListProp extends ModelProduct, RouteComponentProps {
  list: Product[]; // current result for show
  total: number; // total mat
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 1, when fetch, skip=pageSize * pageIdx
  pageLoading: boolean; //show loading until next page is loaded
}

export interface PendingProduct {
  id: string;
  inner_id: string;
  covers: Doc[];
  name: string;
  introduction: string;
  brand: string;
  brand_name: string;
  flavor: string;
  product_type: {
    type: string;
    specific: string;
    serving_size: {
      name: string;
      servingType: string; // Puff, Patches, mg ...
    };
    search_key: string; // keep this filed to help filter by regex
  };
  cannabinoid: string[];
  // strain type There are 3 types of strain: Indica & Sativa & hybrid
  strain_type: string;
  strain: string;
  // TERPENES
  terpenes: string[];
  ex_store_url: string; // when have no stores in our db, client can buy product via this url
  legal_states: string[]; // States of America which can sell cannabis
  status: string; // where this product is active or discontinued
  user?: User;
  submitted_at: Date;
  updated_at?: Date;
  completed_at: Date;
  note: string;
  dose_required?: boolean;
  dosage?: any;
  last_updated_by: string; // user who last saved
  reviewRequired?: boolean;
}

interface DoseObject {
  value: number;
  unit: string;
}

export interface ProductReportStat {
  id: string;
  reporter: {
    username: string;
    role: string;
    email: string;
  };
  update_at: string;
  created_at: string;
  goal_score: KV;
}
interface ModelPendingProduct extends ModuleStore {
  list: PendingProduct[]; // current result for show
  total: number; // total num of current search
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  loading: boolean;
  hasMore: boolean;
}

interface ModelPageLoading extends ModuleStore {
  pageLoading: boolean;
  filterLoading: boolean;
}

interface ModelDiscontinuedProduct extends ModuleStore {
  list: Product[]; // current result for show
  total: number; // total num of current search
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  loading: boolean;
  hasMore: boolean;
}

export interface ModelProduct extends ModuleStore {
  list: Product[]; // current result for show
  total: number; // total num of current search
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  lastUploadCsvStatus: {
    status: string; // processing/success/error:msg
    icon: React.ReactNode;
  };
  loading: boolean;
  hasMore: boolean;
}
export interface ModelProductReports extends ModuleStore {
  // same as ModelProduct
  list: ProductReportStat[];
  total: number; // total num of current search
  searchKey: string; // search keyword
  dates: string[]; // ['yyyy-mm-dd', 'yyyy-mm-dd']
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
}
export const model_product: ModelProduct = {
  module: MODULE_PRODUCT,
  list: [],
  total: 0,
  searchKey: '',
  pageSize: 20,
  pageIdx: 1,
  lastUploadCsvStatus: {
    status: 'success',
    icon: statusIconSuccess,
  },
  loading: true,
  hasMore: true,
};

export const model_page_loading: ModelPageLoading = {
  module: MODULE_PAGE_LOADING,
  pageLoading: false,
  filterLoading: false,
};

export const model_pending_product: ModelPendingProduct = {
  module: MODULE_PENDING_PRODUCT,
  list: [],
  total: 0,
  searchKey: '',
  pageSize: 20,
  pageIdx: 1,
  loading: true,
  hasMore: true,
};
export const model_discontinued_product: ModelDiscontinuedProduct = {
  module: MODULE_DISCONTINUED_PRODUCT,
  list: [],
  total: 0,
  searchKey: '',
  pageSize: 20,
  pageIdx: 1,
  loading: true,
  hasMore: true,
};
export const model_product_reports: ModelProductReports = {
  module: MODULE_PRODUCT_REPORTS,
  list: [],
  total: 0,
  dates: [],
  searchKey: '',
  pageSize: 20,
  pageIdx: 1,
};
let option_enums: KV;

export async function getProductDetail(id: string) {
  let res: Product | undefined;
  const list = getModuleProp(MODULE_PRODUCT, 'list') as Product[];
  if (list?.length) {
    res = list.find((_) => _.id === id);
  }
  if (!res) {
    res = await Net.req(`/product/detail/${id}`, {
      include_stores: false,
      include_ratings: false,
      include_side_effects: false,
      scope_switch: false,
    });
  }
  // fetch reports question options
  if (!option_enums) {
    const res2 = await Net.req('/option/all');
    option_enums = res2.options;
  }
  return res;
}

export function getOptionEnum(name: string) {
  return option_enums[name] as KV;
}

export const FetchProductsAction: ModuleAction<KV, ModelProduct> = {
  module: MODULE_PRODUCT,
  name: 'FetchProductsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelProduct) => {
    const {
      searchKey,
      pageSize,
      pageIdx,
      type,
      specific,
      brand_name,
      states,
      store_url,
      sort_by,
      order,
    } = Object.assign({}, model, payload);
    const res = await Net.req(
      '/product/admin-search',
      {
        search_key: searchKey,
        type,
        specific,
        brand_name,
        states,
        store_url,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      filterLoading: false,
      pageLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: res.list,
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

export const EmptyProductsAction: ModuleAction<KV, ModelProduct> = {
  module: MODULE_PRODUCT,
  name: 'EmptyProductsAction',
  maxProcessSeconds: 30,
  process: async () => ({
    searchKey: '',
    type: '',
    specific: '',
    brand_name: '',
    states: '',
    store_url: '',
    list: [],
    total: 0,
    loading: true,
    pageIdx: 1,
    hasMore: true,
  }),
};

export const FetchMoreProductsAction: ModuleAction<KV, ModelProduct> = {
  module: MODULE_PRODUCT,
  name: 'FetchMoreProductsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelProduct) => {
    const {
      searchKey,
      pageSize,
      pageIdx,
      type,
      specific,
      brand_name,
      states,
      store_url,
      sort_by,
      order,
    } = Object.assign({}, model, payload);
    doAction(PageLoadingAction, { pageLoading: true });

    const res = await Net.req(
      '/product/admin-search',
      {
        search_key: searchKey,
        type,
        specific,
        brand_name,
        states,
        store_url,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
      filterLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: [...model.list, ...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
      };
    }
    message.info('No results');
    return { list: [...model.list], total: 0, hasMore: false };
  },
};

export const PageLoadingAction: ModuleAction<KV, ModelPageLoading> = {
  module: MODULE_PAGE_LOADING,
  name: 'PageLoadingAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelPageLoading) => {
    const { pageLoading, filterLoading } = Object.assign({}, model, payload);
    return {
      pageLoading,
      filterLoading,
    };
  },
};

export const FetchDiscontinuedProductsAction: ModuleAction<KV, ModelDiscontinuedProduct> = {
  module: MODULE_DISCONTINUED_PRODUCT,
  name: 'FetchDiscontinuedProductsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelDiscontinuedProduct) => {
    const {
      searchKey,
      pageSize,
      pageIdx,
      type,
      specific,
      brand_name,
      states,
      store_url,
      sort_by,
      order,
    } = Object.assign({}, model, payload);
    const res = await Net.req(
      '/product/admin-search',
      {
        search_key: searchKey,
        type,
        specific,
        brand_name,
        states,
        store_url,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        status: 'discontinued',
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      filterLoading: false,
      pageLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: res.list,
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

export const EmptyDiscontinuedProductsAction: ModuleAction<KV, ModelDiscontinuedProduct> = {
  module: MODULE_DISCONTINUED_PRODUCT,
  name: 'EmptyDiscontinuedProductsAction',
  maxProcessSeconds: 30,
  process: async () => ({
    searchKey: '',
    list: [],
    total: 0,
    loading: true,
    pageIdx: 1,
    hasMore: true,
  }),
};

export const FetchMoreDiscontinuedProductsAction: ModuleAction<KV, ModelDiscontinuedProduct> = {
  module: MODULE_DISCONTINUED_PRODUCT,
  name: 'FetchMoreDiscontinuedProductsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelDiscontinuedProduct) => {
    const {
      searchKey,
      pageSize,
      pageIdx,
      type,
      specific,
      brand_name,
      states,
      store_url,
      sort_by,
      order,
    } = Object.assign({}, model, payload);
    doAction(PageLoadingAction, { pageLoading: true });

    const res = await Net.req(
      '/product/admin-search',
      {
        search_key: searchKey,
        type,
        specific,
        brand_name,
        states,
        store_url,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        status: 'discontinued',
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
      filterLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: [...model.list, ...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
      };
    }
    message.info('No results');
    return { list: [...model.list], total: 0, hasMore: false };
  },
};

export const FetchPendingProductsAction: ModuleAction<KV, ModelPendingProduct> = {
  module: MODULE_PENDING_PRODUCT,
  name: 'FetchPendingProductsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelPendingProduct) => {
    const {
      searchKey,
      pageSize,
      pageIdx,
      type,
      specific,
      brand_name,
      states,
      store_url,
      sort_by,
      order,
    } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/product/pending/admin-search',
      {
        search_key: searchKey,
        type,
        specific,
        brand_name,
        states,
        store_url,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, { filterLoading: false });
    if (res.list && res.list.length) {
      return {
        list: [...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return {
      list: [],
      total: 0,
      loading: false,
      hasMore: false,
    };
  },
};

export const EmptyPendingProductsAction: ModuleAction<KV, ModelPendingProduct> = {
  module: MODULE_PENDING_PRODUCT,
  name: 'EmptyPendingProductsAction',
  maxProcessSeconds: 30,
  process: async () => ({
    searchKey: '',
    type: '',
    specific: '',
    brand_name: '',
    states: '',
    store_url: '',
    list: [],
    total: 0,
    loading: true,
    pageIdx: 1,
    hasMore: true,
  }),
};

export const FetchMorePendingProductsAction: ModuleAction<KV, ModelPendingProduct> = {
  module: MODULE_PENDING_PRODUCT,
  name: 'FetchMorePendingProductsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelPendingProduct) => {
    const { searchKey, pageSize, pageIdx, sort_by, order } = Object.assign({}, model, payload);
    doAction(PageLoadingAction, { pageLoading: true });

    const res = await Net.req(
      '/product/pending/admin-search',
      {
        search_key: searchKey,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
      filterLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: [...model.list, ...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
      };
    }
    message.info('No results');
    return { list: [...model.list], total: 0, hasMore: false };
  },
};

export const PollingUploadProductStatusAction: ModuleAction<string, ModelProduct> = {
  module: MODULE_PRODUCT,
  name: 'PollingUploadProductsStatusAction',
  maxProcessSeconds: 30,
  process: async (record: string, model: ModelProduct) => {
    const res = await Net.req('/product/admin-upload-csv-status', { record }, 'post');
    if (res?.status) {
      if (res.status === 'success') {
        // change status and stop polling
        if (record) {
          // if has record, means the upload acted by current user
          // so toast a msg when status change
          message.info('last upload csv success');
        }
        // refressh list
        plusAction(FetchProductsAction);

        return {
          lastUploadCsvStatus: {
            status: res.status,
            icon: statusIconSuccess,
          },
        };
      } else if (res.status === 'processing') {
        // make another polling 1 min later
        setTimeout(() => {
          doAction(PollingUploadProductStatusAction, record);
        }, 20 * 1000);
        return {
          lastUploadCsvStatus: {
            status: res.status,
            icon: statusIconProcessing,
          },
        };
      }
      if (record) {
        // if has record, means the upload acted by current user
        // so toast a msg when status change
        message.error('last upload csv error');
      }
      // refressh list
      plusAction(FetchProductsAction);

      return {
        lastUploadCsvStatus: {
          status: res.status,
          icon: statusIconFail,
        },
      };
    }
    return {};
  },
};

export const FetchProductReportsAction: ModuleAction<KV, ModelProductReports> = {
  module: MODULE_PRODUCT_REPORTS,
  name: 'FetchProductReportsAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelProductReports) => {
    const { product, searchKey, pageSize, pageIdx, dates } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/report/admin-search',
      {
        product,
        dates,
        search_key: searchKey,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
      },
      'post'
    );
    if (res.list && res.list.length) {
      return {
        list: res.list,
        total: res.total,
        dates,
        searchKey,
        pageSize,
        pageIdx,
      };
    }
    message.info('No results');
    return { list: [], total: 0 };
  },
};
