/* eslint-disable no-async-promise-executor */
import axios from '../../apiClient';
import moment from 'moment';

export const verifyBrandLockAPI = (brandId: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const { id: userId } = JSON.parse(localStorage.getItem('userDetails') as string);
      const response = await axios.post(`/brand/detail/${brandId}`, {
        include_product: true,
        include_store: false,
        now: null,
      });
      if (response.data.lockTime === null || response.data.lastEditorId === null) {
        await axios.put(`/brand/lock-edit-brand/${brandId}`);
        resolve(response);
      } else if (
        moment(response.data.lockTime).add(10, 'minutes').isAfter(moment()) &&
        response.data.lastEditorId !== userId
      ) {
        reject('FAILED');
      } else {
        await axios.put(`/brand/lock-edit-brand/${brandId}`);
        resolve(response);
      }
    } catch (error) {
      reject('FAILED');
    }
  });
