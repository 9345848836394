import Loader from '../../../components/table/content-loader';
import ProductsFilter from '../../../components/table/filter';
import PaginationComponent from '../../../components/table/pagination';
import ReactTable from '../../../components/table/table';
import {
  model_page_loading,
  model_product,
  FetchOrganizationAction,
  Organization,
} from '../../../models/model_organization_paginated';
import OrganizationEditModal from '../organization-edit-modal/organization-edit-modal';
import { OrganizationModal } from '../organization_modal/organization_modal';
import { Button, notification } from 'antd';
import { doAction, mapProp } from 'module-reaction';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
interface TableWrapperProps {
  list: object[];
  total: number;
  pageLoading: boolean;
  columns: boolean;
}

const TableWrapper = ({ list, total, pageLoading }: TableWrapperProps & object) => {
  const [selectedProduct, setSelectedProduct] = useState<Organization | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<object>({});
  const [multiSelectType, setMultiSelectType] = useState<'none' | 'whole' | 'multi'>('none');
  const [isColumnConfigModalShown, setIsColumnConfigModalShown] = useState(false);

  const onSuccess = useCallback(() => {
    doAction(FetchOrganizationAction, {
      ...currentFilter,
      pageSize: pageSize,
      pageIdx: currentPage,
    });
  }, [pageSize, currentPage, currentFilter]);

  useEffect(() => {
    setLoading(true);
    const timer1 = setTimeout(() => setLoading(false), 1000);
    const timer2 = setTimeout(() => onSuccess(), 200);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [onSuccess]);

  const columns = useMemo<Column<Organization>[]>(
    () =>
      [
        {
          Header: 'Name',
          width: 200,
          accessor: 'name',
          Cell: ({ value, row: { original } }: CellProps<object & { id: string }>) => (
            <Link to={'organization/' + original.id}>{value || ''}</Link>
          ),
        },
        {
          Header: 'Owner',
          width: 250,
          accessor: (row: Organization) => row,
          Cell: ({ value }: { value: Organization }) => (
            <div>
              <div>{value.permissions?.map((permission) => permission.userName).join(', ')}</div>
              {!!value.pendingInvites?.length && (
                <div>
                  <div style={{ color: 'darkorange' }}>Pending invites:</div>
                  {value.pendingInvites.join(', ')}
                </div>
              )}
            </div>
          ),
        },
        {
          Header: 'Street Address',
          width: 200,
          accessor: 'address',
          Cell: ({ value }: CellProps<object>) => value || '',
        },
        {
          Header: 'City',
          width: 200,
          accessor: 'city',
          Cell: ({ value }: CellProps<object>) => value || '',
        },
        {
          Header: 'State',
          width: 200,
          accessor: 'state',
          Cell: ({ value }: CellProps<object>) => value || '',
        },
        {
          Header: 'Zip',
          width: 200,
          accessor: 'zip',
          Cell: ({ value }: CellProps<object>) => value || '',
        },
        {
          Header: 'Url',
          width: 200,
          accessor: 'retailOrganizationWebsite',
          Cell: ({ value }: CellProps<object>) => (
            <p style={{ overflowWrap: 'anywhere' }}>{value || ''}</p>
          ),
        },
        {
          Header: 'Email',
          width: 200,
          accessor: 'email',
          Cell: ({ value }: CellProps<object>) => (
            <p style={{ overflowWrap: 'anywhere' }}>{value || ''}</p>
          ),
        },
        {
          Header: 'Online Store',
          width: 200,
          accessor: 'isOnlineStore',
          Cell: ({ value }: CellProps<object>) => (value ? 'Y' : 'N' || ''),
        },
        {
          Header: 'Number of Locations',
          width: 200,
          accessor: 'numberOfRetailLocation',
          Cell: ({ value }: CellProps<object>) => value || '',
        },
        {
          Header: 'ID',
          accessor: 'id',
          id: '_id',
          Cell: ({ value }: CellProps<object>) => <div className="break-word">{value || ''}</div>,
        },
      ] as Column<Organization>[],
    []
  );

  return !!list && !!columns ? (
    <div className="tableAndFilterWrapper">
      <div className="search-con">
        <ProductsFilter
          onFilter={(res: object) => {
            setCurrentFilter(res);
          }}
          activateBulkActions={() => {
            if (multiSelectType === 'none')
              return notification.warning({
                message: 'Please select 1 or more items.',
              });
          }}
          currentFilter={currentFilter}
          setCurrentPage={setCurrentPage}
          isBulkNeeded={false}
        >
          <div className="add-product-button">
            <Button
              onClick={() => setShowCreateModal(true)}
              type="primary"
              className="add-product-text"
            >
              + Add Organization
            </Button>
          </div>
        </ProductsFilter>
      </div>

      <div className="tableWrapper">
        {(loading || pageLoading) && (
          <div className="loaderCover">
            <Loader />
          </div>
        )}
        <ReactTable
          data={list}
          columns={columns}
          selectedItem={selectedProduct}
          isEditModalShown={false}
          setMultiSelectType={setMultiSelectType}
          setSelectedItens={() => true}
          setIsColumnConfigModalShown={setIsColumnConfigModalShown}
          isColumnConfigModalShown={isColumnConfigModalShown}
          editModal={OrganizationEditModal}
        />
        <br />
        <PaginationComponent
          totalRows={total}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={pageSize}
          setPageSize={setPageSize}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setIsColumnConfigModalShown={setIsColumnConfigModalShown}
        />
        <br />
        <OrganizationModal
          visible={showCreateModal}
          action={'new'}
          selectedOrganization={selectedProduct}
          hide={() => {
            setShowCreateModal(false);
            setSelectedProduct(undefined);
          }}
          onSuccess={() => onSuccess()}
        />
      </div>
    </div>
  ) : null;
};

export default mapProp(
  model_page_loading,
  'pageLoading',
  'filterLoading'
)(mapProp(model_product)(TableWrapper));
