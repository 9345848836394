import RetailUserFilter from './retailUser_filter/retailUser_filter';
import downArrow from '../../assets/down_arrow.svg';
import {
  ModleRetailUser,
  modleRetailUser,
  FetchRetailUsersAction,
  FetchMoreRetailUsersAction,
  RetailUser,
  LoadingRetailUsersAction,
} from '../../models/model_retail_user';
import { Net } from '../../utils/utils';
import { Button, List, Modal, Avatar, Dropdown, Menu, Spin, notification, Typography } from 'antd';
import { mapProp, doAction } from 'module-reaction';
import React from 'react';
import './retailUser_list.less';
import RetailUserAddModal from './retailUser_add_modal/RetailUserAddModal';

interface RetailUserListProp extends ModleRetailUser {
  submitUser?: (selectedIds: Set<unknown>, list?: RetailUser[]) => void;
  from?: string;
}

@mapProp(modleRetailUser)
export class RetailUserListView extends React.Component<RetailUserListProp> {
  state = {
    selectedIds: new Set(),
    showAddModal: false,
    banModalLoading: false,
    activeSort: '',
    sortedArray: false,
    sortedRetailUsersList: [],
    sortActive: false,
    deleteModalLoading: false,
    isDeleteModalShown: false,
    selectedRetailUser: {} as RetailUser,
    selectedFilter: {
      search_key: '',
    },
  };

  componentDidUpdate(prevProps: RetailUserListProp) {
    if (prevProps.tabItem !== this.props.tabItem) {
      this.onTabChange(this.props.tabItem);
    }
  }

  render() {
    const { tabItem, loading, from } = this.props;
    const { showAddModal, isDeleteModalShown, deleteModalLoading } = this.state;

    const curTab = tabItem;

    return (
      <div className={'list-view retail-user-list-view'}>
        <div className="info-con">
          <RetailUserFilter onSubmitFilter={this.submitFilter} curTab={curTab}>
            <Button
              className="add-retailuser-action"
              type="primary"
              onClick={() => this.setState({ showAddModal: true })}
            >
              + Add Retail User Access
            </Button>
          </RetailUserFilter>
        </div>
        {loading && (
          <div className="pending-loading">
            <Spin size="large" />
          </div>
        )}
        {!loading && this.getListCon(curTab)}
        <RetailUserAddModal
          visible={showAddModal}
          onRequestClose={() => {
            this.setState({ showAddModal: false });
          }}
        />
        <Modal
          visible={isDeleteModalShown}
          title={'Remove Retail User Access'}
          onOk={this.deleteRetailUser}
          onCancel={this.hideDeleteModal}
          confirmLoading={deleteModalLoading}
        >
          <p>
            <span>Are you sure you want to remove access for this retail user as an </span>
            <span>
              <b>{this.state.selectedRetailUser.permission}</b> {'of the '}
            </span>
            {this.state.selectedRetailUser.retailOrganization?.id ? (
              <span>
                organization: <b>{this.state.selectedRetailUser.retailOrganization?.name}</b>
              </span>
            ) : (
              <span>
                location: <b>{this.state.selectedRetailUser.retailLocation?.name}</b>
              </span>
            )}
            {' ?'}
          </p>
        </Modal>
      </div>
    );
  }

  private hideDeleteModal = () => {
    this.setState({
      isDeleteModalShown: false,
      selectedUser: {},
    });
  };

  private deleteRetailUser = async () => {
    const { selectedRetailUser } = this.state;

    this.setState({ deleteModalLoading: true });

    try {
      const res = await Net.req(`/org/v1/retail-users/${selectedRetailUser.id}`, {}, 'delete');
      this.setState({
        deleteModalLoading: false,
        isDeleteModalShown: false,
      });
      this.onTabChange(this.props.tabItem);
      if (res?.status) {
        if (res.status === 'success') {
          notification['success']({
            message: 'Success!',
          });
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
    } catch (e) {
      this.setState({
        deleteModalLoading: false,
        isDeleteModalShown: false,
      });
      notification['error']({
        message: 'Something went wrong!',
      });
    }
  };

  private submitFilter = (
    filterData: {
      search_key?: string;
      retail_organization_id?: string;
      retail_location_id?: string;
    } = {}
  ) => {
    this.setState(
      { selectedFilter: { ...this.state.selectedFilter, ...filterData } },
      this.performSearch
    );
  };

  private performSearch = () => {
    const { selectedIds, selectedFilter } = this.state;
    console.log('///////', selectedFilter);
    selectedIds.clear();
    doAction(LoadingRetailUsersAction);
    doAction(FetchRetailUsersAction, {
      pageIdx: 1,
      ...selectedFilter,
    });
  };

  private showDeleteModal = (item: RetailUser) => (e: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      isDeleteModalShown: true,
      selectedRetailUser: item,
    });
  };

  private sortItems = (sortItem: string) => {
    if (this.state.activeSort === sortItem) {
      this.setState(
        {
          activeSort: '',
          sortActive: false,
        },
        () => this.processSort(sortItem)
      );
    } else {
      this.setState(
        {
          activeSort: sortItem,
          sortActive: true,
        },
        () => this.processSort(sortItem)
      );
    }
  };

  private processSort = (sortItem: string) => {
    const arrayItem: Record<string, never>[] = JSON.parse(JSON.stringify(this.props.list));
    const sortArray = arrayItem.sort((a, b) => {
      if (a[sortItem] > b[sortItem]) {
        return 1;
      }
      return -1;
    });
    this.setState({
      sortedRetailUsersList: [...sortArray],
    });
  };

  renderDropdownOverlay = (item: RetailUser) => (
    <Menu>
      <Menu.Item key="remove">
        <Button type="link" onClick={this.showDeleteModal(item)}>
          Remove
        </Button>
      </Menu.Item>
    </Menu>
  );

  loadMoreBrands = () => {
    const { pageSize, pageIdx, hasMore, pageLoading } = this.props;
    const retailUserListCon = document.getElementById('retail-user-list-con');
    if (
      retailUserListCon &&
      retailUserListCon.scrollHeight -
        retailUserListCon.scrollTop -
        retailUserListCon.offsetHeight <
        40 &&
      !pageLoading &&
      hasMore
    ) {
      this.onPageChange((pageIdx || 0) + 1, pageSize);
    }
  };

  private getListCon(curTab: string) {
    const { list, hasMore, from } = this.props;
    const { selectedIds, activeSort, sortActive, sortedRetailUsersList } = this.state;
    return (
      <div className="list-con" id="retail-user-list-con" onScroll={this.loadMoreBrands}>
        <List
          className="retail-user-list"
          dataSource={sortActive ? sortedRetailUsersList : list}
          header={
            <>
              <span className="span-3" onClick={() => this.sortItems('email')}>
                EMAIL
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'email' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-1">AVATAR</span>
              <span className="span-2">
                {curTab === 'organization' ? 'ORGANIZATION' : 'LOCATION'}
              </span>
              <span className="span-2">PERMISSION PROFILE</span>
              <span className="span-2">ACTION</span>
            </>
          }
          renderItem={(item: RetailUser) => (
            <List.Item key={`${item.id}`}>
              <div className="item-content">
                <span className="span-3 ellipse">{item.email}</span>
                <span className="span-1 avatar">
                  <Avatar
                    style={{
                      backgroundColor: '#5344ffff',
                    }}
                    src={
                      item.avatar?.link ? (
                        <img className="avatar-image" alt="avatar-item" src={item.avatar?.link} />
                      ) : (
                        <Typography.Title
                          style={{
                            color: '#ffffff',
                            lineHeight: '60px',
                          }}
                          level={4}
                        >
                          {item.email && item.email[0].toUpperCase()}
                        </Typography.Title>
                      )
                    }
                    size={60}
                  />
                </span>
                <span className="span-2">
                  {curTab === 'organization'
                    ? item.retailOrganization.name
                    : item.retailLocation.name}
                </span>
                <span className="span-2">{item.permission || ''}</span>
                <span className="span-2">
                  <Dropdown
                    className="header-avatar-drop"
                    overlay={this.renderDropdownOverlay(item)}
                  >
                    <div className="header-logo-parent">Select</div>
                  </Dropdown>
                </span>
              </div>
            </List.Item>
          )}
        />
        {hasMore && (
          <div className="loadmore">
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    if (!this.props.list?.length) {
      this.onTabChange(this.props.tabItem);
    }
  }

  private onTabChange = (curTab: string) => {
    const { selectedIds } = this.state;
    selectedIds.clear();
    doAction(LoadingRetailUsersAction);
    doAction(FetchRetailUsersAction, { pageIdx: 1, search_key: '', scope: curTab });
  };

  private onPageChange = (pageIdx?: number, pageSize?: number) => {
    doAction(FetchMoreRetailUsersAction, { pageIdx, pageSize, ...this.state.selectedFilter });
  };
}
