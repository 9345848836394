import FieldInfo from './FieldInfo';
import { Button, Image, Empty } from 'antd';
import React from 'react';

const MappedProductInfo = ({
  onUnmatch,
  mappedProduct,
}: {
  onUnmatch: () => void;
  mappedProduct?: any;
}) => {
  if (!mappedProduct) return <Empty />;

  const covers = mappedProduct.covers;
  const { link } = covers[0];
  const { name, brand_name, product_type, strain, strain_type, flavor, labResults } = mappedProduct;
  const cannabinoids = labResults?.filter(
    (item: any) => item.type == 'cannabinoid' && item.retailLocationId === undefined
  );
  const cannabinoidDosage = cannabinoids?.map((item: any) => (
    <div style={{ paddingLeft: '20px' }} key={item._id}>
      {item.name} {item.min ? `: ${item.min}` : ''} {item.max > item.min ? ` - ${item.max}` : ''}
    </div>
  ));

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 4 }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '340px',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Image height={300} src={link} style={{ objectFit: 'contain' }} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="primary" onClick={onUnmatch}>
            UNMATCH
          </Button>
        </div>
      </div>
      <div style={{ flex: 4 }}>
        <FieldInfo label="Name" value={name} />
        <FieldInfo label="Brand" value={brand_name} />
        <FieldInfo label="Product type">
          {product_type.type || ''} /<br /> {product_type.specific || ''}
        </FieldInfo>
        <FieldInfo label="Strain Type" value={strain_type} />
        <FieldInfo label="Strain" value={strain} />
        <FieldInfo label="Flavor" value={flavor} />
        <FieldInfo label="Cannabinoid/Dosage" value={cannabinoidDosage} />
      </div>
    </div>
  );
};

export default MappedProductInfo;
