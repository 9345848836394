import { addLineBreakForCharacters } from '../../../utils/utils';
import { Image, Empty } from 'antd';
import React, { useMemo, useState, useEffect } from 'react';
import {
  usePagination,
  useRowSelect,
  useTable,
  useExpanded,
  useBlockLayout,
  useResizeColumns,
  CellProps,
} from 'react-table';
import '../../product/product_table/components/product-table.less';

const PossibleMatchesTable = ({
  selectedMatch,
  setSelectedMatch,
  possibleMatchingProducts,
  idField,
}: {
  selectedMatch: any;
  setSelectedMatch: any;
  possibleMatchingProducts?: any;
  idField: string;
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  const columns = useMemo<any[]>(
    () => [
      {
        Header: 'Match',
        id: 'action',
        width: 100,
        Cell: ({ row: { original } }: CellProps<any>) => {
          const selected = !!selectedMatch && selectedMatch[idField] === original[idField];
          return (
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selected ? (
                <div
                  onClick={() => setSelectedMatch(null)}
                  style={{
                    width: 34,
                    height: 34,
                    borderRadius: 8,
                    cursor: 'pointer',
                    border: '2px solid #5344ff',
                    background: '#5344ff',
                  }}
                />
              ) : (
                <div
                  onClick={() => {
                    setSelectedMatch(original);
                  }}
                  style={{
                    width: 34,
                    height: 34,
                    borderRadius: 8,
                    cursor: 'pointer',
                    border: '2px solid #5344ff',
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: 'IMAGE',
        accessor: 'covers',
        disableSortBy: true,
        width: 150,
        Cell: ({ value }: CellProps<any>) => {
          if (!value) return <div className="whiteSquare" />;
          const covers = value;
          const { link } = covers[0];
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Image src={link} alt="product-list" style={{ objectFit: 'contain' }} height={150} />
            </div>
          );
        },
      },
      {
        Header: 'NAME',
        width: 200,
        accessor: 'name',
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'BRAND',
        width: 150,
        accessor: 'brand_name',
        disableSortBy: true,
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'TYPE',
        width: 150,
        accessor: 'product_type',
        disableSortBy: true,
        id: 'product_type.type',
        Cell: ({ value }: CellProps<any>) => {
          if (!value) return '';
          const { type, specific } = value;
          return (
            <span>
              {type || ''} /<br /> {specific || ''}
            </span>
          );
        },
      },
      {
        Header: 'METADATA',
        width: 200,
        id: 'metadata',
        Cell: ({ row: { original } }: CellProps<any>) => {
          const { flavor, cannabinoid, strain, strain_type, labResults } = original;
          const _flavor = flavor ? addLineBreakForCharacters(flavor) : '';
          const cannabinoids = labResults?.filter(
            (item: any) => item.type == 'cannabinoid' && item.retailLocationId === undefined
          );
          const cannabinoidDosage = cannabinoids?.map((item: any) => (
            <div style={{ paddingLeft: '20px' }} key={item._id}>
              {item.name} {item.min ? `: ${item.min}` : ''}{' '}
              {item.max > item.min ? ` - ${item.max}` : ''}
            </div>
          ));

          return (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ marginRight: 10, fontWeight: 600 }}>Strain Type:</div>
                <div>{strain_type}</div>
              </div>
              <p style={{ marginBottom: 0 }}>
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Strain:
                </span>{' '}
                {strain}
              </p>
              <p style={{ marginBottom: 0 }}>
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Flavor:
                </span>{' '}
                {_flavor}
              </p>
              <p style={{ marginBottom: 0 }}>
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Cannabinoid/Dosage:
                </span>{' '}
                {cannabinoidDosage}
              </p>
            </div>
          );
        },
      },
      {
        Header: 'ID',
        accessor: idField,
        Cell: ({ value }: CellProps<any>) => <div className="break-word">{value || ''}</div>,
      },
    ],
    [selectedMatch]
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, visibleColumns } =
    useTable(
      {
        columns,
        data: possibleMatchingProducts,
        manualPagination: true,
        defaultColumn,
      },
      useBlockLayout,
      useResizeColumns,
      useExpanded,
      usePagination,
      useRowSelect
    );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: '10px',
        }}
      >
        <code>{JSON.stringify({ values: row.values }, null, 2)}</code>
      </pre>
    ),
    []
  );

  if (loading) return <div />;

  if (!(possibleMatchingProducts && possibleMatchingProducts.length)) return <Empty />;

  return (
    <>
      <table {...getTableProps()}>
        <thead className="sticky">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th className="colunmHeader" {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}

                  {!['selection', 'action'].includes(column.id) && (
                    <>
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                      />
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, index) => {
            const { original } = row;
            const selected = !!selectedMatch && selectedMatch[idField] === original[idField];
            prepareRow(row);
            return (
              <React.Fragment key={index}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any, index: number) => (
                    <td
                      {...cell.getCellProps([
                        ...(selected
                          ? [
                              {
                                style: {
                                  background: '#f1f0ff',
                                },
                              },
                            ]
                          : []),
                      ])}
                      key={index}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default PossibleMatchesTable;
