import BulkEditProduct from './bulk_edit_product';
import AssociateProductsModal from './components/AssociateProductsModal';
import PaginationComponent from './components/pagination-component';
import ProductsFilter, { FilterProductBody } from './components/product-filter';
import ProductTable from './components/product-table';
import uploadLogo from '../../../assets/upload-logo.svg';
import confirmDialog from '../../../components/confirm/confirm-modal';
import { MODULE_DIALOG, close_dialog } from '../../../models/model_dialog';
import { PollingUploadProductStatusAction } from '../../../models/model_product';
import {
  model_page_loading,
  previous_search,
  model_product,
  Product,
  ProductListProp,
  ProductFilter as ProductFilterProp,
} from '../../../models/model_products_paginated';
import {
  bulkReactivateProductAPI,
  bulkReactivateProductPaginatedAPI,
} from '../../../services/api/productAPI/bulkReactivateProductAPI';
import { paginatedProducts } from '../../../services/api/productAPI/generalSearchProducts';
import {
  moveProductToHomegrown,
  paginatedMoveProductsToHomegrown,
} from '../../../services/api/productAPI/moveProductsToHomeGrown';
import { verifyProductLockAPI } from '../../../services/api/verifyLockAPI/verifyProductLock';
import { ILabResults, ILabResultsInfo } from '../../../types/products';
import { routes } from '../../../utils/enum';
import { addLineBreakForCharacters, encodeQuery, Net, uploadFile } from '../../../utils/utils';
import ProductEditForm from '../product-edit-form/product-edit-form';
import { MenuOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Checkbox,
  Dropdown,
  Menu,
  Button,
  notification,
  Modal,
  Spin,
  Tooltip,
  message,
} from 'antd';
import { format, isValid } from 'date-fns';
import { doAction, getModuleProp, mapProp } from 'module-reaction';
import React, { useMemo, useState, useEffect, useCallback, Component } from 'react';
import { CellProps } from 'react-table';

const callError = () =>
  notification['error']({
    message: 'Something went wrong!',
  });

export const formatQuery = (query: any) => {
  const {
    search_key: searchKey,
    product_type: productType,
    productSpecific,
    brand_name: brandName,
    brand,
    states,
    store_url: storeUrl,
    last_updated_by: lastUpdatedBy,
    reviewRequired,
    collection,
  } = query;

  return {
    ...(!!searchKey && { search_key: searchKey }),
    ...(!!productType && { type: productType }),
    ...(!!productSpecific && { specific: productSpecific }),
    ...(!!brandName && { brand_name: brandName }),
    ...(!!brand && { brand }),
    ...(!!states && { states }),
    ...(!!storeUrl && { store_url: storeUrl }),
    ...(!!lastUpdatedBy && { last_updated_by: lastUpdatedBy }),
    ...(reviewRequired !== undefined && { reviewRequired }),
    ...(!!collection && {
      status: collection === 'approved' ? 'active' : collection,
    }),
  };
};

const formatDate = (date: Date | undefined) => {
  if (!!date && isValid(new Date(date))) {
    return (
      <>
        {format(new Date(date), 'MMM d yyyy')} <br /> {format(new Date(date), 'h:mm aaa')}
      </>
    );
  }
  return '';
};

const HeaderCheckbox = ({ getToggleAllRowsSelectedProps }: any) => (
  <div>
    <Checkbox {...getToggleAllRowsSelectedProps()} />
  </div>
);

const CellCheckbox = ({ row }: any) => (
  <div>
    <Checkbox {...row.getToggleRowSelectedProps()} />
  </div>
);

type Collection = 'pending' | 'approved' | 'discontinued';

type Props = {
  collection: Collection;
  newProduct?: boolean;
} & ProductListProp;

const TableWrapper = ({
  collection,
  lastUploadCsvStatus,
  history,
  search_key,
  brand,
  product_type,
  productSpecific,
  store_url,
  states,
  last_updated_by,
  reviewRequired,
  newProduct,
}: Props) => {
  const [lockLoadingItem, setLockLoadingItem] = useState<string | null>(null);
  const [isDeactivateModalShown, setIsDeactivateModalShown] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | false>(false);
  const [isEditProductModalShown, setIsEditProductModalShown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const [cannabinoidsList, setCannabinoidsList] = useState([]);
  const [isBulkDeactivateModalShown, setIsBulkDeactivateModalShown] = useState(false);
  const [deactivateModalLoading, setDeactivateModalLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectModalLoading, setRejectModalLoading] = useState(false);
  const [showAssociateModal, setShowAssociateModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [activateModalLoading, setActivateModalLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<ProductFilterProp>({});
  const [multiSelectType, setMultiSelectType] = useState<'none' | 'whole' | 'multi'>('none');
  const [isBulkEditModalShown, setIsBulkEditModalShown] = useState(false);
  const [isBulkRejectModalShown, setIsBulkRejectModalShown] = useState(false);
  const [bulkRejectModalLoading, setBulkRejectModalLoading] = useState(false);
  const [isBulkApproveModalShown, setIsBulkApproveModalShown] = useState(false);
  const [bulkApproveModalLoading, setBulkApproveModalLoading] = useState(false);
  const [showBulkActivateModal, setShowBulkActivateModal] = useState(false);
  const [sortByState, setSortByState] = useState([{ id: '', desc: true }]);
  const [isColumnConfigModalShown, setIsColumnConfigModalShown] = useState(false);

  const [showAddProduct, setShowAddProduct] = useState(newProduct ?? false);

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: [
      'paginatedProducts',
      {
        ...currentFilter,
        status: collection,
        pageSize: pageSize,
        pageIdx: currentPage,
        ...(!!sortByState.length && { sort_by: sortByState[0].id }),
        ...(!!sortByState[0] && {
          order: sortByState[0].desc ? 'desc' : 'asc',
        }),
      },
    ],
    queryFn: () =>
      paginatedProducts({
        ...currentFilter,
        status: collection,
        pageSize: pageSize,
        pageIdx: currentPage,
        ...(!!sortByState.length && { sort_by: sortByState[0].id }),
        ...(!!sortByState[0] && {
          order: sortByState[0].desc ? 'desc' : 'asc',
        }),
      }),
    onError: () => {
      notification.error({
        message: 'Something went wrong',
        description: 'Please try later',
      });
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    Net.req('/product/cannabinoids', {}, 'get').then((res) => {
      setCannabinoidsList(res);
    });
    setCurrentFilter({
      ...currentFilter,
      ...(!!search_key && { search_key }),
      ...(!!brand && { brand }),
      ...(!!product_type && { product_type }),
      ...(!!productSpecific && { productSpecific }),
      ...(!!states && { states }),
      ...(!!store_url && { store_url }),
      ...(!!last_updated_by && { last_updated_by }),
      ...([0, 1].includes(reviewRequired) && {
        reviewRequired,
      }),
    });
    //TODO fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Actions
  const showEditProductModal = (item: Product) => {
    setLockLoadingItem(item.id);
    verifyProductLockAPI(item.id, item.updated_at)
      .then((response: any) => {
        setIsEditProductModalShown(true);
        setSelectedProduct({
          ...item,
          cannabinoid: item.cannabinoid ? item.cannabinoid : [],
          reviewRequired: response.data.reviewRequired,
        });
        setLockLoadingItem(null);
      })
      .catch((_error) => {
        setLockLoadingItem(null);
        if (_error?.response?.data?.status === 409) {
          notification['error']({
            message: _error.response.data.message,
          });
        } else {
          notification['error']({
            message: 'This product is being edited by another user',
          });
        }
      });
  };

  const deactivateProduct = async () => {
    if (!selectedProduct) return callError();
    const { id } = selectedProduct;

    setDeactivateModalLoading(true);

    try {
      const res = await Net.req('/product/deactivate-product', { id_updates: [id] }, 'post');
      setDeactivateModalLoading(false);
      setIsDeactivateModalShown(false);
      setSelectedProduct(false);

      if (res?.status) {
        if (res.status === 'success') {
          queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
          notification['success']({
            message: 'Product discontinued successfully!',
          });
        } else {
          callError();
        }
      } else {
        callError();
      }
    } catch (e) {
      setDeactivateModalLoading(false);
      setIsDeactivateModalShown(false);
      setSelectedProduct(false);
      callError();
    }
  };

  const hideEditModal = useCallback(() => {
    setIsEditProductModalShown(false);
    setSelectedProduct(false);
  }, []);

  useEffect(() => () => hideEditModal(), [hideEditModal, collection]);

  const hideDeactivateModal = () => {
    setIsDeactivateModalShown(false);
    setIsBulkDeactivateModalShown(false);
  };

  const showDeactivateModal = (item: Product) => {
    setIsDeactivateModalShown(true);
    setSelectedProduct(item);
  };

  const bulkDeactivateModal = async () => {
    const closure = () => {
      setDeactivateModalLoading(false);
      setIsBulkDeactivateModalShown(false);
      setSelectedProducts([]);
    };

    try {
      if (multiSelectType === 'multi') {
        await confirmDialog(`${selectedProducts.length} Products will be affected, Are you sure?`);
        setDeactivateModalLoading(true);
        const idUpdates = selectedProducts.map((item: any) => item.id);
        const res = await Net.req('/product/deactivate-product', { id_updates: idUpdates }, 'post');
        if (res?.status) {
          if (res.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: res.updated + ' Products discontinued successfully!',
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      } else if (multiSelectType === 'whole') {
        await confirmDialog(
          `This action will be applied over the current search filter, ${data.total} Products will be affected, Are you sure?`
        );
        setDeactivateModalLoading(true);
        const url = encodeQuery(
          '/product/paginated-deactivate-product',
          formatQuery({
            ...currentFilter,
            collection,
          })
        );
        const res = await Net.req(url, {}, 'put');
        if (res?.status) {
          if (res.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: `${res?.updated} products discontinued successfully!`,
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      }
    } catch (e) {
      setDeactivateModalLoading(false);
      setIsBulkDeactivateModalShown(false);
      callError();
    }
  };

  const rejectProduct = async () => {
    if (!selectedProduct) return;
    const { id } = selectedProduct;

    setRejectModalLoading(true);

    try {
      const { data: res } = await moveProductToHomegrown([id]);
      setRejectModalLoading(false);
      setShowRejectModal(false);
      setSelectedProduct(false);
      if (res?.status) {
        if (res.status === 'success') {
          queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
          notification['success']({
            message: 'Product Sent To Homegrown!',
          });
        } else {
          callError();
        }
      } else {
        callError();
      }
    } catch (e) {
      setRejectModalLoading(false);
      setShowRejectModal(false);
      setSelectedProduct(false);
      callError();
    }
  };

  const activateProduct = async () => {
    if (!selectedProduct) return;
    const { id } = selectedProduct;

    setActivateModalLoading(true);

    let data = [];

    data = [id];

    bulkReactivateProductAPI([...data])
      .then((res) => {
        setSelectedProduct(false);
        setShowActivateModal(false);
        setActivateModalLoading(false);
        if (res?.data.status) {
          if (res.data.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: 'Activated Products successfully!',
            });
          } else {
            notification['error']({
              message: 'Something went wrong!',
            });
          }
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      })
      .catch((_error) => {
        setSelectedProduct(false);
        setShowActivateModal(false);
        setActivateModalLoading(false);
        notification['error']({
          message: 'Something went wrong!',
        });
      });
  };

  const bulkActivateProduct = async () => {
    const closure = () => {
      setShowBulkActivateModal(false);
    };

    try {
      if (multiSelectType === 'multi') {
        await confirmDialog(`${selectedProducts.length} Products will be affected, Are you sure?`);
        const data = selectedProducts.map((item: any) => item.id);
        const res = await bulkReactivateProductAPI([...data]);

        if (res?.data.status) {
          if (res.data.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: `Activated ${res.data.updated} Products successfully!`,
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      } else if (multiSelectType === 'whole') {
        await confirmDialog(
          `This action will be applied over the current search filter, ${data.total} Products will be affected, Are you sure?`
        );
        const res = await bulkReactivateProductPaginatedAPI(
          formatQuery({ ...currentFilter, collection })
        );
        if (res?.data.status) {
          if (res.data.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: `${res.data.updated} Activated Products successfully!`,
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      }
    } catch (error) {
      closure();
      callError();
    }
  };

  const bulkRejectProducts = async () => {
    const closure = () => {
      setBulkRejectModalLoading(false);
      setIsBulkRejectModalShown(false);
    };
    try {
      if (multiSelectType === 'multi') {
        await confirmDialog(`${selectedProducts.length} Products will be affected, Are you sure?`);
        setBulkRejectModalLoading(true);
        const { data: res } = await moveProductToHomegrown([
          ...selectedProducts.map((item: any) => item.id),
        ] as any);
        if (res?.status) {
          if (res.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: res.updated + ' Products Sent To Homegrown!',
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      } else if (multiSelectType === 'whole') {
        setBulkRejectModalLoading(true);
        await confirmDialog(
          `This action will be applied over the current search filter, ${data.total} Products will be affected, Are you sure?`
        );
        const { data: res } = await paginatedMoveProductsToHomegrown(
          formatQuery({ ...currentFilter, collection })
        );

        if (res?.status) {
          if (res.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: `${res.updated} Products Sent To Homegrown!`,
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      }
    } catch (e) {
      closure();
      callError();
    }
  };

  const bulkApproveProducts = async () => {
    const closure = () => {
      setBulkApproveModalLoading(false);
      setIsBulkApproveModalShown(false);
    };

    try {
      if (multiSelectType === 'multi') {
        await confirmDialog(`${selectedProducts.length} Products will be affected, Are you sure?`);
        setBulkApproveModalLoading(true);
        const data = selectedProducts.map((selectedProduct: any) => ({
          _id: selectedProduct.id,
        }));
        const res = await Net.req('/product/pending/approve-multiple-product', [...data], 'post');
        if (res?.status) {
          if (res.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: res.updated + ' products approved successfully!',
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      } else if (multiSelectType === 'whole') {
        await confirmDialog(
          `This action will be applied over the current search filter, ${data.total} Products will be affected, Are you sure?`
        );
        setBulkApproveModalLoading(true);
        const url = encodeQuery(
          '/product/pending/paginated-approve-multiple-product',
          formatQuery({
            ...currentFilter,
            collection,
          })
        );
        const res = await Net.req(url, {}, 'put');
        if (res?.status) {
          if (res.status === 'success') {
            queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
            notification['success']({
              message: `${res.updated} products approved successfully!`,
            });
          } else {
            callError();
          }
        } else {
          callError();
        }
        closure();
      }
    } catch (e) {
      callError();
    }
  };

  const goDetail = (id: string) => {
    history.push(routes['productDetail'].replace(':id', id));
  };

  const clickUpload = () => {
    if (lastUploadCsvStatus.status === 'processing') {
      message.warn('please wait for last upload csv to be finished');
      return;
    }
    doAction(MODULE_DIALOG, {
      visible: true,
      title: 'UPDATE PRODUCTS',
      text: `Warning:
            All products in db will be updated! and this operation can not be revert.
            process will stop when known errors occur, but it's in the risk of unknown errors.
            go ahead if you are very sure to do this.`,
      onOk: () =>
        uploadFile('csv')
          .then(async (file) => {
            doAction(MODULE_DIALOG, { showLoading: true });
            const res = await Net.req('/product/admin-upload-csv', { file }, 'multipart');
            if (res?.record) {
              doAction(PollingUploadProductStatusAction, res.record);
            }
          })
          .catch((_) => message.warn(_))
          .finally(close_dialog),
      onCancel: () => {
        if (getModuleProp(MODULE_DIALOG, 'showLoading')) {
          message.warn('waiting process');
        } else {
          close_dialog();
        }
      },
    });
  };

  const columns = useMemo<any[]>(
    () => [
      ...(Object.keys(currentFilter).length
        ? [
            {
              id: 'selection',
              width: 40,
              disableSortBy: true,
              Header: HeaderCheckbox,
              Cell: CellCheckbox,
            },
          ]
        : []),
      {
        Header: 'ACTION',
        id: 'action',
        width: 90,
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<any>) => (
          <>
            {lockLoadingItem === original.id ? (
              <Button size="middle" disabled={true} loading={true}>
                <MenuOutlined style={{ fontSize: '16px' }} />
              </Button>
            ) : (
              <Dropdown
                className="header-avatar-drop"
                overlay={() => (
                  <Menu>
                    {collection === 'pending' && (
                      <>
                        <Menu.Item key="homegrown">
                          <Button
                            type="link"
                            onClick={() => {
                              setSelectedProduct(original);
                              setShowRejectModal(true);
                            }}
                          >
                            Homegrown
                          </Button>
                        </Menu.Item>
                      </>
                    )}

                    {['approved', 'pending'].includes(collection) && (
                      <Menu.Item key="edit">
                        <Button
                          type="link"
                          onClick={() => {
                            showEditProductModal(original);
                          }}
                        >
                          Edit
                        </Button>
                      </Menu.Item>
                    )}
                    {collection === 'pending' && (
                      <Menu.Item key="associate">
                        <Button
                          type="link"
                          onClick={() => {
                            setSelectedProduct(original);
                            setShowAssociateModal(true);
                          }}
                        >
                          Associate with existing product
                        </Button>
                      </Menu.Item>
                    )}
                    {collection === 'discontinued' && (
                      <Menu.Item key="discontinued">
                        <Button
                          type="link"
                          onClick={() => {
                            setShowActivateModal(true);
                            setSelectedProduct(original);
                          }}
                        >
                          Activate
                        </Button>
                      </Menu.Item>
                    )}
                    {collection === 'approved' && (
                      <Menu.Item key="deactivate">
                        <Button
                          type="link"
                          onClick={() => {
                            showDeactivateModal(original);
                          }}
                        >
                          Discontinue
                        </Button>
                      </Menu.Item>
                    )}
                  </Menu>
                )}
              >
                <MenuOutlined style={{ fontSize: '16px' }} />
              </Dropdown>
            )}
          </>
        ),
      },
      {
        Header: 'IMAGE',
        accessor: 'covers',
        disableSortBy: true,
        Cell: ({ value, row: { original } }: CellProps<any>) => {
          if (!!value && value.length && value[0]?.link) {
            return (
              <img
                onClick={() => goDetail(original.id)}
                src={value[0]?.link}
                className="product-list-image"
                alt="product-list"
              />
            );
          }
          return <div onClick={() => goDetail(original.id)} className="whiteSquare" />;
        },
      },
      {
        Header: 'NOTE',
        accessor: 'note',
        disableSortBy: true,
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'STATES',
        width: 150,
        accessor: 'legal_states',
        disableSortBy: true,
        Cell: ({ value }: CellProps<any>) => value?.toString().replace(/,/g, ', ') || '',
      },
      {
        Header: 'BRAND',
        width: 200,
        accessor: 'brand_name',
        disableSortBy: true,
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'NAME',
        width: 200,
        accessor: 'name',
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'TYPE',
        width: 150,
        accessor: 'product_type',
        disableSortBy: true,
        id: 'product_type.type',
        Cell: ({ value }: CellProps<any>) => {
          if (!value) return '';
          const { type, specific } = value;
          return (
            <span>
              {type || ''} /<br /> {specific || ''}
            </span>
          );
        },
      },
      {
        Header: 'STRAIN TYPE',
        width: 150,
        accessor: 'strain_type',
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'STRAIN',
        accessor: 'strain',
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'FLAVOR',
        accessor: 'flavor',
        Cell: ({ value }: CellProps<any>) => {
          if (!(!!value && value.length)) return '';
          return <span className="preWrap">{addLineBreakForCharacters(value)}</span>;
        },
      },
      {
        Header: 'CANNABINOID',
        width: 150,
        accessor: (row: any) => row.labResults,
        Cell: ({ value }: CellProps<any>) => {
          if (!(!!value && value.length)) return '';

          const jointlyData: ILabResults[] = value.filter(
            (item: ILabResults) =>
              !item.retailLocationId && !item.retailProductId && item.type === 'cannabinoid'
          );

          const retailData: ILabResults[] = value.filter(
            (item: ILabResults) =>
              item.retailLocationId && item.retailProductId && item.type === 'cannabinoid'
          );
          const groupedArray: ILabResultsInfo[] = [];

          for (const item of retailData) {
            const { name, min, max } = item;
            const foundItem = groupedArray.find((e) => e.name === name);
            if (!foundItem) {
              if (min !== 0 && max !== 0) {
                groupedArray.push({ name, min, max });
              }
            } else {
              if (min !== null && (foundItem.min === null || min < foundItem.min)) {
                foundItem.min = min;
              }

              if (max !== null && (foundItem.max === null || max > foundItem.max)) {
                foundItem.max = max;
              }
            }
          }

          return (
            <div style={{ position: 'relative', height: '100%' }}>
              {jointlyData.map((item: ILabResults, index: number) => (
                <p key={index} className="preWrap labResultsItem">
                  <strong>
                    {item.name} {item.max === item.min ? item.max : `${item.min}-${item.max}`}
                  </strong>
                </p>
              ))}

              {groupedArray.map((item, index: number) => (
                <p key={index} className="preWrap labResultsItem">
                  {item.name} {item.max === item.min ? item.max : `${item.min}-${item.max}`}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'STORE',
        accessor: 'ex_store_url',
        disableSortBy: true,
        Cell: ({ value }: CellProps<any>) => {
          if (!value) return '';
          return (
            <a href={value} rel="noreferrer" target="_blank">
              {value}
            </a>
          );
        },
      },
      {
        Header: 'TERPENES',
        accessor: (row: any) => row.labResults,
        disableSortBy: true,
        width: 110,
        Cell: ({ value }: CellProps<any>) => {
          if (!(!!value && value.length)) return '';

          const jointlyData: ILabResults[] = value.filter(
            (item: ILabResults) =>
              !item.retailLocationId && !item.retailProductId && item.type === 'terpene'
          );

          const retailData: ILabResults[] = value.filter(
            (item: ILabResults) =>
              item.retailLocationId && item.retailProductId && item.type === 'terpene'
          );

          const groupedArray: ILabResultsInfo[] = [];

          for (const item of retailData) {
            const { name, min, max } = item;

            const foundItem = groupedArray.find((e) => e.name === name);
            if (!foundItem) {
              if (min !== 0 && max !== 0) {
                groupedArray.push({ name, min, max });
              }
            } else {
              if (min !== null && (foundItem.min === null || min < foundItem.min)) {
                foundItem.min = min;
              }

              if (max !== null && (foundItem.max === null || max > foundItem.max)) {
                foundItem.max = max;
              }
            }
          }

          return (
            <div style={{ position: 'relative', height: '100%' }}>
              {jointlyData.map((item: ILabResults, index: number) => (
                <p key={index} className="preWrap labResultsItem">
                  <strong>
                    {item.name} {item.max === item.min ? item.max : `${item.min}-${item.max}`}
                  </strong>
                </p>
              ))}

              {groupedArray.map((item, index: number) => (
                <p key={index} className="preWrap labResultsItem">
                  {item.name} {item.max === item.min ? item.max : `${item.min}-${item.max}`}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'ID',
        accessor: 'id',
        id: '_id',
        Cell: ({ value }: CellProps<any>) => <div className="break-word">{value || ''}</div>,
      },
      ...(['approved', 'pending'].includes(collection)
        ? [
            {
              Header: 'CREATED',
              accessor: 'created_at',
              id: 'createdAt',
              Cell: ({ value }: CellProps<any>) => formatDate(value),
            },
            {
              Header: 'UPDATED',
              accessor: 'updated_at',
              id: 'updatedAt',
              Cell: ({ value }: CellProps<any>) => formatDate(value),
            },
          ]
        : []),
      {
        Header: 'ADMIN',
        accessor: 'last_updated_by',
        Cell: ({ value }: CellProps<any>) => value || '',
      },
    ],
    // TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collection, lockLoadingItem, currentFilter]
  );

  return columns ? (
    <div className="tableAndFilterWrapper">
      <div className="search-con">
        <ProductsFilter
          onFilter={(res: FilterProductBody) => {
            setCurrentFilter(res);
          }}
          activateBulkActions={(res: string) => {
            if (multiSelectType === 'none')
              return notification.warning({
                message: 'Please select 1 or more items.',
              });
            if (res === 'edit') {
              setIsBulkEditModalShown(true);
            } else if (res === 'deactivate') {
              setIsBulkDeactivateModalShown(true);
            } else if (res === 'approve') {
              setIsBulkApproveModalShown(true);
            } else if (res === 'reject') {
              setIsBulkRejectModalShown(true);
            } else if (res === 'activate') {
              setShowBulkActivateModal(true);
            }
          }}
          currentFilter={currentFilter}
          collection={collection}
          setCurrentPage={setCurrentPage}
          shouldPrePopulate={true}
        >
          <div className="add-product-button">
            {['pending', 'discontinued'].includes(collection) && (
              <Button
                onClick={() => setShowAddProduct(true)}
                type="primary"
                className="add-product-text"
              >
                + Add Product
              </Button>
            )}
            {collection === 'approved' && (
              <>
                <Button onClick={() => clickUpload()} type="default">
                  <img src={uploadLogo} className="upload-csv-button" alt="upload-logo" />
                  UPLOAD CSV
                </Button>
                <Tooltip
                  title={`last time upload-product-csv status: ${lastUploadCsvStatus.status}`}
                >
                  {lastUploadCsvStatus.icon}
                </Tooltip>
              </>
            )}
          </div>
        </ProductsFilter>
      </div>

      <div className="tableWrapper">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              position: 'absolute',
              top: '40%',
              textAlign: 'center',
              left: 0,
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          !!data &&
          data.list && (
            <>
              {showAddProduct && (
                <ProductEditForm
                  visible={true}
                  action="new"
                  selectedProduct={{}}
                  hide={() => {
                    window.close();
                    setShowAddProduct(false);
                  }}
                  onSuccess={() => {
                    if (!newProduct) {
                      queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
                    }
                  }}
                  cannabinoidsList={cannabinoidsList}
                />
              )}

              <ProductTable
                data={data.list}
                columns={columns}
                selectedProduct={selectedProduct}
                isEditProductModalShown={isEditProductModalShown}
                hideEditModal={hideEditModal}
                onSuccess={() => queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] })}
                cannabinoidsList={cannabinoidsList}
                setMultiSelectType={setMultiSelectType}
                setSelectedProducts={setSelectedProducts}
                setSortByState={setSortByState}
                setIsColumnConfigModalShown={setIsColumnConfigModalShown}
                isColumnConfigModalShown={isColumnConfigModalShown}
                collection={collection}
              />

              <br />
              <PaginationComponent
                totalRows={data.total}
                pageChangeHandler={setCurrentPage}
                rowsPerPage={pageSize}
                setPageSize={setPageSize}
                pageSize={pageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setIsColumnConfigModalShown={setIsColumnConfigModalShown}
              />
              <br />

              <Modal
                open={showRejectModal}
                title={'Move Product to homegrown Request'}
                onOk={() => rejectProduct()}
                onCancel={() => {
                  setSelectedProduct(false);
                  setShowRejectModal(false);
                }}
                confirmLoading={rejectModalLoading}
              >
                <p>Are you sure you want to move product to homegrown?</p>
              </Modal>

              <AssociateProductsModal
                showAssociateModal={showAssociateModal}
                setShowAssociateModal={setShowAssociateModal}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />

              {/* Edit Modal */}
              {['multi', 'whole'].includes(multiSelectType) && isBulkEditModalShown && (
                <BulkEditProduct
                  open={isBulkEditModalShown}
                  selectedProducts={selectedProducts}
                  clearSelectionData={() =>
                    queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] })
                  }
                  closeBulkEditModal={() => setIsBulkEditModalShown(false)}
                  type="approve"
                  multiSelectType={multiSelectType}
                  query={formatQuery({
                    ...currentFilter,
                    collection,
                  })}
                  total={data.total}
                />
              )}

              {/* Bulk homegrown */}
              <Modal
                open={isBulkRejectModalShown}
                title={
                  multiSelectType === 'whole'
                    ? `Move ${data.total} products to Homegrown`
                    : `Move ${selectedProducts.length} products to Homegrown`
                }
                onOk={() => bulkRejectProducts()}
                onCancel={() => {
                  setIsBulkRejectModalShown(false);
                  setBulkRejectModalLoading(false);
                }}
                confirmLoading={bulkRejectModalLoading}
              >
                <p>Are you sure you want to move these products to homegrown?</p>
              </Modal>

              {/* Bulk Approve */}
              <Modal
                open={isBulkApproveModalShown}
                title={
                  multiSelectType === 'whole'
                    ? `Approve ${data.total} Products`
                    : `Approve ${selectedProducts.length} Products`
                }
                onOk={() => bulkApproveProducts()}
                onCancel={() => setIsBulkApproveModalShown(false)}
                confirmLoading={bulkApproveModalLoading}
              >
                <p>Are you sure you want to approve these products?</p>
              </Modal>

              {/* Bulk Deactivate */}
              <Modal
                open={isDeactivateModalShown || isBulkDeactivateModalShown}
                title={
                  isBulkDeactivateModalShown
                    ? multiSelectType === 'whole'
                      ? `Discontinue ${data.total} Products`
                      : `Discontinue ${selectedProducts.length} Products`
                    : 'Discontinue Product'
                }
                onOk={() =>
                  isDeactivateModalShown
                    ? deactivateProduct()
                    : isBulkDeactivateModalShown
                    ? bulkDeactivateModal()
                    : null
                }
                onCancel={() => hideDeactivateModal()}
                confirmLoading={bulkApproveModalLoading}
              >
                <p>
                  Are you sure you want to{' '}
                  {isDeactivateModalShown || isBulkDeactivateModalShown ? 'discontinue' : 'delete'}{' '}
                  {isBulkDeactivateModalShown ? 'these' : 'this'} product
                  {isBulkDeactivateModalShown ? 's' : ''}?
                </p>
              </Modal>

              <Modal
                open={showActivateModal}
                title={'Activate Product Request'}
                onOk={() => activateProduct()}
                onCancel={() => {
                  setSelectedProduct(false);
                  setShowActivateModal(false);
                }}
                confirmLoading={activateModalLoading}
              >
                <p>Are you sure you want to activate this product?</p>
              </Modal>

              <Modal
                open={showBulkActivateModal}
                title={
                  multiSelectType === 'whole'
                    ? `Activate ${data.total} Products`
                    : `Activate ${selectedProducts.length} Products`
                }
                onOk={() => bulkActivateProduct()}
                onCancel={() => {
                  setShowBulkActivateModal(false);
                }}
                confirmLoading={deactivateModalLoading}
              >
                <p>Are you sure you want to activate these products?</p>
              </Modal>
            </>
          )
        )}
      </div>
    </div>
  ) : null;
};

@mapProp(model_page_loading, 'pageLoading', 'filterLoading')
@mapProp(model_product)
@mapProp(previous_search)
class ProductListRedux extends Component<ProductListProp, object> {
  render(): React.ReactNode {
    const { pathname, search } = this.props.location;
    const collection = pathname?.substring(13) as Collection;
    return (
      <TableWrapper
        collection={collection}
        newProduct={!!search?.includes('newProduct')}
        {...this.props}
      />
    );
  }
}

export default ProductListRedux;
