import UserAutocomplete from './organization_user_autocomplete/UserAutocomplete';
import { Organization } from '../../../models/model_organization_paginated';
import { States } from '../../../utils/options';
import { Net } from '../../../utils/utils';
import { Input, Modal, Select, notification, Button, Form, FormInstance, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useRef, useState } from 'react';
import './organization_modal.less';

const { Option } = Select;

interface OrganizationModalProp {
  visible: boolean;
  hide: () => void;
  onSuccess: () => void;
  selectedOrganization?: Organization;
  action: string;
}

export const OrganizationModal: React.FC<OrganizationModalProp> = (props) => {
  const [approvedOrganization, setApprovedOrganization] = useState<Organization>({
    isOnlineStore: true,
  } as Organization);
  const [approveModalLoading, setApproveModalLoading] = useState(false);

  const formRef = useRef<FormInstance<Organization>>({} as FormInstance);

  const hideApproveModal = () => {
    setApprovedOrganization({} as Organization);
    formRef.current?.resetFields();
    props.hide();
  };

  const approveNewProduct = async () => {
    const {
      id,
      subscription,
      name,
      phone_number: phoneNumber,
      isOnlineStore,
      address,
      organization_website: organizationWebsite,
      city,
      state,
      zip,
      email,
      owner,
    } = approvedOrganization;

    const formData = {
      ...(id ? { id } : {}),
      ...(subscription ? { subscription } : {}),
      ...(name ? { name } : {}),
      ...(phoneNumber ? { phoneNumber } : {}),
      isOnlineStore: !!isOnlineStore,
      ...(address ? { address } : {}),
      ...(organizationWebsite ? { retailOrganizationWebsite: organizationWebsite } : {}),
      ...(city ? { city } : {}),
      ...(state ? { state: !!state && state.toString() } : {}),
      ...(zip ? { zip } : {}),
      ...(email ? { email } : {}),
      ...(owner ? { owner } : {}),
    };

    setApproveModalLoading(true);

    try {
      const res = await Net.req('/org/v1/organization/', formData, 'post');
      setApproveModalLoading(false);
      if (res?.status) {
        if (res.status === 'success') {
          props.onSuccess();
          hideApproveModal();
          notification['success']({
            message: 'Success!',
          });
        } else {
          notification['error']({
            message: res?.message,
          });
        }
      } else {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
    } catch (e) {
      console.error('error', e);
      setApproveModalLoading(false);
      notification['error']({
        message: 'Something went wrong!',
      });
    }
  };

  const onApproveChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement> | string) => {
    setApprovedOrganization((a) => ({
      ...a,
      [field]: typeof e === 'string' ? e : e.target.value,
    }));
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setApprovedOrganization((a) => ({
      ...a,
      isOnlineStore: e.target.checked,
    }));
  };

  return (
    <Modal
      visible={props.visible}
      confirmLoading={approveModalLoading}
      footer={null}
      width="966px"
      onCancel={hideApproveModal}
    >
      <Form<Organization>
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={approveNewProduct}
        initialValues={props.selectedOrganization}
        ref={formRef}
      >
        <div className="product-modal-title">
          {(() => {
            switch (props.action) {
              case 'new':
                return 'Add Organization';
              case 'pending-edit':
                return 'Edit Organization';
              case 'approve':
                return 'Approve Organization';
              case 'edit':
                return 'Edit Organization';
              default:
                return 'Organization';
            }
          })()}
          <div className="edit-product-option-buttons">
            <Button type="link" className="cancel-button" onClick={hideApproveModal}>
              Cancel
            </Button>
            <span className="add-product-space" />
            <Button
              type="primary"
              htmlType="submit"
              loading={approveModalLoading}
              disabled={approveModalLoading}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="approve-modal-product approve-modal">
          <div className="product-modal-col-2">
            <div className="form-item">
              <div>Name</div>
              <Form.Item name="name" rules={[{ required: true }]}>
                <Input onChange={onApproveChange('name')} value={approvedOrganization.name} />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Email</div>
              <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                <Input onChange={onApproveChange('email')} value={approvedOrganization.email} />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Phone Number </div>
              <Form.Item name="phone_number" rules={[{ required: true }]}>
                <Input
                  onChange={onApproveChange('phone_number')}
                  value={approvedOrganization.phone_number}
                />
              </Form.Item>
            </div>

            <div className="form-item" style={{ marginBottom: 20 }}>
              <Checkbox defaultChecked={approvedOrganization.isOnlineStore} onChange={onChange}>
                Is online store?
              </Checkbox>
            </div>

            <div className="form-item">
              <div>Owner email </div>
              <Form.Item name="owner" rules={[{ required: true, type: 'email' }]}>
                <UserAutocomplete
                  onChange={onApproveChange('owner')}
                  value={approvedOrganization.owner}
                />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Website URL</div>
              <Form.Item
                name="organization_website"
                rules={[
                  {
                    required: approvedOrganization.isOnlineStore,
                    type: 'url',
                  },
                ]}
              >
                <Input
                  onChange={onApproveChange('organization_website')}
                  value={approvedOrganization.organization_website}
                />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Street Address</div>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: !approvedOrganization.isOnlineStore,
                  },
                ]}
              >
                <Input onChange={onApproveChange('address')} value={approvedOrganization.address} />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>City</div>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: !approvedOrganization.isOnlineStore,
                  },
                ]}
              >
                <Input onChange={onApproveChange('city')} value={approvedOrganization.city} />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>State</div>
              <Form.Item
                name="legal_states"
                rules={[
                  {
                    required: !approvedOrganization.isOnlineStore,
                  },
                ]}
              >
                <Select
                  showSearch
                  className="add-product-input-full-component"
                  onChange={onApproveChange('state')}
                  value={approvedOrganization.state}
                  filterOption={(input, option) =>
                    option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {States.map(({ name, abbreviation }) => (
                    <Option key={abbreviation} value={abbreviation} title={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Zip Code</div>
              <Form.Item
                name="zip"
                rules={[
                  {
                    required: !approvedOrganization.isOnlineStore,
                  },
                ]}
              >
                <Input onChange={onApproveChange('zip')} value={approvedOrganization.zip} />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
