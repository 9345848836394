import AddEditModal from './AddEditModal';
import PaginationComponent from '../../components/table/pagination';
import ReactTable from '../../components/table/table';
import {
  deleteEcommerceClient,
  EcommerceClient,
  getEcommerceClients,
} from '../../services/api/ecommerceClient';
import { formatDate } from '../../utils/utils';
import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Button, notification, Popconfirm, Space } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import './ecommerceClient.less';
import { CellProps } from 'react-table';

const EcommerceClientList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<EcommerceClient | undefined>();
  const [isColumnConfigModalShown, setIsColumnConfigModalShown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);

  useEffect(() => {
    if (!isModalOpen) {
      setItemToEdit(undefined);
    }
  }, [isModalOpen]);

  const { data, refetch } = useQuery({
    queryKey: ['getEcommerceClients', { skip: (currentPage - 1) * pageSize, limit: pageSize }],
    queryFn: getEcommerceClients,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: { results: [], total: 0 },
    retry: 1,
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteEcommerceClient,
    onSuccess: ({ data }) => {
      notification.success({
        message: `Client ${data.host} deleted successfully!`,
      });
      setTimeout(() => {
        refetch();
      }, 500);
    },
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const columns = useMemo<unknown[]>(
    () => [
      {
        Header: 'Action',
        width: 380,
        Cell: ({ row: { original } }: CellProps<EcommerceClient>) => (
          <Space size="middle">
            <Button
              type="primary"
              icon={<CopyOutlined />}
              onClick={() => {
                const scriptTag = `<script src="${process.env.REACT_APP_SPARK_BASE_URL}/api/v1/spark/chat?client_id=${original._id}" type="text/javascript"></script>`;
                navigator.clipboard.writeText(scriptTag);
                notification.success({
                  message: `Script tag copied to clipboard!`,
                });
              }}
            >
              Script tag
            </Button>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setItemToEdit(original);
                setTimeout(() => {
                  setIsModalOpen(true);
                }, 100);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                if (original._id) deleteMutation.mutate(original._id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
      {
        Header: 'Retail Location',
        minWidth: 180,
        maxWidth: 220,
        flexGrow: 1,
        accessor: 'retailLocation.name',
        Cell: ({ value, row: { original } }: CellProps<EcommerceClient>) => (
          <Link to={'retailLocation/' + original.retailLocationId}>{value}</Link>
        ),
      },
      {
        Header: 'Client ID',
        minWidth: 220,
        maxWidth: 350,
        flexGrow: 1,
        accessor: '_id',
        Cell: ({ value }: CellProps<EcommerceClient>) => <>{value}</>,
      },
      {
        Header: 'Created At',
        minWidth: 150,
        maxWidth: 220,
        flexGrow: 1,
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<Record<string, string>>) => formatDate(value),
      },
      {
        Header: 'Updated At ',
        minWidth: 150,
        maxWidth: 220,
        flexGrow: 1,
        accessor: 'updatedAt',
        Cell: ({ value }: CellProps<Record<string, string>>) => formatDate(value),
      },
    ],
    [deleteMutation]
  );

  return (
    <div className="tableWrapper">
      <Space size="middle" className="ecommerce-header-wrapper">
        <div className="ecommerce-client-list-header">Ecommerce Clients</div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Add
        </Button>
      </Space>
      <ReactTable
        data={data.results}
        columns={columns}
        selectedItem={false}
        isEditModalShown={false}
        setMultiSelectType={() => true}
        setSelectedItens={() => true}
        setIsColumnConfigModalShown={setIsColumnConfigModalShown}
        isColumnConfigModalShown={isColumnConfigModalShown}
        manualSortBy={false}
      />
      <br />
      <PaginationComponent
        totalRows={data.total as number}
        pageChangeHandler={setCurrentPage}
        rowsPerPage={pageSize}
        setPageSize={setPageSize}
        pageSize={pageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setIsColumnConfigModalShown={setIsColumnConfigModalShown}
      />
      <br />
      {isModalOpen && <AddEditModal setOpen={setIsModalOpen} itemToEdit={itemToEdit} />}
    </div>
  );
};

export default EcommerceClientList;
