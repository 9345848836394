import '../product/product-routing.less';
import PreApprovedTable from './preApproved/RetailProducts';
import { module_retail_products } from '../../models/model_retail_products';
import { Menu, Badge } from 'antd';
import { mapProp } from 'module-reaction';
import React from 'react';
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';

const RetailProductRouting: React.FC = (props: any) => {
  const location = useLocation();

  const {
    matchedProductsCount,
    unmatchedProductsCount,
    prematchedProductsCount,
    unsupportedProductsCount,
  } = props;

  return (
    <div className="list-view product-list-view">
      <div className="product-list-header">Retail Products</div>
      <Menu mode="horizontal" className="products-nav-panel" selectedKeys={[location?.pathname]}>
        <Menu.Item key="/retailProductList/mapped">
          <NavLink to="/retailProductList/mapped" data-testid="mapped-tab">
            Mapped
            <Badge
              className="site-badge-count-109"
              count={matchedProductsCount}
              style={{ backgroundColor: 'rgb(40, 180, 99)' }}
              overflowCount={999999999}
              showZero
            />
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/retailProductList/preMapped">
          <NavLink to="/retailProductList/preMapped" data-testid="preMapped-tab">
            Pre mapped
            <Badge
              className="site-badge-count-109"
              count={prematchedProductsCount}
              style={{ backgroundColor: '#F4D03F' }}
              overflowCount={999999999}
              showZero
            />
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/retailProductList/notMapped">
          <NavLink to="/retailProductList/notMapped" data-testid="notMapped-tab">
            Not mapped
            <Badge
              className="site-badge-count-109"
              count={unmatchedProductsCount}
              style={{ backgroundColor: 'rgb(231, 76, 60)' }}
              overflowCount={999999999}
              showZero
            />
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/retailProductList/notSupported">
          <NavLink to="/retailProductList/notSupported">
            Not supported
            <Badge
              className="site-badge-count-109"
              count={unsupportedProductsCount}
              style={{ backgroundColor: 'black' }}
              overflowCount={999999999}
              showZero
            />
          </NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/retailProductList/mapped" component={PreApprovedTable} />
        <Route path="/retailProductList/preMapped" component={PreApprovedTable} />
        <Route path="/retailProductList/notMapped" component={PreApprovedTable} />
        <Route path="/retailProductList/notSupported" component={PreApprovedTable} />
        <Redirect to="/retailProductList/mapped" />
      </Switch>
    </div>
  );
};

@mapProp(module_retail_products)
class RetailProductWrapper extends React.Component<any> {
  render(): React.ReactNode {
    return <RetailProductRouting {...this.props} />;
  }
}

export default RetailProductWrapper;
