import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
type BrandDetailProp = RouteComponentProps;

interface RouteParams {
  id: string;
}

export class BrandDetailView extends React.Component<BrandDetailProp, {}> {
  render() {
    const { id } = this.props.match.params as RouteParams;
    return <div className="brand-detail-view">brand detail,id:{id}</div>;
  }
}
