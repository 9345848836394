import { UserFilter } from './user_filter/user_filter';
import downArrow from '../../assets/down_arrow.svg';
import {
  ModleUser,
  modleUser,
  FetchUsersAction,
  FetchMoreUsersAction,
  User,
  ChgBanUsersAction,
  LoadingUsersAction,
} from '../../models/model_user';
import { Net } from '../../utils/utils';
import {
  Button,
  List,
  Modal,
  Checkbox,
  Avatar,
  Dropdown,
  Menu,
  Spin,
  notification,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { mapProp, doAction, doFunction, KV } from 'module-reaction';
import moment from 'moment';
import React from 'react';
import './user_list.less';

interface UserListProp extends ModleUser {
  submitUser?: (selectedIds: Set<unknown>, list?: User[]) => void;
  from?: string;
}

const genderEnum: KV = {
  female: 'Female',
  male: 'Male',
  transgenderFemale: 'Transgender Female',
  transgenderMale: 'Transgender Male',
  genderVariant: 'Gender Variant/Non-Conforming',
  other: 'Other/Not Listed',
  nonBinary: 'Non-binary',
  agender: 'Agender',
  preferNotToSay: 'Prefer not to say',
  unknown: 'Unknown',
};

@mapProp(modleUser)
export class UserListView extends React.Component<UserListProp> {
  state = {
    selectedIds: new Set(),
    showBanModal: false,
    banModalLoading: false,
    activeSort: '',
    sortedArray: false,
    sortedUsersList: [],
    sortActive: false,
    deleteModalLoading: false,
    isDeleteModalShown: false,
    selectedUser: {} as User,
    selectedFilter: {},
  };

  componentDidUpdate(prevProps: UserListProp) {
    if (prevProps.tabItem !== this.props.tabItem) {
      this.onTabChange(this.props.tabItem);
    }
  }

  render() {
    const { tabItem, loading, from } = this.props;
    const { selectedIds, showBanModal, banModalLoading, isDeleteModalShown, deleteModalLoading } =
      this.state;

    const curTab = tabItem;

    return (
      <div
        className={
          from === 'RETAIL_ORGANIZATION' ? 'list-view org-list-view' : 'list-view user-list-view'
        }
      >
        <div className="info-con">
          <UserFilter onSubmitFilter={this.submitFilter}>
            {from !== 'RETAIL_ORGANIZATION' && (
              <Button
                className="user-ban-action"
                type="primary"
                disabled={selectedIds.size === 0}
                onClick={() => this.setState({ showBanModal: true })}
              >
                {curTab === 'active' ? 'BAN' : 'UNBAN'} USER
              </Button>
            )}
          </UserFilter>
        </div>
        {loading && (
          <div className="pending-loading">
            <Spin size="large" />
          </div>
        )}
        {!loading && this.getListCon()}
        <Modal
          visible={showBanModal}
          title={curTab === 'active' ? 'BAN USER' : 'UNBAN USER'}
          onOk={this.changeBanUsers}
          onCancel={this.hideBanModal}
          confirmLoading={banModalLoading}
        >
          <p>
            Are you sure you want to{' '}
            {`${curTab === 'active' ? 'ban' : 'unban'} ${selectedIds.size} user(s)?`}
          </p>
        </Modal>
        <Modal
          visible={isDeleteModalShown}
          title={'Delete User'}
          onOk={this.deleteUser}
          onCancel={this.hideDeleteModal}
          confirmLoading={deleteModalLoading}
        >
          <p>Are you sure you want to delete this user?</p>
        </Modal>
      </div>
    );
  }

  private hideDeleteModal = () => {
    this.setState({
      isDeleteModalShown: false,
      selectedUser: {},
    });
  };

  private deleteUser = async () => {
    const { selectedUser } = this.state;

    this.setState({ deleteModalLoading: true });

    try {
      const res = await Net.req('/user/admin-delete-user', { users: [selectedUser.id] }, 'post');
      this.setState({
        deleteModalLoading: false,
        isDeleteModalShown: false,
      });
      this.onTabChange(this.props.tabItem);
      if (res?.message) {
        if (res.message === 'success') {
          notification['success']({
            message: 'Success!',
          });
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
    } catch (e) {
      this.setState({
        deleteModalLoading: false,
        isDeleteModalShown: false,
      });
      notification['error']({
        message: 'Something went wrong!',
      });
    }
  };

  private submitFilter = (
    filterData: {
      search_key?: string;
      searchKey?: string;
      country?: string;
      ethnicity?: string;
      gender?: string;
      birthday?: string;
      height?: string;
      weight?: string;
      education_level?: string;
      marital_status?: string;
      employment?: string;
    } = {}
  ) => {
    if (Object.prototype.hasOwnProperty.call(filterData, 'search_key'))
      filterData.searchKey = filterData.search_key;
    this.setState({ selectedFilter: filterData }, this.performSearch);
  };

  private performSearch = () => {
    const { selectedIds, selectedFilter } = this.state;
    selectedIds.clear();
    doAction(LoadingUsersAction);
    doAction(FetchUsersAction, {
      curTab: this.props.tabItem,
      pageIdx: 1,
      ...selectedFilter,
    });
  };

  private showDeleteModal = (item: User) => (e: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      isDeleteModalShown: true,
      selectedUser: item,
    });
  };

  private getEthnicity(ethnicity?: string | string[]) {
    if (!ethnicity) {
      return null;
    }
    if (typeof ethnicity === 'string') {
      return ethnicity;
    }
    return ethnicity.reduce((result: string, e: string) => result + `, ${e}`, '');
  }

  private sortItems = (sortItem: string) => {
    if (this.state.activeSort === sortItem) {
      this.setState(
        {
          activeSort: '',
          sortActive: false,
        },
        () => this.processSort(sortItem)
      );
    } else {
      this.setState(
        {
          activeSort: sortItem,
          sortActive: true,
        },
        () => this.processSort(sortItem)
      );
    }
  };

  private processSort = (sortItem: string) => {
    const arrayItem: Record<string, never>[] = JSON.parse(JSON.stringify(this.props.list));
    const sortArray = arrayItem.sort((a, b) => {
      if (a[sortItem] > b[sortItem]) {
        return 1;
      }
      return -1;
    });
    this.setState({
      sortedUsersList: [...sortArray],
    });
  };

  renderDropdownOverlay = (item: User) => (
    <Menu>
      <Menu.Item key="delete">
        <Button type="link" onClick={this.showDeleteModal(item)}>
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  loadMoreBrands = () => {
    const { pageSize, pageIdx, hasMore, pageLoading } = this.props;
    const userListCon = document.getElementById('user-list-con');
    if (
      userListCon &&
      userListCon.scrollHeight - userListCon.scrollTop - userListCon.offsetHeight < 40 &&
      !pageLoading &&
      hasMore
    ) {
      this.onPageChange((pageIdx || 0) + 1, pageSize);
    }
  };

  private getListCon() {
    const { list, hasMore, from } = this.props;
    const { selectedIds, activeSort, sortActive, sortedUsersList } = this.state;
    return (
      <div className="list-con" id="user-list-con" onScroll={this.loadMoreBrands}>
        <List
          className={from === 'RETAIL_ORGANIZATION' ? 'org-list' : 'user-list'}
          dataSource={sortActive ? sortedUsersList : list}
          header={
            <>
              {from !== 'RETAIL_ORGANIZATION' && (
                <Checkbox className="check-ico" onChange={this.selectAll} />
              )}
              {from !== 'RETAIL_ORGANIZATION' && <span className="span-2">ACTION</span>}
              <span className="span-1">AVATAR</span>
              <span className="span-2">STATUS</span>
              <span className="span-2" onClick={() => this.sortItems('username')}>
                USERNAME
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'username' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-3" onClick={() => this.sortItems('email')}>
                EMAIL
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'email' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-2" onClick={() => this.sortItems('country')}>
                COUNTRY
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'country' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-3" onClick={() => this.sortItems('ethnicity')}>
                ETHNICITY
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'ethnicity' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-2" onClick={() => this.sortItems('gender')}>
                GENDER
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'gender' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-2" onClick={() => this.sortItems('birthday')}>
                BIRTHDAY
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'birthday' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-2" onClick={() => this.sortItems('height')}>
                HEIGHT
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'height' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              <span className="span-2" onClick={() => this.sortItems('weight')}>
                WEIGHT
                <img
                  src={downArrow}
                  className="header-sort-icon"
                  alt="sort-icon"
                  style={activeSort === 'weight' ? { transform: 'rotate(180deg)' } : {}}
                />
              </span>
              {from !== 'RETAIL_ORGANIZATION' && (
                <>
                  <span className="span-3" onClick={() => this.sortItems('education')}>
                    EDUCATION LEVEL
                    <img
                      src={downArrow}
                      className="header-sort-icon"
                      alt="sort-icon"
                      style={
                        activeSort === 'education'
                          ? {
                              transform: 'rotate(180deg)',
                            }
                          : {}
                      }
                    />
                  </span>
                  <span className="span-2" onClick={() => this.sortItems('marital')}>
                    MARITAL
                    <img
                      src={downArrow}
                      className="header-sort-icon"
                      alt="sort-icon"
                      style={
                        activeSort === 'marital'
                          ? {
                              transform: 'rotate(180deg)',
                            }
                          : {}
                      }
                    />
                  </span>
                  <span className="span-2" onClick={() => this.sortItems('employment')}>
                    EMPLOYMENT
                    <img
                      src={downArrow}
                      className="header-sort-icon"
                      alt="sort-icon"
                      style={
                        activeSort === 'employment'
                          ? {
                              transform: 'rotate(180deg)',
                            }
                          : {}
                      }
                    />
                  </span>
                </>
              )}
            </>
          }
          renderItem={(item: User) => (
            <List.Item key={`${item.id}`}>
              <Checkbox
                className="check-ico"
                checked={selectedIds.has(item.id)}
                onChange={(e) => this.selectOne(item.id, e.target.checked)}
              />
              <div className="item-content">
                {from !== 'RETAIL_ORGANIZATION' && (
                  <span className="span-2">
                    <Dropdown
                      className="header-avatar-drop"
                      overlay={this.renderDropdownOverlay(item)}
                    >
                      <div className="header-logo-parent">Select</div>
                    </Dropdown>
                  </span>
                )}
                <span className="span-1 avatar">
                  <Avatar
                    style={{
                      backgroundColor: '#5344ffff',
                    }}
                    src={
                      item.avatar?.link ? (
                        <img className="avatar-image" alt="avatar-item" src={item.avatar?.link} />
                      ) : (
                        <Typography.Title
                          style={{
                            color: '#ffffff',
                            lineHeight: '60px',
                          }}
                          level={4}
                        >
                          {item.username && item.username[0].toUpperCase()}
                        </Typography.Title>
                      )
                    }
                    size={60}
                  />
                </span>
                <span className="span-2">{item.profile?.banned ? 'Banned' : 'Active'}</span>
                <span className="span-2">{item.username}</span>
                <span className="span-3 ellipse">{item.email}</span>
                <span className="span-2">{item.country || 'unknown'}</span>
                <span className="span-3 ellipse">
                  {this.getEthnicity(item.profile?.ethnicity) || 'unknown'}
                </span>
                <span className="span-2 ellipse">
                  {genderEnum[item.profile?.gender || 'unknown'] || 'Unknown'}
                </span>
                <span className="span-2 ellipse">
                  {item.profile?.date_of_birth
                    ? moment(item.profile?.date_of_birth).format('YYYY-MM-DD')
                    : 'unknown'}
                </span>
                <span className="span-2 ellipse">
                  {item.profile?.height
                    ? item.profile?.height?.feet +
                      'feet ' +
                      (item.profile.height.inches || 0 + 'inches')
                    : 'unknown'}
                </span>
                <span className="span-2">
                  {item.profile?.weight ? item.profile?.weight + 'pounds' : 'unknown'}
                </span>
                {from !== 'RETAIL_ORGANIZATION' && (
                  <>
                    <span className="span-3 ellipse">
                      {item.profile?.education_level || 'unknown'}
                    </span>
                    <span className="span-2">{item.profile?.marital || 'unknown'}</span>
                    <span className="span-2">{item.profile?.employment || 'unknown'}</span>
                  </>
                )}
              </div>
            </List.Item>
          )}
        />
        {hasMore && (
          <div className="loadmore">
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    if (!this.props.list?.length) {
      this.onTabChange(this.props.tabItem);
    }
  }

  private onTabChange = (curTab: string) => {
    const { selectedIds } = this.state;
    selectedIds.clear();
    doAction(LoadingUsersAction);
    doAction(FetchUsersAction, { curTab, pageIdx: 1, search_key: '' });
  };

  private onPageChange = (pageIdx?: number, pageSize?: number) => {
    const { selectedFilter } = this.state;
    doAction(FetchMoreUsersAction, {
      curTab: this.props.tabItem,
      pageIdx,
      pageSize,
      ...selectedFilter,
    });
  };

  private selectAll = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const { list } = this.props;
    const { selectedIds } = this.state;
    if (checked) {
      list?.forEach((item) => selectedIds.add(item.id));
    } else {
      selectedIds.clear();
    }
    this.setState({ selectedIds });
  };

  private selectOne(id?: string, checked?: boolean) {
    const { selectedIds } = this.state;
    const { from, submitUser } = this.props;
    if (from === 'RETAIL_ORGANIZATION') {
      selectedIds.clear();
    }
    void (checked ? selectedIds.add(id) : selectedIds.delete(id));
    this.setState({ selectedIds });
    if (from === 'RETAIL_ORGANIZATION') {
      const { list } = this.props;
      submitUser?.(selectedIds, list);
    }
  }

  private hideBanModal = () => {
    this.setState({ showBanModal: false });
  };

  private changeBanUsers = () => {
    this.setState({ banModalLoading: true });

    const { selectedIds } = this.state;
    doAction(ChgBanUsersAction, { users: Array.from(selectedIds) });
    doFunction(async () => this.setState({ banModalLoading: false, showBanModal: false }));
  };
}
