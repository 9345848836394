import { getRetailOrganization } from '../../../services/api/organizationAPI/organizationListAPI';
import { useQuery } from '@tanstack/react-query';
import { Spin, Empty, Descriptions } from 'antd';
import React from 'react';

type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

const RetailOrganizationDetail = (props: Props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { isLoading, error, data } = useQuery({
    queryKey: ['getRetailLocation', id],
    queryFn: () => getRetailOrganization(id),
  });

  if (isLoading) return <Spin size="large" />;
  if (error) return <Empty />;

  const {
    name,
    isOnlineStore,
    phoneNumber,
    retailOrganizationWebsite,
    state,
    zip,
    address,
    numberOfRetailLocation,
    city,
  } = data?.retailOrganization || {};

  return (
    <Descriptions title={name} bordered style={{ marginTop: 20 }}>
      <Descriptions.Item label="ID">{data?.retailOrganization?.id}</Descriptions.Item>
      <Descriptions.Item label="Online Store">{isOnlineStore ? 'Yes' : 'No'}</Descriptions.Item>
      <Descriptions.Item label="Locations">{numberOfRetailLocation}</Descriptions.Item>

      <Descriptions.Item label="Website" span={3}>
        {retailOrganizationWebsite}
      </Descriptions.Item>
      <Descriptions.Item label="Phone Number">{phoneNumber}</Descriptions.Item>
      <Descriptions.Item label="Address">{address}</Descriptions.Item>
      <Descriptions.Item label="State">{state}</Descriptions.Item>
      <Descriptions.Item label="City">{city}</Descriptions.Item>
      <Descriptions.Item label="Zipcode">{zip}</Descriptions.Item>
    </Descriptions>
  );
};

export default RetailOrganizationDetail;
