import axios from '../../apiClient';
import React from 'react';

export const getPotentialDuplicatesAPI = (id: string) =>
  axios.get('/product/get-potential-duplicate-products/' + id);

export const associateDuplicateAPI = (currentId: string, existingId: React.Key) =>
  axios.post('/product/pending/associate-product', {
    currentId,
    existingId,
  });
