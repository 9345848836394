import { Input, Select } from 'antd';
import './retailUser_filter.less';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { getRetailOrganizations } from '../../../services/api/organizationAPI';
import { getRetailLocations } from '../../../services/api/retailLocationAPI';
import { useQuery } from '@tanstack/react-query';

interface RetailUserFilterProp {
  onSubmitFilter: any;
  curTab: string;
  children: React.ReactNode;
}

const RetailUserFilter = ({ onSubmitFilter, curTab, children }: RetailUserFilterProp) => {
  const [filterState, setFilterState] = useState({
    search_key: '',
    retail_organization_id: '',
    retail_location_id: '',
  });

  const emitChangeDebounced = useCallback(debounce(onSubmitFilter, 500), []);

  useEffect(() => () => emitChangeDebounced.cancel(), []);

  const onSearchChange = (field: string) => (e: any) => {
    const temp = {
      ...filterState,
      [field]: ['search_key'].includes(field) ? e.target.value || '' : e || '',
    };
    if (field === 'retail_organization_id') {
      temp.retail_location_id = '';
    }
    setFilterState(temp);
    emitChangeDebounced(temp);
  };

  const retailOrganizationsQuery = useQuery({
    queryKey: ['getRetailOrganizations'],
    queryFn: getRetailOrganizations,
    refetchOnWindowFocus: false,
  });

  const retailLocationsQuery = useQuery({
    queryKey: ['getRetailLocations', filterState.retail_organization_id],
    queryFn: () => getRetailLocations({ organizationId: filterState.retail_organization_id }),
    refetchOnWindowFocus: false,
  });

  return (
    <div className="retail-user-filter-root">
      <div className="product-filter">
        <div className="filter-item">
          {retailOrganizationsQuery.data?.length && (
            <Select
              key={curTab}
              showSearch
              className="product-filter-name-2"
              placeholder="Organization"
              allowClear
              onChange={onSearchChange('retail_organization_id')}
              style={{ width: '200px' }}
            >
              {retailOrganizationsQuery.data.map(({ name, id }: { name: string; id: string }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        <div className="filter-item">
          {retailLocationsQuery.data?.length && curTab === 'location' && (
            <Select
              showSearch
              className="product-filter-name-2"
              placeholder="Location"
              allowClear
              onChange={onSearchChange('retail_location_id')}
              style={{ width: '200px', cursor: 'pointer' }}
            >
              {retailLocationsQuery.data
                ?.sort((a: any, b: any) => {
                  if (a.totalProducts > b.totalProducts) {
                    return -1;
                  }
                  return 1;
                })
                .map(({ name, id }: { name: string; id: string }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </div>
        <div className="filter-item">
          <Input.Search
            placeholder="Search"
            onChange={onSearchChange('search_key')}
            allowClear={true}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default RetailUserFilter;
