import './brand_list.less';
import downArrow from '../../../assets/down_arrow.svg';
import uploadLogo from '../../../assets/upload-logo.svg';
import {
  Brand,
  FetchBrandsAction,
  EmptyBrandsAction,
  FetchMoreBrandsAction,
  SearchBrandsAction,
  model_brand,
  PollingUploadBrandStatusAction,
  BrandListProp,
} from '../../../models/model_brand';
import { MODULE_DIALOG, close_dialog } from '../../../models/model_dialog';
import { model_page_loading, PageLoadingAction } from '../../../models/model_product';
import { bulkDeactivateBrandAPI } from '../../../services/api/brandAPI/bulkDeactivateBrandAPI';
import { verifyBrandLockAPI } from '../../../services/api/verifyLockAPI/verifyBrandLock';
import { uploadFile, Net, escapeQuery } from '../../../utils/utils';
import BrandEditModal from '../brand-edit-modal/brand-edit-modal';
import { BrandFilter } from '../brand_filter/brand_filter';
import BulkEditBrands from '../bulk_edit_brand/bulk_edit_brand';
import {
  List,
  Button,
  message,
  Tooltip,
  Modal,
  notification,
  Checkbox,
  Dropdown,
  Menu,
  Spin,
} from 'antd';
import { mapProp, doAction, getModuleProp } from 'module-reaction';
import React, { MouseEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

@mapProp(model_page_loading, 'pageLoading', 'filterLoading')
@mapProp(model_brand)
export class BrandListView extends React.Component<BrandListProp, object, RouteComponentProps> {
  state = {
    selectedBrand: {} as Brand,
    isBrandModalShown: false,
    isDeactivateModalShown: false,
    deactivateModalLoading: false,
    selectedBrands: [],
    activeSort: '',
    sortActive: false,
    sortedProductList: [],
    isBulkEditModalShown: false,
    isBulkDeactivateModalShown: false,
    lockLoadingItem: null,
    filter: {
      sort_by: '',
      order: '',
    },
  };

  sortItems = (sortItem: string) => {
    const { filter } = this.state;
    doAction(PageLoadingAction, { filterLoading: true });
    if (filter.sort_by === '' || (filter.sort_by !== sortItem && filter.order !== 'asc')) {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'desc' },
        },
        () =>
          doAction(FetchBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'desc',
          })
      );
    } else if (filter.order === 'asc') {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'desc' },
        },
        () =>
          doAction(FetchBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'desc',
          })
      );
    } else {
      this.setState(
        {
          filter: { ...filter, sort_by: sortItem, order: 'asc' },
        },
        () =>
          doAction(FetchBrandsAction, {
            pageIdx: 1,
            pageSize: 20,
            sort_by: sortItem,
            order: 'asc',
          })
      );
    }
  };

  checkProduct = (itemId: string) => {
    const data: string[] = [...this.state.selectedBrands];
    const itemIndex = data.findIndex((item: string) => item === itemId);
    if (itemIndex === -1) {
      data.push(itemId);
    } else {
      data.splice(itemIndex, 1);
    }
    this.setState({
      selectedBrands: [...data],
    });
  };

  checkAllProducts = () => {
    let data: string[] = this.state.selectedBrands;
    if (data.length !== this.props.list.length) {
      data = this.props.list.map((item) => item.id);
    } else {
      data = [];
    }
    this.setState({
      selectedBrands: [...data],
    });
  };

  private showBulkActions = (action: string) => {
    if (action === 'deactivate' && this.state.selectedBrands.length > 0) {
      this.showBulkDeactivateModal();
    } else if (action === 'edit' && this.state.selectedBrands.length > 0) {
      this.showBulkEditModal();
    } else {
      notification.warning({ message: 'Please select 1 or more items.' });
    }
  };

  private showBulkDeactivateModal = () => {
    this.setState({
      isBulkDeactivateModalShown: true,
    });
  };

  private showBulkEditModal = () => {
    this.setState({
      isBulkEditModalShown: true,
    });
  };

  renderDropdownOverlay = (item: Brand) => (
    <Menu>
      <Menu.Item key="edit">
        <Button type="link" onClick={this.showBrandModal(item)}>
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="deactivate">
        <Button type="link" onClick={this.showDeactivateModal(item)}>
          Discontinue
        </Button>
      </Menu.Item>
    </Menu>
  );

  loadMoreBrands = () => {
    const { pageSize, pageIdx, hasMore, pageLoading } = this.props;
    const brandListCon = document.getElementById('brand-list-con')!;
    if (
      brandListCon?.scrollHeight - brandListCon?.scrollTop - brandListCon?.offsetHeight < 40 &&
      !pageLoading &&
      hasMore
    ) {
      this.onPageChange(pageIdx + 1, pageSize);
    }
  };

  render() {
    const { list, lastUploadCsvStatus, loading, hasMore, filterLoading } = this.props;

    const {
      selectedBrand,
      isBrandModalShown,
      isDeactivateModalShown,
      deactivateModalLoading,
      selectedBrands,
      sortedProductList,
      sortActive,
      isBulkEditModalShown,
      isBulkDeactivateModalShown,
      lockLoadingItem,
    } = this.state;

    const activeSort = this.state.filter.order === 'desc' ? this.state.filter.sort_by : '';
    if (loading) {
      return (
        <div className="pending-loading">
          <Spin size="large" />
        </div>
      );
    }

    return (
      <div className="list-view brand-list-view">
        <div className="search-con">
          <BrandFilter
            uploadCSV={this.clickUpload}
            onSearch={this.onSearch}
            lastUploadCSV={lastUploadCsvStatus.icon}
            activateBulkActions={this.showBulkActions}
          >
            <div className="upload-csv-section">
              <Button onClick={this.clickUpload} type="default">
                <img src={uploadLogo} className="upload-csv-button" alt="csv-upload-button" />
                UPLOAD CSV
              </Button>
              <Tooltip title={`last time upload-product-csv status: ${lastUploadCsvStatus.status}`}>
                {lastUploadCsvStatus.icon}
              </Tooltip>
            </div>
          </BrandFilter>
        </div>
        <div className="list-con" id="brand-list-con" onScroll={this.loadMoreBrands}>
          <List
            className="brand-list"
            dataSource={sortActive ? sortedProductList : list}
            header={
              <>
                <span className="span-1">
                  <Checkbox
                    indeterminate={
                      selectedBrands.length > 0 && list.length !== selectedBrands.length
                    }
                    onChange={this.checkAllProducts}
                    checked={
                      this.props.list.length === selectedBrands.length &&
                      this.props.list.length !== 0
                    }
                  />
                </span>
                <span className="span-1">ACTION</span>
                <span className="span-2">IMAGE</span>
                <span className="span-2" onClick={() => this.sortItems('inner_id')}>
                  ID
                  <img
                    src={downArrow}
                    alt="header-sort-icon"
                    className="header-sort-icon"
                    style={
                      activeSort === 'inner_id'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2" onClick={() => this.sortItems('note')}>
                  NOTE
                  <img
                    src={downArrow}
                    alt="header-sort-icon"
                    className="header-sort-icon"
                    style={
                      activeSort === 'note'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2 ellipse" onClick={() => this.sortItems('name')}>
                  NAME
                  <img
                    src={downArrow}
                    alt="header-sort-icon"
                    className="header-sort-icon"
                    style={
                      activeSort === 'name'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-2" onClick={() => this.sortItems('company')}>
                  COMPANY
                  <img
                    src={downArrow}
                    alt="header-sort-icon"
                    className="header-sort-icon"
                    style={
                      activeSort === 'company'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-3" onClick={() => this.sortItems('introduction')}>
                  DESCRIPTION
                  <img
                    src={downArrow}
                    alt="header-sort-icon"
                    className="header-sort-icon"
                    style={
                      activeSort === 'introduction'
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    }
                  />
                </span>
                <span className="span-3 ellipse">IMAGE</span>
              </>
            }
            renderItem={(item: Brand) => {
              if (filterLoading) {
                return null;
              }
              return (
                <>
                  <List.Item key={`${item.inner_id}`}>
                    <span className="span-1">
                      <Checkbox
                        onChange={(_) => this.checkProduct(item.id)}
                        checked={selectedBrands.includes(item.id as never)}
                      />
                    </span>
                    <span className="span-1">
                      {lockLoadingItem === item.id ? (
                        <Button size="middle" disabled={true} loading={true}>
                          Select
                        </Button>
                      ) : (
                        <Dropdown
                          className="header-avatar-drop"
                          overlay={this.renderDropdownOverlay(item)}
                        >
                          <div className="header-logo-parent">Select</div>
                        </Dropdown>
                      )}
                    </span>
                    <span className="span-2">
                      <img
                        src={item.covers[0]?.link}
                        className="product-list-image"
                        alt="product"
                      />
                    </span>
                    <span className="span-2">#{item.inner_id}</span>
                    <span className="span-2">{item.note}</span>
                    <span className="span-2 ellipse">{item.name}</span>
                    <span className="span-2">{item.company}</span>
                    <span className="span-3">{item.introduction}</span>
                    <span className="span-3 ellipse">
                      <a href={item.covers[0].link} rel="noreferrer" target="_blank">
                        Link
                      </a>
                    </span>
                  </List.Item>
                  {isBrandModalShown && selectedBrand.id === item.id ? (
                    <List.Item className="edit-product-background">
                      <BrandEditModal
                        visible={isBrandModalShown}
                        selectedBrand={selectedBrand as Brand}
                        action="edit"
                        hide={this.hideBrandModal}
                        onSuccess={this.handleSuccess}
                        showDeactivateModal={this.showDeactivateModal(item)}
                      />
                    </List.Item>
                  ) : null}
                </>
              );
            }}
          />
          {hasMore && (
            <div className="loadmore">
              <Spin size="large" />
            </div>
          )}
        </div>

        {isBulkEditModalShown && (
          <BulkEditBrands
            visible={isBulkEditModalShown}
            selectedBrands={selectedBrands as string[]}
            brandList={this.props.list as Brand[]}
            clearSelectionData={this.clearSelectionData as never}
            closeBulkEditModal={this.closeBulkEditModal as never}
            type="approve"
          />
        )}

        <Modal
          visible={isDeactivateModalShown || isBulkDeactivateModalShown}
          title={'Discontinue Brand'}
          onOk={
            isDeactivateModalShown
              ? this.deactivateBrand
              : isBulkDeactivateModalShown
              ? this.bulkDeactivateBrand
              : () => null
          }
          onCancel={this.hideDeactivateModal}
          confirmLoading={deactivateModalLoading}
        >
          <p>Are you sure you want to discontinue this brand?</p>
        </Modal>
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    doAction(FetchBrandsAction);
    doAction(PollingUploadBrandStatusAction);
  }

  componentWillUnmount() {
    doAction(EmptyBrandsAction);
  }

  private clearSelectionData = () => {
    this.setState({
      selectedBrands: [],
    });
    doAction(FetchBrandsAction);
  };

  private closeBulkEditModal = () => {
    this.setState({
      isBulkEditModalShown: false,
    });
  };

  private onSearch = (searchKey: string) => {
    doAction(SearchBrandsAction, {
      searchKey: escapeQuery(searchKey),
      pageIdx: 1,
    });
  };

  private onPageChange = (pageIdx?: number, pageSize?: number) => {
    doAction(PageLoadingAction, { pageLoading: true });
    const { sort_by, order } = this.state.filter;
    doAction(FetchMoreBrandsAction, { pageIdx, pageSize, sort_by, order });
  };

  private clickUpload = () => {
    const { lastUploadCsvStatus } = this.props;
    if (lastUploadCsvStatus.status === 'processing') {
      message.warn('please wait for last upload csv to be finished');
      return;
    }
    doAction(MODULE_DIALOG, {
      visible: true,
      title: 'UPDATE BRANDS',
      text: `Warning:
            All brands in db will be updated! and this operation can not be revert.
            process will stop when known errors occur, but it's in the risk of unknown errors.
            go ahead if you are very sure to do this.`,
      onOk: () =>
        uploadFile('csv')
          .then(async (file) => {
            doAction(MODULE_DIALOG, { showLoading: true });
            const res = await Net.req('/brand/admin-upload-csv', { file }, 'multipart');
            if (res?.record) {
              doAction(PollingUploadBrandStatusAction, res.record);
            }
          })
          .catch((_) => message.warn(_))
          .finally(close_dialog),
      onCancel: () => {
        if (getModuleProp(MODULE_DIALOG, 'showLoading')) {
          message.warn('waiting process');
        } else {
          close_dialog();
        }
      },
    });
  };

  private showBrandModal = (item: Brand) => (e: MouseEvent<HTMLInputElement>) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      lockLoadingItem: item.id,
    });
    verifyBrandLockAPI(item.id)
      .then((response: any) => {
        this.setState({
          isBrandModalShown: true,
          selectedBrand: {
            ...item,
            updatedAt: response.data.updatedAt,
          },
          lockLoadingItem: null,
        });
      })
      .catch((_error) => {
        this.setState({
          lockLoadingItem: null,
        });
        notification['error']({
          message: 'This product is being edited by another user',
        });
      });
  };

  private hideBrandModal = () => {
    this.setState({
      isBrandModalShown: false,
      selectedBrand: {},
    });
  };

  private handleSuccess = () => {
    doAction(FetchBrandsAction);
  };

  // deactivate

  private showDeactivateModal = (item: Brand) => (e: MouseEvent<HTMLInputElement>) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      isDeactivateModalShown: true,
      selectedBrand: item,
    });
  };

  private hideDeactivateModal = () => {
    this.setState({
      isDeactivateModalShown: false,
      selectedBrand: {},
      isBulkDeactivateModalShown: false,
    });
  };

  private deactivateBrand = async () => {
    const { id } = this.state.selectedBrand as any;

    this.setState({ deactivateModalLoading: true });

    try {
      const res = await Net.req(`/brand/deactivate-brand/${id}`, {}, 'post');
      this.setState({
        deactivateModalLoading: false,
        isDeactivateModalShown: false,
      });
      if (res?.status) {
        if (res.status === 'success') {
          doAction(FetchBrandsAction);
          notification['success']({
            message: 'Deactivated brand successfully!',
          });
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
    } catch (e) {
      this.setState({
        deactivateModalLoading: false,
        isDeactivateModalShown: false,
      });
      notification['error']({
        message: 'Something went wrong!',
      });
    }
  };

  private bulkDeactivateBrand = async () => {
    const { selectedBrands } = this.state as any;
    const data = selectedBrands.map((brandItem: string) => ({
      brand: brandItem,
    }));

    this.setState({ deactivateModalLoading: true });

    try {
      const res = await bulkDeactivateBrandAPI([...data] as any);
      this.setState({
        deactivateModalLoading: false,
        isBulkDeactivateModalShown: false,
      });
      if (res?.data.status) {
        if (res.data.status === 'success') {
          doAction(FetchBrandsAction);
          notification['success']({
            message: 'Deactivated brand successfully!',
          });
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
    } catch (e) {
      this.setState({
        deactivateModalLoading: false,
        isBulkDeactivateModalShown: false,
      });
      notification['error']({
        message: 'Something went wrong!',
      });
    }
  };
}
