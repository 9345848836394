import React from 'react';
import './retailLocation.less';
import { Redirect, Route, Switch } from 'react-router-dom';
import Table from './retail_location_table/table-wrapper';
import RetailLocationDetail from './retail_location_detail/RetailLocationDetail';

const RetailLocation: React.FC = () => (
  <div className="retailLocation-list-view retailLocation-list-view">
    <div className="retailLocation-list-header">Retail Location</div>
    <Switch>
      <Route path="/retailLocationList" component={Table} />
      <Route path="/retailLocationDetail/:id" component={RetailLocationDetail} />
      <Redirect to="/retailLocationList" />
    </Switch>
  </div>
);

export default RetailLocation;
