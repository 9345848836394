import React from 'react';
import './brand_routing.less';
import ApprovedBrand from './approved_brand/approved_brand';
import DiscontinuedBrand from './discontinued_brand/discontinued_brand';
import PendingBrand from './pending_brand/pending_brand';
import { Menu } from 'antd';
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';

export const BrandRouting: React.FC = () => {
  const location = useLocation();

  return (
    <div className="list-view product-list-view">
      <div className="product-list-header">Brands</div>
      <Menu mode="horizontal" className="products-nav-panel" selectedKeys={[location.pathname]}>
        <Menu.Item key="/brandlist/approved">
          <NavLink to="/brandlist/approved">Approved</NavLink>
        </Menu.Item>
        <Menu.Item key="/brandlist/pending">
          <NavLink to="/brandlist/pending">Pending</NavLink>
        </Menu.Item>
        <Menu.Item key="/brandlist/discontinued">
          <NavLink to="/brandlist/discontinued">Discontinued</NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/brandlist/approved" render={(props) => <ApprovedBrand {...props} />} />
        <Route path="/brandlist/pending" render={(props) => <PendingBrand {...props} />} />
        <Route
          path="/brandlist/discontinued"
          render={(props) => <DiscontinuedBrand {...props} />}
        />
        <Redirect to="/brandlist/approved" />
      </Switch>
    </div>
  );
};
