import React from 'react';
import { Input, Select } from 'antd';
import './pending_brand_filter';
import { debounce } from 'lodash';

const { Option } = Select;
interface PendingBrandFilterProp {
  onSearch: any;
  activateBulkActions: any;
  children: React.ReactNode;
}

export class PendingBrandFilter extends React.Component<PendingBrandFilterProp, {}> {
  emitChangeDebounced = debounce(this.props.onSearch, 500);

  componentWillUnmount() {
    this.emitChangeDebounced.cancel();
  }

  handleSearch = (e: string) => {
    this.emitChangeDebounced(e);
  };

  render() {
    const { activateBulkActions } = this.props;

    return (
      <div className="filter-root">
        <div className="product-filter">
          <div className="filter-item">
            <Input.Search
              placeholder="Search"
              onChange={(e) => this.handleSearch(e.target.value)}
              allowClear={true}
            />
          </div>

          <Select
            onSelect={activateBulkActions}
            className="filter-search-bulk-actions"
            placeholder="Bulk Actions"
            value=""
          >
            <Option value="" disabled>
              Bulk Actions
            </Option>
            <Option value="edit">Edit</Option>
            {/* <Option value="deactivate">Deactivate</Option> */}
          </Select>
          {this.props.children}
        </div>
      </div>
    );
  }
}
