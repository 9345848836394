import logo from '../../assets/jointly-white.svg';
import { Net } from '../../utils/utils';
import { Layout, Spin, Col } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './verify.less';
const { Header, Content } = Layout;

interface Params {
  uid: string;
}

export class VerifyView extends React.Component<RouteComponentProps> {
  state = { success: false, loading: false };
  render() {
    return (
      <Layout className="verify-view">
        <Header>
          <img src={logo} alt="Jointly" />
        </Header>
        <Content>
          <Col lg={8} md={10} sm={20} xs={22}>
            {this.state.success ? (
              <div className="content-box">
                <p className="title">Success</p>
                <p className="des">Your email is successfully verified.</p>
              </div>
            ) : (
              <div className="content-box">
                {this.state.loading ? (
                  <Spin size="large" />
                ) : (
                  <p className="title">Verification Failed!</p>
                )}
              </div>
            )}
          </Col>
        </Content>
      </Layout>
    );
  }

  componentDidMount() {
    this.confirm();
  }

  private confirm = async () => {
    try {
      const { uid } = this.props.match.params as Params;
      this.setState({ loading: true });
      const res = await Net.req('/user/confirm', { id: uid }, 'post');
      if (res && res.message === 'success') {
        this.setState({ success: true, loading: false });
      } else {
        this.setState({ success: false, loading: false });
      }
    } catch (e) {
      this.setState({ success: false, loading: false });
    }
  };
}
