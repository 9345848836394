import { Organization } from '../../../models/model_organization_paginated';
import axios from '../../apiClient';

export const getRetailOrganization = async (id: string): Promise<Organization> => {
  const { data } = await axios.get('/org/v1/organization/' + id);
  return data.retailLocation;
};

export const getRetailOrganizations = async (): Promise<Organization[]> => {
  const urlParams = new URLSearchParams();
  urlParams.set('limit', '1000');
  const { data } = await axios.get('/org/v1/organization?' + urlParams.toString());
  return data.list;
};
