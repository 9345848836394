import { Input, Select, notification } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import './discontinued_brand_filter';

const { Option } = Select;

interface DiscontinuedBrandFilterProp {
  onSearch: any;
  selectedBrands: string[];
  showBulkActivateBrand: Function;
  children?: React.ReactNode;
}

export class DisontinuedBrandFilter extends React.Component<DiscontinuedBrandFilterProp, {}> {
  emitChangeDebounced = debounce(this.props.onSearch, 500);

  componentWillUnmount() {
    this.emitChangeDebounced.cancel();
  }

  handleSearch = (e: string) => {
    this.emitChangeDebounced(e);
  };

  activateBulkActions = (selectedKey: string) => {
    if (this.props.selectedBrands.length === 0) {
      notification.warning({
        message: 'Please select the brands',
      });
    } else if (selectedKey === 'activate') this.props.showBulkActivateBrand();
  };

  render() {
    return (
      <div className="filter-root">
        <div className="product-filter">
          <div className="filter-item">
            <Input.Search
              placeholder="Search"
              onChange={(e) => this.handleSearch(e.target.value)}
              allowClear={true}
            />
          </div>
          <Select
            onSelect={this.activateBulkActions}
            className="filter-search-bulk-actions"
            placeholder="Bulk Actions"
            value=""
          >
            <Option value="" disabled>
              Bulk Actions
            </Option>
            <Option value="activate">Activate</Option>
          </Select>
          {this.props.children}
        </div>
      </div>
    );
  }
}
