import { RetailProductStatusEnum } from '../utils/enum';
import { ModuleStore, ModuleAction, KV } from 'module-reaction';

const MODULE_RETAIL_PRODUCTS = 'module_retail_products';

export const module_retail_products: ModuleStore = {
  module: MODULE_RETAIL_PRODUCTS,
  matchedProductsCount: 0,
  unmatchedProductsCount: 0,
  prematchedProductsCount: 0,
  unsupportedProductsCount: 0,
};

interface ModelRetailProductsCounter extends ModuleStore {
  matchedProductsCount: boolean;
  unmatchedProductsCount: boolean;
  prematchedProductsCount: boolean;
  unsupportedProductsCount: boolean;
}

export const SetRetailProductsCount: ModuleAction<KV, ModelRetailProductsCounter> = {
  module: MODULE_RETAIL_PRODUCTS,
  name: 'SetCount',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelRetailProductsCounter) => {
    const { count, keyToUpdate } = Object.assign({}, model, payload);

    if (keyToUpdate === RetailProductStatusEnum.Mapped)
      return {
        matchedProductsCount: count,
      };
    if (keyToUpdate === RetailProductStatusEnum.NotMapped)
      return {
        unmatchedProductsCount: count,
      };
    if (keyToUpdate === RetailProductStatusEnum.PreMapped)
      return {
        prematchedProductsCount: count,
      };
    if (keyToUpdate === RetailProductStatusEnum.NotSupported)
      return {
        unsupportedProductsCount: count,
      };

    return {};
  },
};
