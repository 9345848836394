import { UserListView } from './user_list';
import { ModleUser } from '../../models/model_user';
import { Menu } from 'antd';
import React from 'react';
import './user_routing.less';
import {
  NavLink,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';

interface UserListProp extends RouteComponentProps, ModleUser {}

export const UserRouting = (props: UserListProp) => {
  const location = useLocation();

  return (
    <div className="list-view users-list-view">
      <div className="users-list-header">Users</div>
      <Menu mode="horizontal" className="products-nav-panel" selectedKeys={[location.pathname]}>
        <Menu.Item key="/users/active">
          <NavLink to="/users/active">Active</NavLink>
        </Menu.Item>
        <Menu.Item key="/users/banned">
          <NavLink to="/users/banned">Banned</NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/users/active">
          <UserListView {...props} tabItem="active" />
        </Route>
        <Route path="/users/banned">
          <UserListView {...props} tabItem="banned" />
        </Route>
        <Redirect to="/users/active" />
      </Switch>
    </div>
  );
};
