import React, { useState, useEffect, ChangeEvent } from 'react';
import './bulk_edit_brand.less';
import { Modal, Typography, Table, Input, Form, Button, notification } from 'antd';
import { Net } from '../../../utils/utils';
import { Brand } from '../../../models/model_brand';

interface Item {
  key: any;
  company: string;
  introduction: string;
  note: string;
}

interface BulkEditBrandProps {
  selectedBrands: string[];
  brandList: Brand[];
  closeBulkEditModal: Function;
  visible: boolean;
  clearSelectionData: Function;
  type: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'select' | 'text';
  record: any;
  index: number;
  children: React.ReactNode;
  processInput: Function;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  processInput,
  ...restProps
}) => {
  const inputNode = (
    <Input value={record ? record[dataIndex] : undefined} onChange={processInput as never} />
  );

  return (
    <td {...restProps} style={!editing ? { background: '#cccccc' } : {}}>
      {editing ? (
        <Form.Item
          name={`${dataIndex}-${record.key}`}
          initialValue={record ? record[dataIndex] : undefined}
          style={{ margin: 0 }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const BulkEditBrands = ({
  selectedBrands,
  brandList,
  closeBulkEditModal,
  visible,
  clearSelectionData,
  type,
}: BulkEditBrandProps) => {
  let originData = [];
  originData = brandList
    .filter((brandItem: Brand) => selectedBrands.includes(brandItem.id))
    .map((brandItem: Brand, index: number) => ({
      ...brandItem,
      key: index,
    }));

  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    let originData = [];
    originData = brandList
      .filter((brandItem: Brand) => selectedBrands.includes(brandItem.id))
      .map((brandItem: Brand, index: number) => ({
        ...brandItem,
        key: index,
      }));
    setData(originData);

    return () => {
      form.resetFields();
    };
  }, [visible, selectedBrands, brandList, form]);

  const closeModal = () => {
    form.resetFields();
    closeBulkEditModal();
  };

  const submitEditData = async () => {
    form.submit();
    form.validateFields().then(async (values) => {
      const company = values['company-'];
      const introduction = values['introduction-'];
      const note = values['note-'];
      const brandsData = data.map((brandItem) => {
        const { id, inner_id, name, covers } = brandItem;
        const data: any = {
          id,
          inner_id,
          name,
          covers: JSON.stringify(covers),
          action: type,
        };
        if (company && company.length > 0) {
          data.company = company;
        }
        if (introduction && introduction.length > 0) {
          data.introduction = introduction;
        }
        if (note && note.length > 0) {
          data.note = note;
        }
        return { ...data };
      });

      const apiRequestData = {
        brands: [...brandsData],
      };
      setSubmitting(true);
      try {
        const res = await Net.req(
          '/brand/bulk-update-pending-brand',
          { ...apiRequestData },
          'post'
        );
        form.resetFields();
        if (res?.status) {
          if (res.status === 'success') {
            notification['success']({
              message: 'Success!',
            });
          } else {
            notification['error']({
              message: 'Something went wrong!',
            });
          }
        } else {
          notification['error']({
            message: 'Something went wrong!',
          });
        }
        clearSelectionData();
        closeModal();
      } catch (error) {
        notification['error']({
          message: 'Something went wrong!',
        });
      }
      setSubmitting(false);
    });
  };

  const columns = [
    {
      title: 'Parent Company',
      dataIndex: 'company',
      width: '15%',
      editable: true,
    },
    {
      title: 'Note',
      dataIndex: 'note',
      width: '20%',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'introduction',
      width: '50%',
      editable: true,
    },
  ];

  const processInput =
    (stateItem: Item, dataIndex: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const dataList = [...data];
      dataList[stateItem.key] = {
        ...dataList[stateItem.key],
        [dataIndex]: event.target.value,
      };
      setData([...dataList]);
    };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: col.dataIndex === 'company' || 'note' || 'introduction',
        processInput: processInput(record, col.dataIndex),
      }),
    };
  });

  const dataParam: Item[] = [
    {
      key: '',
      company: '',
      introduction: '',
      note: '',
    },
  ];

  return (
    <Modal visible={visible} footer={null} width="95vw" onCancel={closeModal}>
      <Typography.Title level={3} className="edit-brand-header">
        Edit Brands
      </Typography.Title>
      <Form form={form} component={false}>
        <div className="edit-product-option-buttons">
          <Button type="link" onClick={closeModal} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={submitEditData}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={dataParam}
          columns={mergedColumns}
          rowClassName="editable-row"
        />
      </Form>
    </Modal>
  );
};

export default BulkEditBrands;
