import Loader from '../../../components/table/content-loader';
import ProductsFilter from '../../../components/table/filter';
import PaginationComponent from '../../../components/table/pagination';
import ReactTable from '../../../components/table/table';
import YesOrNo from '../../../components/YesOrNo';
import {
  model_page_loading,
  model_product,
  FetchRetailLocationAction,
  RetailLocation,
} from '../../../models/model_retail_location_paginated';
import RetailLocationModal, { Action } from '../retail_location_modal/RetailLocationModal';
import { Button } from 'antd';
import { doAction, mapProp } from 'module-reaction';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CellProps } from 'react-table';

const TableWrapper = ({ list, total, pageLoading }: Record<string, unknown>) => {
  const [selectedProduct] = useState<RetailLocation | Record<string, unknown>>({});
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const [loading, setLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<Record<string, unknown>>({});
  const [isColumnConfigModalShown, setIsColumnConfigModalShown] = useState(false);

  const onSuccess = useCallback(() => {
    doAction(FetchRetailLocationAction, {
      ...currentFilter,
      pageSize: pageSize,
      pageIdx: currentPage,
    });
  }, [pageSize, currentPage, currentFilter]);

  const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);

  useEffect(() => {
    setLoading(true);
    const timer1 = setTimeout(() => setLoading(false), 1000);
    const timer2 = setTimeout(() => onSuccess(), 200);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [onSuccess]);

  const columns = useMemo<unknown[]>(
    () => [
      {
        Header: 'Name',
        width: 200,
        accessor: 'name',
        Cell: ({ value, row: { original } }: CellProps<Record<string, string>>) => (
          <Link to={'retailLocation/' + original.id}>{value || ''}</Link>
        ),
      },
      {
        Header: 'Mobile App (iOS)',
        accessor: 'mobileEnabled',
        Cell: ({ value }: CellProps<Record<string, string>>) => <YesOrNo value={value} />,
      },
      {
        Header: 'Web Browser (Android)',
        accessor: 'mobWebEnabled',
        Cell: ({ value }: CellProps<Record<string, string>>) => <YesOrNo value={value} />,
      },
      {
        Header: 'Pickup',
        accessor: 'pickup',
        Cell: ({ value }: CellProps<Record<string, string>>) => <YesOrNo value={value} />,
      },
      {
        Header: 'Delivery',
        accessor: 'delivery',
        Cell: ({ value }: CellProps<Record<string, string>>) => <YesOrNo value={value} />,
      },
      {
        Header: 'Products',
        width: 200,
        accessor: 'totalProducts',
        Cell: ({ value }: CellProps<Record<string, string>>) => value || '',
      },
      {
        Header: 'Organization',
        width: 200,
        accessor: 'retailOrganization.id',
        Cell: ({ value }: CellProps<Record<string, string>>) => (
          <Link to={'organization/' + value}>{value || ''}</Link>
        ),
      },
      {
        Header: 'Website',
        width: 200,
        accessor: 'website',
        Cell: ({ value }: CellProps<Record<string, string>>) =>
          <p style={{ overflowWrap: 'anywhere' }}>{value}</p> || '',
      },
      {
        Header: 'Street Address',
        width: 200,
        accessor: 'address',
        Cell: ({ value }: CellProps<Record<string, string>>) => value || '',
      },
      {
        Header: 'State',
        width: 200,
        accessor: 'state',
        Cell: ({ value }: CellProps<Record<string, string>>) => value || '',
      },
      {
        Header: 'Zip Code',
        width: 200,
        accessor: 'zipcode',
        Cell: ({ value }: CellProps<Record<string, string>>) => value || '',
      },
      {
        Header: 'Phone Number',
        width: 200,
        accessor: 'phoneNumber',
        Cell: ({ value }: CellProps<Record<string, string>>) => value || '',
      },
      {
        Header: 'has BCC Email',
        width: 200,
        accessor: 'bccEmail',
        Cell: ({ value }: CellProps<Record<string, string>>) => <YesOrNo value={!!value} />,
      },
      {
        Header: 'GPS Coords',
        width: 200,
        accessor: 'geoLocation',
        Cell: ({ value }: CellProps<Record<string, string>>) => (
          <p style={{ overflowWrap: 'anywhere' }}>
            {(!!value?.coordinates && JSON.stringify(value?.coordinates)) || ''}
          </p>
        ),
      },
      {
        Header: 'POS Provider',
        width: 200,
        accessor: 'pos',
        Cell: ({ value }: CellProps<Record<string, string>>) =>
          (!!value && value.type + ', ' + value.name) || '',
      },
      {
        Header: 'Minimum stock',
        width: 200,
        accessor: 'pos.stockMinimum',
        Cell: ({ value }: CellProps<Record<string, string>>) => value || '',
      },
      {
        Header: 'Shop Domain',
        width: 200,
        accessor: 'pos.connectionInfo.shopifyDomain',
        Cell: ({ value }: CellProps<Record<string, string>>) => value || '',
      },
      {
        Header: 'Users',
        width: 200,
        accessor: 'users',
        Cell: ({ value }: CellProps<Record<string, string>>) => (!!value && value.length) || '',
      },
      {
        Header: 'ID',
        accessor: 'id',
        id: '_id',
        Cell: ({ value }: CellProps<Record<string, string>>) => (
          <div className="break-word">{value || ''}</div>
        ),
      },
    ],
    []
  );

  return !!list && !!columns ? (
    <div className="tableAndFilterWrapper" style={{ height: '100%' }}>
      <div className="search-con">
        <ProductsFilter
          onFilter={(res: Record<string, unknown>) => {
            setCurrentFilter(res);
          }}
          activateBulkActions={() => true}
          currentFilter={currentFilter}
          setCurrentPage={setCurrentPage}
          isBulkNeeded={false}
        >
          {userDetails?.role?.includes('admin_user') && (
            <div className="add-product-button">
              <Button
                onClick={() => !isSelected && setShowCreateModal(true)}
                type="primary"
                className="add-product-text"
              >
                + Add Retail Location
              </Button>
            </div>
          )}
        </ProductsFilter>
      </div>

      <div className="tableWrapper">
        {(loading || pageLoading) && (
          <div className="loaderCover">
            <Loader />
          </div>
        )}
        <ReactTable
          data={list as unknown[]}
          columns={columns}
          selectedItem={selectedProduct}
          isEditModalShown={false}
          setMultiSelectType={() => true}
          setSelectedItens={() => true}
          setIsColumnConfigModalShown={setIsColumnConfigModalShown}
          isColumnConfigModalShown={isColumnConfigModalShown}
        />
        <br />
        <PaginationComponent
          totalRows={total as number}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={pageSize}
          setPageSize={setPageSize}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setIsColumnConfigModalShown={setIsColumnConfigModalShown}
        />
        <br />
        {showCreateModal && (
          <RetailLocationModal
            visible={showCreateModal}
            action={Action.add}
            onRequestClose={() => {
              onSuccess();
              setShowCreateModal(false);
              setIsSelected(false);
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

@mapProp(model_page_loading, 'pageLoading', 'filterLoading')
@mapProp(model_product)
class Table extends React.Component<Record<string, unknown>> {
  render(): React.ReactNode {
    return <TableWrapper {...this.props} />;
  }
}

export default Table;
