import './retailUser_add_modal.less';
import { getRetailOrganizations } from '../../../services/api/organizationAPI';
import { getRetailLocations } from '../../../services/api/retailLocationAPI';
import { addRetailUser } from '../../../services/api/retailUserAPI';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Input,
  Modal,
  Select,
  notification,
  Button,
  Form,
  FormInstance,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { AxiosError } from 'axios';
import React, { useState, createRef, useEffect } from 'react';

type Props = {
  visible: boolean;
  onRequestClose: () => void;
};

const RetailUserAddModal = ({ visible, onRequestClose }: Props) => {
  const [email, setEmail] = useState('');
  const [scope, setScope] = useState('organization');
  const [organizationId, setOrganizationId] = useState('');
  const [locationId, setLocationId] = useState('');

  const [form] = Form.useForm();

  const formRef = createRef<FormInstance>();

  const queryClient = useQueryClient();

  const onSuccess = () => {
    form.resetFields();
    notification.success({
      message: 'Updated successfully',
    });
    queryClient.invalidateQueries({ queryKey: ['getRetailLocation'] });
    onRequestClose();
  };

  const onError = (err: AxiosError) => {
    notification.error({
      message: err?.response?.data?.message || 'Something went wrong!',
    });
  };

  const addMutation = useMutation({
    mutationFn: (_addRetailUser: any) => addRetailUser(_addRetailUser),
    onSuccess,
    onError,
  });

  const initialValues = {
    email: '',
    scope: 'organization',
    organizationId: '',
    locationId: '',
    permission: '',
  };

  const handleOnChangeScope = (e: RadioChangeEvent) => {
    setScope(e.target.value);
    setLocationId('');
  };

  const retailOrganizationsQuery = useQuery({
    queryKey: ['getRetailOrganizations'],
    queryFn: getRetailOrganizations,
    refetchOnWindowFocus: false,
  });

  const retailLocationsQuery = useQuery({
    queryKey: ['getRetailLocations', organizationId],
    queryFn: () => getRetailLocations({ organizationId: organizationId }),
    refetchOnWindowFocus: false,
  });

  const permissionList = scope === 'organization' ? ['owner'] : ['admin', 'manager'];

  useEffect(() => {
    form.setFieldsValue({ permission: '' });
    if (scope === 'organization') {
      form.setFieldsValue({ locationId: '' });
    }
  }, [form, scope]);

  return (
    <Modal
      visible={visible}
      confirmLoading={addMutation.isLoading}
      footer={null}
      width="966px"
      onCancel={() => {
        onRequestClose();
        form.setFieldsValue(initialValues);
      }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={(formData) => {
          const { email, scope, organizationId, locationId, permission } = formData;

          const payload = {
            email,
            ...(scope === 'organization' && { organizationId }),
            ...(scope === 'location' && { locationId }),
            locationId,
            permission,
          };
          addMutation.mutate(payload);
        }}
        initialValues={initialValues}
        ref={formRef}
      >
        <div
          className="product-modal-title"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          Add Retail User
          <div className="edit-product-option-buttons">
            <Button
              type="link"
              className="cancel-button"
              onClick={() => {
                onRequestClose();
                form.setFieldsValue(initialValues);
              }}
            >
              Cancel
            </Button>
            <span className="add-product-space" />
            <Button
              type="primary"
              htmlType="submit"
              loading={addMutation.isLoading}
              disabled={addMutation.isLoading}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="approve-modal-product approve-modal">
          <div className="product-modal-col-2">
            <div className="form-item">
              <div>Email</div>
              <Form.Item name="email" rules={[{ required: true }]}>
                <Input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Type</div>
              <Form.Item name="scope" rules={[{ required: true }]}>
                <Radio.Group onChange={handleOnChangeScope} value={scope} defaultValue={scope}>
                  <Radio value={'organization'}>Organization level</Radio>
                  <Radio value={'location'}>Location level</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="form-item">
              <div>Organization</div>
              <Form.Item name="organizationId" rules={[{ required: scope === 'organization' }]}>
                <Select
                  showSearch
                  placeholder="Organization"
                  optionFilterProp="children"
                  className="add-product-input-full-component"
                  onChange={(value) => {
                    setOrganizationId(value);
                    setLocationId('');
                  }}
                  value={organizationId}
                  defaultValue={organizationId}
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {retailOrganizationsQuery.data?.map(({ name, id }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            {scope === 'location' && (
              <div className="form-item">
                <div>Location</div>
                <Form.Item name="locationId" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    placeholder="Organization"
                    optionFilterProp="children"
                    className="add-product-input-full-component"
                    onChange={(value) => setLocationId(value)}
                    value={locationId}
                    defaultValue={locationId}
                    filterOption={(input, option) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {retailLocationsQuery.data?.map(({ name, id }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}

            <div className="form-item">
              <div>Permission Profile</div>
              <Form.Item name="permission" rules={[{ required: true }]}>
                <Select
                  key={scope}
                  placeholder="Permission"
                  optionFilterProp="children"
                  className="add-product-input-full-component"
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {permissionList?.map((permission: string) => (
                    <Select.Option key={permission} value={permission}>
                      {permission}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default RetailUserAddModal;
