import React, { useEffect } from 'react';
import '../../../components/table/table.less';
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';
import ColumnsConfig from '../../../components/table/column-config';

type Props = {
  data: any[];
  columns: any[];
  selectedItem: any;
  setMultiSelectType: Function;
  setSelectedItens: Function;
  setIsColumnConfigModalShown: Function;
  isColumnConfigModalShown: boolean;
  getTrProps?: any;
};

const ReactTable = ({
  data,
  columns,
  setMultiSelectType,
  setSelectedItens,
  setIsColumnConfigModalShown,
  isColumnConfigModalShown,
  getTrProps,
}: Props) => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    isAllRowsSelected,
    setHiddenColumns,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      defaultColumn,
      manualSortBy: true,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (isAllRowsSelected) {
      setMultiSelectType('whole');
    } else if (Object.keys(selectedFlatRows).length) {
      setMultiSelectType('multi');
    } else {
      setMultiSelectType('none');
    }
    setSelectedItens(selectedFlatRows.map((item) => item.original));
  }, [selectedFlatRows, setSelectedItens, isAllRowsSelected, setMultiSelectType]);

  return (
    <>
      <table {...getTableProps()}>
        <thead className="sticky">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th
                  className="colunmHeader"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render('Header')}

                  {!['selection', 'action'].includes(column.id) && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, index) => {
            prepareRow(row);
            return (
              <React.Fragment key={index}>
                <tr {...row.getRowProps()} onClick={(e) => getTrProps(row, e)}>
                  {row.cells.map((cell: any, index: number) => (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {/* Column configuration */}
      <ColumnsConfig
        visible={isColumnConfigModalShown}
        onCancel={() => setIsColumnConfigModalShown(false)}
        colums={allColumns}
        setHiddenColumns={setHiddenColumns}
      />
    </>
  );
};

export default ReactTable;
