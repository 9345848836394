import { getToken } from '../../utils/utils';
import { notification } from 'antd';
import axios from 'axios';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.baseURL = process.env.REACT_APP_BASE_URL;
    config.headers.Authorization = 'Bearer ' + getToken();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    notification.error({ message: 'Something went wrong!' });
    return Promise.reject(error);
  }
);

export default axios;
