import axios from '../../apiClient';
import { QueryFunctionContext } from '@tanstack/react-query';

export const getActiveBrand = async () => {
  const { data } = await axios.get('/brand/admin-search?status=active');
  if (data.list) {
    return data.list.map((brand: { id: string; name: string }) => ({
      id: brand.id,
      name: brand.name,
    }));
  }
  return [];
};

type Brand = {
  id: string;
  inner_id: string;
  name: string;
  company: string;
  covers: {
    link: string;
  }[];
  introduction: string;
  note: string;
  legal_states: string[];
};

export const getBrands = async ({
  queryKey,
}: QueryFunctionContext<[string, { searchKey?: string; skip: number; limit: number }]>): Promise<
  Brand[]
> => {
  const [, { searchKey, skip, limit }] = queryKey;
  const { data } = await axios.get(
    `/brand/search?search_key=${searchKey}&skip=${skip}&limit=${limit}&status=active`
  );
  return data;
};
