import confirmDialog from '../../../components/confirm/confirm-modal';
import { ProductFilter } from '../../../models/model_products_paginated';
import {
  editMultipleProductAPI,
  editMultipleProductsPaginatedAPI,
} from '../../../services/api/productAPI/editProductAPI';
import { callError } from '../../../utils/JSXutils';
import { States } from '../../../utils/options';
import { Modal, Typography, Table, Input, Form, Select, Button, notification } from 'antd';
import React, { useState, useEffect, ChangeEvent } from 'react';
import './bulk_edit_product.less';

interface Item {
  key: string;
  name: string;
  note: string;
  ex_store_url: string;
  legal_states: [];
  inner_id: string;
}

interface BulkEditProductProps {
  selectedProducts: any[];
  closeBulkEditModal: Function;
  open: boolean;
  clearSelectionData: Function;
  type: string;
  multiSelectType: string;
  query: ProductFilter | { collection: string };
  total: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'select' | 'text';
  record: any;
  index: number;
  children: React.ReactNode;
  processInputState: Function;
  processInput: Function;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  processInputState,
  processInput,
  ...restProps
}) => {
  const states = States.map(({ name, abbreviation }) => ({
    label: name,
    value: abbreviation,
  }));
  const inputNode =
    inputType === 'select' ? (
      <Select
        mode="multiple"
        options={states}
        value={record ? record[dataIndex] : undefined}
        onChange={processInputState as never}
      />
    ) : (
      <Input value={record ? record[dataIndex] : undefined} onChange={processInput as never} />
    );

  return (
    <td {...restProps} style={!editing ? { background: '#cccccc' } : {}}>
      {editing ? (
        <Form.Item
          name={`${dataIndex}-${record.key}`}
          initialValue={record ? record[dataIndex] : undefined}
          style={{ margin: 0 }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const BulkEditProduct = ({
  selectedProducts,
  closeBulkEditModal,
  open,
  clearSelectionData,
  multiSelectType,
  query,
  total,
}: BulkEditProductProps) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<Item[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    let originData: Item[] = [];
    originData = selectedProducts.map((productItem: any, index: number) => ({
      ...productItem,
      key: index,
    }));
    setData(originData);
  }, [open, selectedProducts]);

  const closeModal = () => {
    form.resetFields();
    closeBulkEditModal();
  };

  const submitEditData = async () => {
    form.submit();
    form.validateFields().then(async (values) => {
      const ex_store_url = values['ex_store_url-'];
      const legal_states = values['legal_states-'];
      const note = values['note-'];
      const productsData = data.map((productItem) => {
        const { id } = productItem as any;
        const data: any = { _id: id };
        if (ex_store_url && ex_store_url.length > 0) {
          data.ex_store_url = ex_store_url;
        }
        if (legal_states && legal_states.length > 0) {
          data.legal_states = JSON.stringify(
            legal_states.includes('all') ? States.map((s) => s.abbreviation) : legal_states
          );
        }
        if (note && note.length > 0) {
          data.note = note;
        }
        return { ...data };
      });

      const closure = () => {
        setSubmitting(false);
        clearSelectionData();
        closeModal();
      };

      try {
        if (multiSelectType === 'multi') {
          await confirmDialog(`${productsData.length} Products will be affected, Are you sure?`);
          setSubmitting(true);
          const res = await editMultipleProductAPI([...productsData]);
          form.resetFields();
          notification['success']({
            message: res.data.updated + ' products updated!',
          });
          closure();
        } else if (multiSelectType === 'whole') {
          await confirmDialog(
            `This action will be applied over the current search filter, ${total} Products will be affected, Are you sure?`
          );
          setSubmitting(true);
          const modifiersObject = {
            ...(!!ex_store_url && { ex_store_url }),
            ...(!!note && { note }),
            ...(!!legal_states && !!legal_states.length && { legal_states }),
          };

          if (!Object.keys(query).length || !Object.keys(modifiersObject).length)
            return callError();

          const res = await editMultipleProductsPaginatedAPI(query, modifiersObject);
          form.resetFields();
          if (res?.data.status) {
            if (res.data.status === 'success') {
              notification['success']({
                message: `${res?.data?.updated} products updated`,
              });
            } else {
              callError();
            }
          } else {
            callError();
          }
          closure();
        } else {
          callError();
          closure();
        }
      } catch (error) {
        callError();
      }
    });
  };

  const columns = [
    {
      title: 'Note',
      dataIndex: 'note',
      width: '25%',
      editable: true,
    },
    {
      title: 'Store URL',
      dataIndex: 'ex_store_url',
      width: '40%',
      editable: true,
    },
    {
      title: 'Legal States',
      dataIndex: 'legal_states',
      width: '20%',
      editable: true,
      render: (_: any, record: Item) => record.legal_states.join(', '),
    },
  ];

  const processInputState =
    (stateItem: any, dataIndex: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const dataList = [...data];
      dataList[stateItem.key] = {
        ...dataList[stateItem.key],
        [dataIndex]: event,
      };
      setData([...dataList]);
    };

  const processInput =
    (stateItem: any, dataIndex: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const dataList = [...data];
      dataList[stateItem.key] = {
        ...dataList[stateItem.key],
        [dataIndex]: event.target.value,
      };
      setData([...dataList]);
    };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'legal_states' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: col.dataIndex === 'legal_states' || 'note' || 'ex_store_url',
        processInputState: processInputState(record, col.dataIndex),
        processInput: processInput(record, col.dataIndex),
      }),
    };
  });

  const dataParam: Item[] = [
    {
      key: '',
      name: '',
      note: '',
      ex_store_url: '',
      legal_states: [],
      inner_id: '',
    },
  ];

  return (
    <Modal open={open} footer={null} onCancel={closeModal} width="95vw">
      <Typography.Title level={3} className="bulk-edit-product-header">
        {multiSelectType === 'whole'
          ? `Edit ${total} Products`
          : `Edit ${selectedProducts.length} Products`}
      </Typography.Title>
      <Form form={form} component={false}>
        <div className="edit-product-option-buttons">
          <Button type="link" onClick={closeModal} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={submitEditData}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={dataParam}
          columns={mergedColumns}
          rowClassName="editable-row"
        />
      </Form>
    </Modal>
  );
};

export default BulkEditProduct;
