import { Promotion } from '../../models/model_retail_location_paginated';

export const parsePromotionValue = (promotion: Promotion): string | number => {
  if (promotion.valueType === 'boolean') {
    if (!promotion.value) return 'No';
    return 'Yes';
  }
  if (promotion.valueType === 'number' && typeof promotion.value === 'number')
    return promotion.value;
  return 'Unknown';
};
