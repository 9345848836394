import './pending_brand/pending_brand_list.less';
import uploadIcon from '../../assets/product-edit-form/upload-icon.svg';
import ProductImg from '../../assets/product.svg';
import { updateBrand } from '../../services/api/brandAPI/updateBrandAPI';
import { uploadBrandImageUtil } from '../../services/api/utils/uploadImageUtil';
import { BrandRequest, BrandModalProp } from '../../utils/interfaces/brand_interfaces';
import { getSizeInUnits } from '../../utils/utils';
import { Input, Modal, Typography, Upload, notification, Form, Button, FormInstance } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';
import React, { Component, createRef } from 'react';

const { TextArea } = Input;

const getBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export class BrandModal extends Component<BrandModalProp, object> {
  state = {
    selectedBrand: {},
    approvedBrand: {},
    approveModalLoading: false,
    previewBrandImage: '',
    adminBrandImage: null,
    imageSize: '',
    imageFormat: '',
  };
  formRef = createRef<FormInstance>();
  render() {
    const {
      approvedBrand,
      approveModalLoading,
      previewBrandImage,
      selectedBrand,
      imageSize,
      imageFormat,
    } = this.state;

    const brandFileDialogProps: UploadProps = {
      name: 'brand-file',
      multiple: false,
      maxCount: 1,
      fileList: [],
      beforeUpload: this.uploadBrandFile,
      onRemove: this.removeBrandFile,
    };

    let title = '';
    switch (this.props.action) {
      case 'new':
        title = 'Add Brand';
        break;
      case 'pending-edit':
        title = 'Edit Brand';
        break;
      case 'approve':
        title = 'Approve Brand';
        break;
      case 'edit':
        title = 'Edit Brand';
        break;
      default:
        break;
    }

    const isNew = Object.keys(selectedBrand).length === 0;
    const { company, name, introduction, note } = selectedBrand as BrandRequest;
    const initialValues = {
      company,
      name,
      introduction,
      note,
    };

    return (
      <Modal
        open={this.props.visible}
        confirmLoading={approveModalLoading}
        footer={null}
        width="966px"
        onCancel={this.hideApproveModal}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.approveNewBrand}
          initialValues={initialValues}
          ref={this.formRef}
        >
          <div className="product-modal-title" data-testid="modal-title">
            {title}
            <div className="edit-product-option-buttons">
              <Button type="link" className="cancel-button" onClick={this.hideApproveModal}>
                Cancel
              </Button>
              <span className="add-brand-space" />
              <Button
                type="primary"
                htmlType="submit"
                loading={approveModalLoading}
                disabled={approveModalLoading}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="approve-modal">
            <div className="product-modal-col-1">
              {!isNew && (
                <>
                  <Typography>Image</Typography>

                  {(selectedBrand as BrandRequest)?.covers && (
                    <img
                      alt="product"
                      src={(selectedBrand as BrandRequest).covers[0].link}
                      className="prod-img"
                    />
                  )}
                </>
              )}

              <div className="product-image-section">
                <Typography.Text strong className="brand-image-text">
                  {isNew ? 'Brand image' : 'Want to add a different image?'}
                </Typography.Text>
                <Upload {...brandFileDialogProps} className="product-image-container">
                  <div className="product-image-container">
                    <img
                      src={previewBrandImage || ProductImg}
                      alt="brand"
                      className="product-image"
                      id="add-brand-image"
                    />
                    <div className="product-upload-button">
                      <img src={uploadIcon} alt="upload-icon" className="upload-button-icon" />
                      Upload
                    </div>
                  </div>
                </Upload>
                {previewBrandImage && (
                  <div className="product-edit-image-text">
                    {imageFormat} - {getSizeInUnits(imageSize)}
                    <br />
                    Size: {document.getElementById('add-brand-image')?.clientWidth}x
                    {document.getElementById('add-brand-image')?.clientHeight}
                    px
                  </div>
                )}
                <div className="product-edit-bottom-image-description">
                  Image is best if 200x200px min. Format .jpg, .png
                </div>
              </div>
            </div>
            <div className="new-brand-form">
              <div className="form-item">
                <div>Name</div>
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input
                    placeholder="Brand name"
                    onChange={this.onApproveChange('name')}
                    value={(approvedBrand as BrandRequest).name}
                  />
                </Form.Item>
              </div>
              <div className="form-item">
                <div>Parent Company Name</div>
                <Form.Item name="company" rules={[{ required: false }]}>
                  <Input
                    placeholder="Parent Company Name"
                    onChange={this.onApproveChange('company')}
                    value={(approvedBrand as BrandRequest).company}
                  />
                </Form.Item>
              </div>
              <div className="form-item">
                <div>Description</div>
                <Form.Item name="introduction" rules={[{ required: true }]}>
                  <TextArea
                    className="introduction-text-area"
                    placeholder="Description"
                    onChange={this.onApproveChange('introduction')}
                    value={(approvedBrand as BrandRequest).introduction}
                  />
                </Form.Item>
              </div>

              <div className="form-item">
                <div>Note</div>
                <Form.Item name="note" rules={[{ required: false }]}>
                  <Input
                    placeholder="Note"
                    onChange={this.onApproveChange('note')}
                    value={(approvedBrand as BrandRequest).note}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: BrandModalProp) {
    if (nextProps.visible) {
      this.showApproveModal(nextProps.selectedBrand);
    }
  }

  // Brand Form

  private uploadBrandFile = (file: RcFile) => {
    getBase64(file).then((res) => {
      this.setState({
        previewBrandImage: res,
        imageSize: file.size / 1000,
        imageFormat: file.type.split('/')[1].toUpperCase(),
      });
    });

    this.setState({
      adminBrandImage: file,
    });
    return false;
  };

  private removeBrandFile = () => {
    this.setState({
      adminBrandImage: null,
      previewBrandImage: '',
    });
  };

  // End of Brand form

  // Approve form

  private showApproveModal = (item: {}) => {
    this.setState({
      approvedBrand: item,
      selectedBrand: item,
      previewBrandImage: '',
      adminBrandImage: null,
    });
    const { company, name, introduction, note } = item as BrandRequest;
    const initialValues = {
      company,
      name,
      introduction,
      note,
    };
    this.formRef.current?.setFieldsValue(initialValues);
  };

  private hideApproveModal = () => {
    this.setState({
      approvedBrand: {},
      selectedBrand: {},
      previewBrandImage: '',
      adminBrandImage: null,
    });
    this.formRef.current?.resetFields();
    this.props.hide();
  };

  private onApproveChange =
    (field: string) =>
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const { approvedBrand } = this.state;
      this.setState({
        approvedBrand: {
          ...approvedBrand,
          [field]: e.target.value,
        },
      });
    };

  private approveNewBrand = async () => {
    const { approvedBrand, adminBrandImage } = this.state;

    const { id, inner_id, name, company, introduction, note, covers, updatedAt } =
      approvedBrand as BrandRequest;

    if (!(name && introduction)) {
      return;
    }

    const formData: BrandRequest = {
      id,
      inner_id,
      name,
      company,
      introduction,
      note,
      covers: JSON.stringify(covers),
      action: this.props.action,
      updatedAt,
    };

    this.setState({ approveModalLoading: true });

    try {
      if (adminBrandImage) {
        const adminImageRes = await uploadBrandImageUtil(adminBrandImage, inner_id);

        if (adminImageRes && adminImageRes.data.link) {
          formData.link = adminImageRes.data.link;
        }
      } else if (this.props.action === 'new') {
        notification['error']({
          message: 'Please upload the product image.',
        });
        this.setState({ approveModalLoading: false });
        return;
      }

      const res = await updateBrand(formData);
      this.setState({ approveModalLoading: false });
      this.hideApproveModal();
      if (res?.status) {
        if (res.data.status === 'success') {
          this.props.onSuccess && this.props.onSuccess();
          notification['success']({
            message: 'Success!',
          });
        } else {
          notification['error']({
            message: res.data?.message,
          });
        }
      } else {
        notification['error']({
          message: (res && res.data.message) || 'Something went wrong!',
        });
      }
    } catch (e: any) {
      this.setState({ approveModalLoading: false });
      this.hideApproveModal();
      notification['error']({
        message: (e.response && e.response.data.message) || 'Something went wrong!',
      });
    }
  };

  // end approve
}
