import { BrandListView } from './brand_list';
import { BrandListProp } from '../../../models/model_brand';
import React from 'react';
import { RouteComponentProps } from 'react-router';

const ApprovedBrand = (props: RouteComponentProps | BrandListProp) => (
  <BrandListView {...(props as BrandListProp)} />
);

export default ApprovedBrand;
