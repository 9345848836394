import { MODULE_DIALOG, close_dialog } from '../../../models/model_dialog';
import {
  Product,
  getProductDetail,
  cannabinoid_enums,
  model_product_reports,
  ModelProductReports,
  FetchProductReportsAction,
  getOptionEnum,
  ProductReportStat,
} from '../../../models/model_product';
import { Net } from '../../../utils/utils';
import {
  Button,
  Spin,
  Layout,
  Row,
  Col,
  Input,
  List,
  Pagination,
  Checkbox,
  message,
  DatePicker,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { doAction, KV, mapProp, getModuleProp } from 'module-reaction';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './product_detail.less';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface ProductDetailProp extends RouteComponentProps, ModelProductReports {}

@mapProp(model_product_reports)
export class ProductDetailView extends React.Component<ProductDetailProp> {
  state: { product: Product; selectedIds: Set<string> } = {
    product: undefined as any,
    selectedIds: new Set(),
  };

  render() {
    if (!this.state.product) {
      return <Spin />;
    }

    const product: Product = this.state.product!;
    const { list, pageIdx, pageSize, total } = this.props;
    const { selectedIds } = this.state;
    const goal_enum = getOptionEnum('goal');
    return (
      <div className="product-detail-view">
        <Button type="link" onClick={(e) => this.props.history.goBack()}>
          {'<BACK'}
        </Button>
        <Layout>
          <Row>
            <Col className="header-block" span={8}>
              <div className="info-id">#{product.inner_id}</div>
              <div className="info-name">{product.name}</div>
              <div className="info-status">{product.status}</div>
              <div className="info-other">
                Brand:<span>{product.brand_name}</span>
              </div>
              <div className="info-other">
                Type:
                <span>
                  {product.product_type
                    ? `${product.product_type.type}:${product.product_type.specific}`
                    : 'unknown'}
                </span>
              </div>
              <div className="info-other">
                Strain Type:
                <span>{product.strain_type || 'unknown'}</span>
              </div>
              <div className="info-other">
                Strain:
                <span>{product.strain || 'unknown'}</span>
              </div>
              <div className="info-other">
                Flavor:
                <span>{product.flavor || 'unknown'}</span>
              </div>
              <div className="btns">
                <Button href={product.ex_store_url} type="primary" target="view_window">
                  FIND STORE
                </Button>
              </div>
              <img src={product.covers[0].link} alt="Img" />
            </Col>
            <Col className="header-block" span={15}>
              <div className="info-title">DESCRIPTION</div>
              <div className="info-des">{product.introduction || 'unknown'}</div>
              <div className="info-title">STATE(IN WHICH LEGAL)</div>
              <div className="info-states">{product.legal_states?.join(',')}</div>
              <div className="info-title">CANNABINOID</div>
              <div className="info-cannabinoids">
                {cannabinoid_enums.filter((_) => (product as KV)[_]).join(',') || 'unknown'}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="review-top-bar">
              <span className="review-tab">Review</span>
              <Search placeholder="keyword" onSearch={this.onSearch} />
              <RangePicker onChange={this.onDateRangeChg} />
              <Button type="primary" disabled={selectedIds.size === 0} onClick={this.delReports}>
                DELETE REPORTS
              </Button>
            </div>
          </Row>
          <div className="list-con">
            <List
              className="report-list"
              dataSource={list}
              header={
                <>
                  <Checkbox className="check-ico" onChange={this.selectAll} />
                  <span className="span-3">EMAIL</span>
                  <span className="span-2">USERNAME</span>
                  <span className="span-1">ROLE</span>
                  <span className="span-2">REPORT CREATION DATE</span>
                  <span className="span-2">LAST REPORT TIMESTAMP</span>
                  {Object.keys(goal_enum).map((key) => (
                    <span className="span-1" key={`show-goal-score-${key}`}>
                      {goal_enum[key]}
                    </span>
                  ))}
                </>
              }
              renderItem={(item: ProductReportStat) => (
                <List.Item key={`${item.id}`}>
                  <Checkbox
                    className="check-ico"
                    checked={selectedIds.has(item.id)}
                    onChange={(e) => this.selectOne(item.id, e.target.checked)}
                  />
                  <span className="span-3">{item.reporter.email}</span>
                  <span className="span-2">{item.reporter.username}</span>
                  <span className="span-1">{item.reporter.role}</span>
                  <span className="span-2">{item.created_at}</span>
                  <span className="span-2">{item.update_at}</span>
                  {Object.keys(goal_enum).map((key) => (
                    <span className="span-1" key={`value-goal-score-${key}`}>
                      {item.goal_score[key] || '-'}
                    </span>
                  ))}
                </List.Item>
              )}
            />
          </div>
          <Pagination
            className="bottom-pagination"
            size="small"
            pageSize={pageSize}
            current={pageIdx}
            total={total}
            showSizeChanger={true}
            showQuickJumper={true}
            onChange={this.onPageChange}
            onShowSizeChange={this.onPageChange}
          />
        </Layout>
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    const { id } = this.props.match.params as any;

    getProductDetail(id).then((product) => {
      if (product) {
        this.setState({ product });
        this.onSearch();
      }
    });
  }

  private onSearch = (searchKey?: string) => {
    doAction(FetchProductReportsAction, {
      product: this.state.product!.id,
      searchKey,
      pageIdx: 1,
    });
  };

  private onPageChange = (pageIdx?: number, pageSize?: number) => {
    doAction(FetchProductReportsAction, {
      product: this.state.product!.id,
      pageIdx,
      pageSize,
    });
  };

  private selectAll = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const { list } = this.props;
    const { selectedIds } = this.state;
    if (checked) {
      list.forEach((item) => selectedIds.add(item.id));
    } else {
      selectedIds.clear();
    }
    this.setState({ selectedIds });
  };

  private selectOne(id: string, checked: boolean) {
    const { selectedIds } = this.state;
    checked ? selectedIds.add(id) : selectedIds.delete(id);
    this.setState({ selectedIds });
  }

  private onDateRangeChg = (dts: any, datesString: string[]) => {
    datesString = datesString.filter((_) => _);
    doAction(FetchProductReportsAction, {
      product: this.state.product!.id,
      dates: datesString,
    });
  };

  private delReports = () => {
    const { selectedIds } = this.state;
    doAction(MODULE_DIALOG, {
      visible: true,
      title: 'DELETE REPORTS',
      text: ' Do you want to delete selected reports? ',
      onOk: async () => {
        doAction(MODULE_DIALOG, { showLoading: true });
        const res = await Net.req(
          '/report/admin-del-reports',
          { reports: Array.from(selectedIds) },
          'post'
        );
        if (res?.message === 'success') {
          message.info('success!');
          selectedIds.clear();
          this.setState({ selectedIds });
          this.onSearch();
          close_dialog();
        }
      },
      onCancel: () => {
        if (getModuleProp(MODULE_DIALOG, 'showLoading')) {
          message.warn('waiting process');
        } else {
          close_dialog();
        }
      },
    });
  };
}
