import NewSetting from './NewSetting';
import Setting from './Setting';
import { getSettings } from '../../services/api/settings';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Spin, Alert, Layout, Select, Typography, Space, Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;

const Main = () => {
  const history = useHistory();
  const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);
  const [selectedSetting, setSelectedSetting] = useState('');
  const [newSettingName, setNewSettingName] = useState('');
  const [addMode, setAddMode] = useState(false);

  useEffect(() => {
    if (!userDetails.role.includes('admin_user')) {
      history.goBack();
    }
  }, [userDetails]);

  const settingsQuery = useQuery({
    queryKey: ['getSettings'],
    queryFn: () => getSettings(),
  });

  if (settingsQuery.isLoading)
    return (
      <Layout
        style={{
          height: 400,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </Layout>
    );

  if (settingsQuery.isError)
    return (
      <Layout style={{ alignItems: 'center' }}>
        <Alert message="Error" description="Unable to load settings" type="error" showIcon />
      </Layout>
    );

  return (
    <div>
      <Title level={4}>
        General settings {addMode && <PlusOutlined style={{ color: '#5344ff' }} />}
      </Title>
      <Space>
        {addMode ? (
          <>
            <Input
              style={{ width: 280 }}
              placeholder="Setting name"
              onChange={(e) => setNewSettingName(e.target.value)}
            />
            <Button
              onClick={() => {
                setAddMode(false);
                setNewSettingName('');
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Select
              style={{ width: 280 }}
              value={selectedSetting}
              onChange={(value: string) => {
                setSelectedSetting(value);
              }}
              options={settingsQuery.data?.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setAddMode(true);
                setSelectedSetting('');
              }}
            >
              Add
            </Button>
          </>
        )}
      </Space>

      <br />
      <br />
      {!!addMode && !!newSettingName ? (
        <NewSetting
          setting={newSettingName}
          onSuccess={() => {
            setAddMode(false);
            setNewSettingName('');
          }}
        />
      ) : (
        <Setting
          setting={selectedSetting}
          onSuccessDeleting={() => {
            setSelectedSetting('');
          }}
        />
      )}
    </div>
  );
};

export default Main;
