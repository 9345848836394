import React, { useState } from 'react';
import './brand-edit-modal.less';
import uploadIcon from '../../../assets/product-edit-form/upload-icon.svg';
import TextInputComponent from '../../../components/text-input-component/text-input-component';
import { Brand } from '../../../models/model_brand';
import { editBrandAPI } from '../../../services/api/brandAPI/editBrandAPI';
import { releaseBrandLockAPI } from '../../../services/api/brandAPI/releaseBrandEditLock';
import { BrandModalProp, BrandEditFormData } from '../../../utils/interfaces/brand_interfaces';
import { getBase64, getSizeInUnits, Net } from '../../../utils/utils';
import { Button, Form, Upload, Input, Typography, notification } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';

const { TextArea } = Input;

const BrandEditModal = (props: BrandModalProp) => {
  const { selectedBrand, action } = props;
  const [previewImage, setPreviewImage] = useState('');
  const [adminBrandImage, setBrandImage] = useState(null);
  const [imageFormat, setImageFormat] = useState('');
  const [imageSize, setImageSize] = useState('');
  const [approveModalLoading, setApproveModalLoading] = useState(false);
  const [form] = Form.useForm();

  const submitForm = async (values: {}) => {
    const { id, inner_id, covers, updatedAt } = selectedBrand as Brand;
    const { name, company, introduction, note, legal_states } = values as Brand;

    const formData: BrandEditFormData = {
      id,
      inner_id,
      name,
      company,
      introduction,
      note,
      covers: JSON.stringify(covers),
      legal_states,
      action,
      updatedAt,
    };

    setApproveModalLoading(true);

    try {
      if (adminBrandImage) {
        const adminImageRes = await Net.req(
          '/brand/admin-upload-image',
          {
            name: inner_id,
            adminBrandImage,
          },
          'multipart'
        );
        if (adminImageRes && adminImageRes.link) {
          formData.link = adminImageRes.link;
        }
      }

      const res = await editBrandAPI(formData);
      setApproveModalLoading(false);
      props.hide();
      if (res?.status) {
        if (res.data.status === 'success') {
          props.onSuccess && props.onSuccess();
          notification['success']({
            message: 'Success!',
          });
        } else {
          notification['error']({
            message: (res && res.data.message) || 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: (res && res.data.message) || 'Something went wrong!',
        });
      }
      props.hide();
    } catch (e: any) {
      setApproveModalLoading(false);
      props.hide();
      notification['error']({
        message: (e.response && e.response.data.message) || 'Something went wrong!',
      });
    }
  };

  const uploadFile = (file: RcFile) => {
    getBase64(file).then((res) => {
      setPreviewImage(res as any);

      setImageSize((file.size / 1000).toString());
      setImageFormat(file.type.split('/')[1].toUpperCase());
    });

    setBrandImage(file as any);
    return false;
  };

  const removeFile = () => {
    setPreviewImage('');
    setBrandImage(null);
  };

  const fileDialogProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    fileList: [],
    beforeUpload: uploadFile,
    onRemove: removeFile,
  };

  const { id, inner_id, name, company, note, introduction, legal_states } = selectedBrand;

  const closeEditBrand = () => {
    releaseBrandLockAPI(id);
    props.hide();
  };

  const initialValues = {
    name,
    company,
    note,
    introduction,
    id,
    inner_id,
    legal_states,
  };

  return (
    <Form form={form} onFinish={submitForm} initialValues={initialValues}>
      <div className="brand-edit-parent">
        <Upload {...fileDialogProps} className="product-upload-button">
          <div className="product-image-section">
            <img
              src={previewImage || (props.selectedBrand as Brand).covers[0].link}
              alt="product"
              className="product-image"
            />
            <div className="product-upload-button">
              <img
                src={uploadIcon}
                alt="upload"
                className="upload-button-icon"
                id="add-product-image"
              />
              Upload
            </div>
            {previewImage && (
              <div className="product-edit-image-text">
                {imageFormat} - {getSizeInUnits(imageSize)}
                <br />
                Size: {document.getElementById('add-product-image')?.clientWidth}x
                {document.getElementById('add-product-image')?.clientHeight}
                px
              </div>
            )}
            <div className="product-edit-bottom-image-description">
              Image is best if 200x200px min. Format .jpg, .png
            </div>
          </div>
        </Upload>
        <div className="product-edit-section-2">
          <div className="product-edit-sub-section-2">
            <div className="edit-product-header">
              <div className="edit-product-id">ID #{selectedBrand.inner_id}</div>
            </div>
            <div className="edit-product-option-buttons">
              <Button type="link" onClick={closeEditBrand}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={approveModalLoading}
                disabled={approveModalLoading}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="data-row-2">
            <div>
              <TextInputComponent
                name="name"
                placeholder="Name"
                title="Name"
                size={'366px'}
                required={true}
              />

              <TextInputComponent
                name="company"
                placeholder="Parent Company"
                title="Parent Company"
                size={'366px'}
                required={false}
              />

              <TextInputComponent
                name="note"
                placeholder="Note"
                title="Note"
                size={'366px'}
                required={false}
              />
            </div>
            <div className="textfield-container-1">
              <Typography.Text>Description</Typography.Text>
              <Form.Item name="introduction" rules={[{ required: true }]}>
                <TextArea placeholder="Desciption" className="text-field-sub-component" />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default BrandEditModal;
