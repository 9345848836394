import axios from '../../apiClient/axios-inerceptor';

export const getSearchOrganizationList = async (search_key?: string) => {
  const urlParams = new URLSearchParams();
  if (search_key && search_key.length > 0) urlParams.set('search_key', search_key);
  urlParams.set('limit', '1000');
  return axios
    .get('/org/v1/organization?' + urlParams.toString())
    .then((response) => response)
    .catch((error) => error);
};

export const getRetailOrganization = async (id: string) => {
  const { data } = await axios.get('/org/v1/organization/' + id);
  return data;
};
