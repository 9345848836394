import { EligibleZipCode } from '../../RetailLocationModal';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';

export type EligibleZipCodeInputProps = {
  initialValue?: EligibleZipCode[];
  onChange?: (value: EligibleZipCode[]) => void;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const EligibleZipCodeInput: FC<EligibleZipCodeInputProps> = ({ initialValue = [], onChange }) => {
  const [data, setData] = useState(initialValue);

  useEffect(() => {
    onChange?.(data);
  }, [data, onChange]);

  return (
    <>
      <Form.List
        name="eligibleZipCodes"
        initialValue={data}
        rules={[
          {
            validator: async (_) => {
              const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

              const invalidZipCodes = data.filter((zipCode: EligibleZipCode) =>
                zipCode?.value ? !zipCodeRegex.test(zipCode.value) : false
              );

              const invalidMins = data
                .map((item) => item?.min)
                .filter((min) => min === undefined || min === null || min < 0 || min > 99999);

              if (invalidZipCodes.length > 0) {
                return Promise.reject(
                  new Error(
                    `Invalid zip codes: ${invalidZipCodes
                      .map((item: EligibleZipCode) => item?.value)
                      .join(', ')}. Please enter valid zip codes.`
                  )
                );
              } else if (invalidMins.length > 0) {
                return Promise.reject(
                  new Error(
                    `Invalid mins: ${invalidMins
                      .map((item: number) => item)
                      .join(', ')}. Please enter valid mins.`
                  )
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                data-testid={`eligibleZipCode-${index}`}
                {...formItemLayout}
                required={false}
                key={field.key}
              >
                <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} noStyle>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 40 }}>
                    <Input
                      placeholder="value"
                      style={{ width: '30%' }}
                      onChange={(e) => {
                        const newZipCodes = [...data];
                        newZipCodes[index] = {
                          ...newZipCodes[index],
                          value: e.target.value,
                        };
                        setData(newZipCodes);
                      }}
                      value={data[index]?.value || ''}
                    />
                    <Input
                      placeholder="min"
                      style={{ width: '30%' }}
                      onChange={(e) => {
                        const newZipCodes = [...data];
                        newZipCodes[index] = {
                          ...newZipCodes[index],
                          min: Number(e.target.value),
                        };
                        setData(newZipCodes);
                      }}
                      type="number"
                      value={data[index]?.min || ''}
                    />
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        data-testid={`removeEligibleZipCodeBtn-${index}`}
                        style={{
                          fontSize: 30,
                        }}
                        onClick={() => {
                          remove(field.name);
                          setData((prevState) => prevState.filter((_, i) => i !== index));
                        }}
                      />
                    ) : null}
                  </div>
                </Form.Item>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                data-testid="addEligibleZipCode"
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default EligibleZipCodeInput;
