import axios from '../../apiClient';

export interface AddRetailUser {
  email: string;
  organizationId?: string;
  locationId?: string;
  permission: string;
}

export interface EditRetailUser extends AddRetailUser {
  id: string;
}

export const addRetailUser = async (payload: AddRetailUser) =>
  axios.post('/org/v1/organization/send-invitation', payload);
