import { Modal } from 'antd';
import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import './confirm-modal.less';

export const ConfirmModal = (props: any) => {
  const { show, proceed, dismiss, confirmation } = props;

  return (
    <Modal open={show} title={'Confirmation'} onOk={() => proceed()} onCancel={() => dismiss()}>
      <p>{confirmation}</p>
    </Modal>
  );
};

const confirm = createConfirmation(confirmable(ConfirmModal));

export default function confirmFunction(confirmation: any, options?: any) {
  return confirm({ confirmation, options });
}
