import './main_view.less';
import { HeaderComponent } from '../../components/header/header';
import Sidebar from '../../components/sidebar/sidebar';
import { model_dialog } from '../../models/model_dialog';
import { modleUser, LoginWithTokenAction } from '../../models/model_user';
import { routes } from '../../utils/enum';
import { BrandDetailView } from '../brand/brand_detail';
import { BrandRouting } from '../brand/brand_routing';
import EcommerceClientList from '../ecommerceClient';
import OrdersTable from '../orders/order_table/table-wrapper';
import Organization from '../organization/organization';
import RetailOrganizationDetail from '../organization/organization_detail/RetailOrganizationDetail';
import { ProductRouting } from '../product/product-routing';
import { ProductDetailView } from '../product/product_detail/product_detail';
import RetailLocationDetail from '../retailLocation/retail_location_detail/RetailLocationDetail';
import RetailLocation from '../retailLocation/retailLocation';
import RetailProductRouting from '../retailProducts/retail-product-routing';
import { RetailUserRouting } from '../retailUser/retailUser_routing';
import Settings from '../settings/Main';
import { UserDetialView } from '../user/user_detail';
import { UserRouting } from '../user/user_routing';
import { Layout, Modal } from 'antd';
import { mapProp, doAction, KV } from 'module-reaction';
import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

const { Content } = Layout;

interface MainProps extends RouteComponentProps, KV {
  logined: boolean; // injected by mapProp
  loading: boolean;
}

@mapProp(modleUser)
@mapProp(
  model_dialog,
  'title:dlgTitle',
  'visible:dlgVisible',
  'text:dlgText',
  'onOk:dlgOnOk',
  'onCancel:dlgOnCancel',
  'showLoading:dlgShowLoading'
)
export class MainView extends React.Component<MainProps> {
  componentRouters: RouterCfg[] = [
    {
      path: routes['userList'],
      component: UserRouting,
    },
    {
      path: routes['userDetail'],
      component: UserDetialView,
      exact: true,
    },
    {
      path: routes['brandList'],
      component: BrandRouting,
    },
    {
      path: routes['brandDetail'],
      component: BrandDetailView,
      exact: true,
    },
    {
      path: routes['retailLocationList'],
      component: RetailLocation,
    },
    {
      path: routes['retailLocationDetail'],
      component: RetailLocationDetail,
    },
    {
      path: routes['ecommerceClientList'],
      component: EcommerceClientList,
    },
    {
      path: routes['organizationList'],
      component: Organization,
    },
    {
      path: routes['organizationDetail'],
      component: RetailOrganizationDetail,
    },
    {
      path: routes['retailUserList'],
      component: RetailUserRouting,
    },
    {
      path: routes['productList'],
      component: ProductRouting,
    },
    {
      path: routes['retailProductList'],
      component: RetailProductRouting,
    },
    {
      path: routes['productDetail'],
      component: ProductDetailView,
      exact: true,
    },
    {
      path: routes['orderList'],
      component: OrdersTable,
      exact: true,
    },
    {
      path: routes['settings'],
      component: Settings,
      exact: true,
    },
  ];

  UNSAFE_componentWillMount() {
    doAction(LoginWithTokenAction, this.props.history);
  }

  render() {
    if (!this.props.logined && !this.props.loading) {
      return <Redirect to="/sign-in" />;
    }
    const { dlgVisible, dlgTitle, dlgText, dlgOnOk, dlgOnCancel, dlgShowLoading } = this.props;
    return (
      <Layout className="main-view">
        <HeaderComponent />
        <Layout>
          <Sidebar />
          <Content>
            <Switch>
              {this.componentRouters.map((_: RouterCfg, idx: number) => (
                <Route key={`${_.path}-${idx}`} {..._} />
              ))}
            </Switch>
          </Content>
        </Layout>
        <Modal
          visible={dlgVisible}
          title={dlgTitle}
          onOk={dlgOnOk}
          onCancel={dlgOnCancel}
          closable={false}
          maskClosable={false}
          confirmLoading={dlgShowLoading}
        >
          {dlgText}
        </Modal>
      </Layout>
    );
  }
}
