/* eslint-disable no-async-promise-executor */
import axios from '../../apiClient';
import moment from 'moment';

export const verifyProductLockAPI = (productId: string, updatedAt: Date): Promise<any> =>
  new Promise((resolve, reject) => {
    void (async () => {
      try {
        const { id: userId } = JSON.parse(localStorage.getItem('userDetails') as string);
        const response = await axios.get(
          `product/detail/${productId}?require_similar_products=true`
        );
        if (response.data.lockTime === null || response.data.lastEditorId === null) {
          await axios.put(`/product/lock-edit-product`, {
            product_id: productId,
            updatedAt,
          });
          resolve(response);
        } else if (
          moment(response.data.lockTime).add(10, 'minutes').isAfter(moment()) &&
          response.data.lastEditorId !== userId
        ) {
          reject(new Error('FAILED'));
        } else {
          await axios.put(`/product/lock-edit-product`, {
            product_id: productId,
            updatedAt,
          });
          resolve(response);
        }
      } catch (error) {
        reject(error);
      }
    })();
  });
