import { Input, Select } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import './brand_filter.less';

export interface BrandFilterProp {
  uploadCSV: Function;
  onSearch: Function;
  lastUploadCSV: any;
  activateBulkActions: Function;
  children: React.ReactNode;
}

const { Option } = Select;

export class BrandFilter extends React.Component<BrandFilterProp, {}> {
  emitChangeDebounced = debounce(this.props.onSearch as any, 500);

  componentWillUnmount() {
    this.emitChangeDebounced.cancel();
  }

  handleSearch = (e: string) => {
    this.emitChangeDebounced(e);
  };

  render() {
    const { activateBulkActions } = this.props;

    return (
      <div className="filter-root">
        <div className="product-filter">
          <div className="filter-item">
            <Input.Search
              placeholder="Search"
              onChange={(e) => this.handleSearch(e.target.value)}
              allowClear
            />
          </div>

          <Select
            onSelect={activateBulkActions as never}
            className="filter-search-bulk-actions"
            placeholder="Bulk Actions"
            value=""
            options={[
              { value: '', label: 'Bulk Actions', disabled: true },
              { value: 'edit', label: 'Edit', disabled: false },
              { value: 'deactivate', label: 'Discontinue', disabled: false },
            ]}
          />
          {this.props.children}
        </div>
      </div>
    );
  }
}
